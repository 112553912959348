import logo from './logo.svg';
import './App.css';
import Login from './Components/Login';
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Switch
} from "react-router-dom";
import Navbar from './Components/Navbar';
import TopNavbar from './Components/TopNavbar';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustDataAction } from './actions/action'
import MarkUpScreen from './Screens/MarkUpScreen';
import CompanyMarkupScreen from './Screens/CompanyMarkupScreen';
import ServiceScreen from './Screens/ServiceScreen';
import CompanyService from './Screens/CompanyService';
import DirectPay from './Components/DirectPay';
import EventEnquiry from './Screens/EventEnquiry'
import GroupBookingScreen from './Screens/GroupBookingScreen'
import BookingEnquiryDetails from './Screens/BookingEnquiryDetails'
import GroupBookingDetails from './Screens/GroupBookingDetails'
import ContentManagement from './Screens/ContentManagement'
import PackageScreen from './Screens/PackageScreen'
import SupplierMasterScreen from './Screens/SupplierMasterScreen'
import HotelTypeMasterScreen from './Screens/HotelTypeMasterScreen'
import ItineraryImageMasterScreen from './Screens/ItineraryImageMasterScreen'
import DigitalVaultScreen from './Screens/DigitalVaultScreen'
import WalletMemberScreen from './Screens/WalletMemberScreen'
import PackageGroupBooking from './Screens/PackageGroupBooking'
import PackageGroupDetails from './Screens/PackageGroupDetails'
import PackageOfflineBooking from './Screens/PackageOfflineBooking'
import PackageBookingDetails from './Screens/PackageBookingDetails'
import NewWalletAccount from './Screens/NewWalletAccount'
import WalletHistory from './Screens/WalletHistory';
import RewardScreen from './Screens/RewardScreen';
import TransactionHistory from './Screens/transactionHistory';
import UpdateAccountStatus from './Screens/UpdateAccountStatus';
import PetBooking from './Screens/PetBooking';
import DirectPayScreen from './Screens/DirectPayScreen';
import PackageTravellerDetailsScreen from './Screens/PackageTravellerDetailsScreen';
import DisplayBookingdetails from './Screens/DisplayBookingdetails';
import CorporateRegistrationEntry from './Screens/CorporateRegistrationEntry';
import CorporateRegistrationDetails from './Screens/CorporateRegistrationDetails';
import TravelAgentMis from './Screens/TravelAgentMis';
import HotelTravelAgentMis from './Screens/HotelTravelAgentMis';
// import merchantWallet from './Screens/merchantWallet';

function App() {

  const dispatch = useDispatch();

  const custListData = useSelector((state) => state.custListStore);
  const { error, loading, MEMBER_LIST_DATA } = custListData;

  if(loading == false){
  }

  useEffect(() => {
    const urlData = "/cust-data/";
    dispatch(getCustDataAction(urlData));

  }, [dispatch]);

  if (loading == true) {

  }
  else if(loading == false){
    return (
      <div>
        <Router>     
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<MarkUpScreen />} />
              <Route path="/create-service" element={<ServiceScreen />} />
              <Route path="/companies-markup" element={<CompanyMarkupScreen />} />
              <Route path="/companies-service" element={<CompanyService />} />
              <Route path="/pay" element={<DirectPay />} />
              <Route path="/eventEnquiry" element ={<EventEnquiry/>} />
              <Route path="/eventBooking" element ={<BookingEnquiryDetails/>} />
              <Route path="/groupBooking" element ={<GroupBookingScreen/>} />
              <Route path="/groupBookingDetails" element ={<GroupBookingDetails/>} />
              <Route path="/contentManagement" element ={<ContentManagement/>} />
              <Route path="/supplier_master" element ={<SupplierMasterScreen/>} />
              <Route path="/hoteltype_master" element ={<HotelTypeMasterScreen/>} />
              <Route path="/itinerary_images" element ={<ItineraryImageMasterScreen/>} />
              <Route path="/packageManagement" element ={<PackageScreen/>} />
              <Route path="/digitalVault" element ={<DigitalVaultScreen/>} />
              <Route path="/walletMembers" element ={<WalletMemberScreen/>} />
              <Route path="/packageGroupBooking" element ={<PackageGroupBooking/>} />
              <Route path="/packageGroupDetails" element ={<PackageGroupDetails/>} />
              <Route path="/package_bookings" element ={<PackageOfflineBooking/>} />
              <Route path="/package_booking_details" element ={<PackageBookingDetails/>}/>
              <Route path="/NewWalletAccount" element ={<NewWalletAccount/>} />
              <Route path="/WalletHistory" element ={<WalletHistory/>} />
              <Route path="/Rewards" element ={<RewardScreen/>} />
              {/* <Route path="/merchantWallet" element ={<merchantWallet/>} /> */}
              <Route path="/TransactionHistory" element ={<TransactionHistory/>} />
              <Route path="/UpdateAccountStatus" element ={<UpdateAccountStatus/>}/>
              <Route path="/PetBooking" element ={<PetBooking/>}/>
              <Route path="/DirectPayScreen" element ={<DirectPayScreen/>}/>
              <Route path="/PackageTravellerDetailsScreen" element ={<PackageTravellerDetailsScreen/>}/>
              <Route path="/DisplayBookingdetails" element={<DisplayBookingdetails />} />
              <Route path="/b2b_entries" element={<CorporateRegistrationEntry />} />
              <Route path="/b2b_entry_details" element={<CorporateRegistrationDetails />} />
              <Route path="/travel-agent-mis-flights" element={<TravelAgentMis />} />
              <Route path="/travel-agent-mis-hotels" element={<HotelTravelAgentMis />} />
            </Routes>
        </Router>
      </div>


    );
  }
  else{
    return(
      <div></div>
    )
  }

}

export default App;
