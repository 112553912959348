import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux'
import {getCustDataReducer, getTrainerListReducer} from '../src/reducers/reducer'


const reducer = combineReducers({
    custListStore : getCustDataReducer,
    trainerListStore : getTrainerListReducer,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))


export default store 