import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import { HotelTypeMaster } from "../dummyDb";
import $ from "jquery"

var id="";

function HotelTypeMasterScreen(){
    var data = [];
    const [hoteltype_list, set_hoteltype_list] = useState([])
    const [edit_flag, set_edit_flag] = useState(false)
    const [clear_flag, set_clear_flag] = useState(false)
    const [id, setid] = useState("")

    useEffect(() => {
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
      const fetchData = async () => {
        const hoteltype_details = await axios.get( API_URL + '/packages/DisplayAllHotelTypeDetails/')
        set_hoteltype_list(hoteltype_details.data)
      };
      fetchData();
    }, [])

    useEffect(() => {
      if(edit_flag == true){
        getData(id)
      }
      if (clear_flag == true){
        document.getElementById("hotel_type").value = "";
        document.getElementById("create").classList.remove("hide");
        document.getElementById("update").classList.add("hide");
      }
    }, [edit_flag, clear_flag])

  var columns = [
      {
        name: 'Hotel Type',
        selector: 'hotel_type',
      },
      {
        name: '',
        selector: 'edit',
      },
  ];

  const createAPI = async (req) => {
    const hoteltype_details = await axios.post( API_URL + '/packages/Create_and_Update_HotelType/', req)
    if(hoteltype_details.data == "Created Successfully" || hoteltype_details.data == "Updated Successfully"){
      swal(hoteltype_details.data)
      setTimeout(() => {window.location.reload()}, 1000)
    }
    else{
      swal("Something went wrong, Please try again later!")
    }
  }

  const setData = (id, type) => {
    
    if($("#hotel_type").val() == ""){
      swal("Please Enter Hotel Type")
    }
    else{
      
      if (type == "create"){
        
        var obj = {
          hotel_type: document.getElementById("hotel_type").value,
        }
      }
      if (type == "update"){
        
        var obj = {
          id: id,
          hotel_type: document.getElementById("hotel_type").value,
        }
      }
      createAPI(obj)
      console.log("obj")
      console.log(obj)
    }
  }

  const getData = async (id) => {
    
    data = await axios.post( API_URL + '/packages/Display_HotelType/', {'id':id})
    data = data.data
    document.getElementById("hotel_type").value = data.hotel_type
    document.getElementById("create").classList.add("hide");
    document.getElementById("update").classList.remove("hide");
    set_edit_flag(false)
    set_clear_flag(false)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return(
      <>
      <nav
        class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
      >
        <Navbar />
      </nav>
      <div class="main-content" id="panel">
        <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
          <TopNavbar />
        </nav>
        <div className="ml-5 mt-5 mr-5">
        <span>{console.log('hoteltype_list')}</span>
          <span>{console.log(hoteltype_list)}</span>
          <div className="container">
          <Button className="btn btn-sm" id="" onClick={e => set_clear_flag(true)}>+Add New</Button>
            <Form className="packageModal">
            <div className="row">
                <div className="col">
                    <Form.Group>
                      <Form.Label>Hotel Type</Form.Label>
                      <Form.Control
                        className=""
                        type="text"
                        placeholder="Hotel Type"
                        name="hotel_type"
                        id="hotel_type"
                      />
                    </Form.Group>
                </div>
                <div className="col">
                    <br />
                    <Button id="create" onClick={(e) => setData("", "create")}>Create</Button>
                    <Button className="hide" onClick={(e) => setData(id, "update")} id="update">Update</Button>
                </div>
            </div>
            </Form>
          </div>
          <div className="card">
          {hoteltype_list.map((obj, index) => {
              var datalist = {
              "hotel_type":obj.hotel_type,
              "edit":
              <span id={obj.id}
              style={{"cursor":"pointer"}}
              onClick={async () => {
                
                getData(obj.id)
                await setid(obj.id)
                console.log("obj.id")
                console.log(obj)
                set_edit_flag(true)
                set_clear_flag(false)
              }}
              >
                Edit
              </span>,
            }
            data.push(datalist)
          })}
            <DataTableExtensions
                      title="HotelTypeMaster"
                      columns={columns}
                      data={data}
                      print={false}
                      export={false}
                      filter={false}
                      filterPlaceholder="search"
                      filterHidden={true}
                      filterDigit={1}
                    >
              <DataTable
                nHeader
                highlightOnHover
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
     
      </>
    )
}


export default HotelTypeMasterScreen