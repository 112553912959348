import React, { useState, useEffect } from "react";
import { Container, Row, ToggleButton, Badge} from "react-bootstrap";
import { GROUPBOOKING_LIST } from "../dummyDb";
import axios from "axios";
import { MDBDataTable, MDBInput } from 'mdbreact';
import { objectOf } from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import Swal from 'sweetalert2'
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";
import $ from "jquery"
import swal from "sweetalert";


var memberOrNot = []
function DisplayBookingdetails() {
  const [digitalWallet, setdigitalWallet] = useState(null);
  const [HotelandPaymentData, setHotelandPaymentData] = useState();
  const [loading, setLoading] = useState(true);
  const [Handle, setHandle] = useState(false)
  const [updateResponse, setUpdateResponse] = useState(false);
  // const [dataoftable, setdataoftable] = useState(null);
  const [HotelPayDetails, setHotelPayDetails] = useState();
  const [Payment, setPayment] = useState();
  const [CustBookingDetails, setCustBookingDetails] = useState();
  const [CustTripDetails, setCustTripDetails] = useState();
  const [Invoice, setInvoice] = useState();



  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
    const fetchData = async () => {
      setLoading(true)
      const DisplayBookingDetails = await axios.get( API_URL + '/Aggrepayment/DisplayBookingDetails/')
      setdigitalWallet(DisplayBookingDetails.data)
      const rowData = DisplayBookingDetails.data      //old code
      const HotelPayDetails = rowData.HotelPaymentLogTable
      setHotelPayDetails(HotelPayDetails);
      const Payment = rowData.PaymentDetail
      setPayment(Payment);
      const CustBookingDetails = rowData.CustomerBookingTable
      setCustBookingDetails(CustBookingDetails);
      const CustTripDetails = rowData.CustomerTripTable
      setCustTripDetails(CustTripDetails);
   
      const Invoice = rowData.InvoiceDetails
      setInvoice(Invoice);
   
      setLoading(false)
    };
    setTimeout(() => {
        fetchData();
    }, 10)
  }, [updateResponse]);


  const handleupdate =  (data)=>{
    setHandle(true)
    if (Handle == false ){
      setHandle(true)
      }else{
      setHandle(false) 
      }
    console.log(data.target.innerHTML)   
   }
  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {     
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
          <h2 className="mt-3 my-3">
            Display Booking Details
          </h2>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive vault_table">
                  <table class="table align-items-center table-flush flex-wrap" id="tableaccordion">
                      <thead class="thead-light">
                        <tr>
                          {/*<th scope="col">
                          <input
                          type="checkbox"

                          /></th>*/}
                          <th scope="col" >Order ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Booking Status</th>
                          <th scope="col">Invoice Number</th>
                          <th scope="col">Amount Paid</th>
                          <th scope="col"></th> 
                        </tr>
                      </thead>
                      <tbody>
                    {HotelPayDetails.map((obj,i) => {
                      var filtered = Payment.find(x => x.order_id == obj.order_id);
                      var filtered1 = CustBookingDetails.find(x => x.Flight_booking_id == obj.order_id);
                      var filtered2 = CustTripDetails.find(x => x.Flight_booking_id == obj.order_id);
                      var filtered3 = Invoice.find(x => x.booking_id == obj.order_id);
                        const orderid = obj.order_id
                        const name = obj.name
                        const email = obj.email
                        const phone = obj.phone
                        const transaction_id = obj.transaction_id
                        const bookingstatus = filtered1.booking_status
                        const invoicenumber = filtered3.invoice_number
                        const amountpaid = filtered3.amount_paid
                        const City = obj.city
                        const Country = obj.country
                      return(
                         <>     
                        <tr>
                          <td scope="col" style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false" id="tableaccordion2"   aria-controls={"collapseExample"+obj.id}>{orderid}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{name}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{email}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{phone}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{transaction_id}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{bookingstatus}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{invoicenumber}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}>{amountpaid}</td>
                          <td scope="col "style={{ "cursor": "pointer" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample"+obj.id}aria-expanded="false"   aria-controls={"collapseExample"+obj.id}><button type="button" style={{ cursor: "pointer" , background:"transparent","box-shadow": "none",color:"black",height:"0.1rem", width:"1rem",padding: "0px"}} data-toggle="collapse" data-target={"#collapseExample"+obj.id}       aria-expanded="false"   aria-controls={"collapseExample"+obj.id}   class="accordion-button collapsed"></button></td>
                        </tr>
                        <td colspan="12" style={{"padding": "0px"}}>
                            <div className="collapse" style={{"padding": "0px"}} id={"collapseExample"+obj.id} aria-labelledby={"collapseExample"+obj.id} data-parent="#tableaccordion2">
                                {/* <div className="card card-body"> */}
                                
                              <thead className="" style={{ backgroundColor: "#ededed", width:"" }}>
                                <tr>

                                <th scope="col" id ={"collapseExample"+obj.id}  style={{ cursor: "pointer",backgroundColor:"",width:"15000px", paddingLeft:"520px"  }} data-toggle="collapse" className="" data-target={"#collapseExample_UserInfo" + filtered.id }aria-expanded="true"   aria-controls={"collapseExample_UserInfo"+ filtered.id}><h6>User Details</h6></th>
                               
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                              
                                <td colspan="12" style={{"padding": "0px"}}>
                                <div className="collapse show" style={{"padding": "0px"}} id={"collapseExample_UserInfo"+ filtered.id} aria-labelledby={"collapseExample_UserInfo"+ filtered.id} data-parent="#tableaccordion">
                                {/* <div className="card card-body"> */}
                                
                              <thead className="" style={{ "background-color": "#ededed" }}>
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">City</th>
                                <th scope="col">Country</th>
                                <th scope="col">Zip Code</th>
                                <th scope="col">No. of Travellers</th>
                               
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                                <tr>
                                  <td scope="col">{obj.name}</td>
                                  <td scope="col">{obj.email}</td>
                                  <td scope="col">{obj.phone}</td>
                                  <td scope="col">{obj.city}</td>
                                  <td scope="col">{obj.country}</td>    
                                  <td scope="col">{obj.zip_code}</td>    
                                  <td scope="col">{filtered2.noOfTraveller}</td>    
                                   
                                </tr>
                              </tbody>
                              
                                {/* </div> */}
                              </div>  
                            </td>
                            
                              </tbody>
                              <td colspan="12" style={{"padding": "0px"}}>
                                <div className="collapse" style={{"padding": "0px"}} id={"collapseExample"+obj.id} aria-labelledby={"collapseExample"+obj.id} data-parent="#tableaccordion2">
                                {/* <div className="card card-body"> */}
                                
                                <thead className="" style={{ "background-color": "#ededed" }}>
                                <tr>

                                <th scope="col" style={{ cursor: "pointer",width:"15000px", paddingLeft:"510px" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample_PaymentInfo" + filtered.id }aria-expanded="false" aria-controls={"collapseExample_PaymentInfo"+ filtered.id}><h6>Payment Details</h6></th>
                              
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                                <tr>
                                
                                </tr>
                                <td colspan="12" style={{"padding": "0px"}}>
                                <div className="collapse" style={{"padding": "0px"}} id={"collapseExample_PaymentInfo"+ filtered.id} aria-labelledby={"collapseExample_PaymentInfo"+ filtered.id} data-parent="#tableaccordion">
                                {/* <div className="card card-body"> */}
                                
                              <thead className="" style={{ "background-color": "#ededed" }}>
                                <tr>
                                <th scope="col">Payment APi</th>
                                <th scope="col">Markup Amount</th>
                                <th scope="col">Service Charge</th>
                                <th scope="col">Gst Charge</th>
                                <th scope="col">Invoice Number</th>
                                <th scope="col">Amount Paid</th>
                                <th scope="col">Points</th>
                                <th scope="col">Discount Amount</th>
                                <th scope="col">Total Discount Amount</th>
                                <th scope="col">Discount Type</th>
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                                <tr>
                                  <td scope="col">{filtered.payment_api}</td>
                                  <td scope="col">{filtered1.markup_amount}</td>
                                  <td scope="col">{filtered1.service_charge}</td>
                                  <td scope="col">{filtered1.gst_charge}</td>    
                                  <td scope="col">{filtered3.invoice_number}</td>    
                                  <td scope="col">{filtered3.amount_paid}</td>    
                                  <td scope="col">{filtered3.points}</td>    
                                  <td scope="col">{filtered3.discount_amt}</td>
                                  <td scope="col">{filtered3.total_disc_amt}</td>
                                  <td scope="col">{filtered3.discount_type}</td>    
                                </tr>
                                
                              </tbody>
                              
                                {/* </div> */}
                                
                              </div>  
                            </td>
                            
                              </tbody>
                                
                              </div> 
                              <td colspan="12" style={{"padding": "0px"}}>
                                <div className="collapse" style={{"padding": "0px"}} id={"collapseExample"+obj.id} aria-labelledby={"collapseExample"+obj.id} data-parent="#tableaccordion2">
                                {/* <div className="card card-body"> */}
                                
                                <thead className="" style={{ "background-color": "#ededed" }}>
                                <tr>

                                <th scope="col" style={{ cursor: "pointer",width:"15000px", paddingLeft:"510px" }} data-toggle="collapse" className="collapsed" data-target={"#collapseExample_BookingInfo" + filtered.id }aria-expanded="false" aria-controls={"collapseExample_BookingInfo"+ filtered.id}><h6>Booking Details</h6></th>
                                
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                                <tr>
                               
                                </tr>
                                <td colspan="12" style={{"padding": "0px"}}>
                                <div className="collapse" style={{"padding": "0px"}} id={"collapseExample_BookingInfo"+ filtered.id} aria-labelledby={"collapseExample_BookingInfo"+ filtered.id} data-parent="#tableaccordion">
                                {/* <div className="card card-body"> */}
                                
                              <thead className="" style={{ "background-color": "#ededed" }}>
                                <tr>
                                <th scope="col">Trip Type</th>
                                <th scope="col">Api type</th>
                                <th scope="col">Cabin Class</th>
                                <th scope="col">From City</th>
                                <th scope="col">To City</th>
                                <th scope="col">From Airport</th>
                                <th scope="col">To Airport</th>
                                <th scope="col">Airline Data</th>
                                <th scope="col">Arrival Time</th>
                                <th scope="col">Departure time</th>
                                <th scope="col">Trip Status</th>
                                <th scope="col">Flight Number</th>
                              </tr>
                              </thead>
                              <tbody style={{ "background-color": "rgb(233 228 228)" }}>
                                <tr>
                                  <td scope="col">{filtered1.trip_type}</td>
                                  <td scope="col">{filtered1.apiType}</td>
                                  <td scope="col">{filtered1.cabin_class}</td>
                                  <td scope="col">{filtered2.from_city}</td>    
                                  <td scope="col">{filtered2.to_city}</td>    
                                  <td scope="col">{filtered2.from_airport}</td>    
                                  <td scope="col">{filtered2.to_airport}</td>    
                                  <td scope="col">{filtered2.airline_data}</td>
                                  <td scope="col">{filtered2.arrival_time}</td>
                                  <td scope="col">{filtered2.departure_time}</td> 
                                  <td scope="col">{filtered2.trip_status}</td>
                                  <td scope="col">{filtered2.flight_number}</td>
                                </tr>
                                
                              </tbody>
                              
                                {/* </div> */}
                                
                              </div>  
                            </td>
                            
                              </tbody>
                                
                              </div> 
                                
                            </td >  
                            </td >
                            
                            
                              </div> 
                                
                            </td >
                            
                        </>  );
                    })}
                    </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default DisplayBookingdetails;
