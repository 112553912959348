import React, { useState, useEffect } from "react";
import { Container, ToggleButton, Button } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { GROUPBOOKING_LIST } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import Form from 'react-bootstrap/Form';
import $ from 'jquery'


function CorporateRegistrationEntry({ match }) {
  const [registration_details, set_registration_details] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [booking_type, setBookingType] = useState(false);
  const [display, setdisplay] = useState("hide")
  const [company, setcompany] = useState([
    {"Company_name":"",
      "contact_no":"",
      "contact_person_name":"",
      "email_id":"",
      "gst_no":"",
      "address":"",
      "isactive":true,
      "corporate_id":"",
      "logo":"",
      "pan_no":"",
      "gst_certificates":"",
      "pan_card_docs":""
    }
  ])
  
  let url_data = window.location.href;
  url_data = url_data.split("?")[1];

  const handleAddClick = async (type,id) => {
    if (type == "update"){
      company[0].id = id
    }
    const update_booking_id = await axios.post( API_URL + '/accounts/Company_details/', company[0]);
    if (update_booking_id.data == "Updated Successfully"){
      delete company[0].id
      swal("Updated Successfully")
    }
    else if(update_booking_id.data == "Created Successfully"){
      swal("Created Successfully")
    }
    
  }

  const Activate = async (req) => {
    console.log("req")
    console.log(req)
    const activate = await axios.post( API_URL + '/accounts/RegistrationActivateEntry/',req)
    if(activate.data == "Activated Successfully"){
      swal(activate.data)
    }
    else{
      swal("Something went wrong, Please try again later!")
    }
  }

  const ActivationClick = async (id,cre_contact_no) => {

    //check user exist in user table
    const user = await axios.post( API_URL + '/accounts/UserExistOrNot/',{"cre_contact_no":cre_contact_no})
    console.log("user.data")
    console.log(user.data)
    if(typeof(user.data) == 'object'){
      if(user.data.Message == 'User exist'){
        debugger
        swal({
            text: `User exist, Do you want to continue with same user?`,
            buttons: [
                    'No',
                    'Yes'
                  ],
        }).then(async (isConfirm) => {
          if(isConfirm){
            debugger
            var request_format = {
              "id":id,
              "user_id":user.data.user_id,
              "cre_contact_no":cre_contact_no,
              "isactive":document.getElementById("checkbox"+id).checked
            }
            Activate(request_format)
          }
          else{
            document.getElementById("checkbox"+id).checked = false
          }
        })
      }
      else if(user.data.Message == 'User does not exist'){
        debugger
        swal({
            text: `Do you want to activate user?`,
            buttons: [
                    'No',
                    'Yes'
                  ],
        }).then(async (isConfirm) => {
          if(isConfirm){
            var request_format = {
              "id":id,
              "cre_contact_no":cre_contact_no,
              "isactive":document.getElementById("checkbox"+id).checked
            }
            Activate(request_format)
          }
          else{
            document.getElementById("checkbox"+id).checked = false
          }
        })
      }
      
    }
    else{
      swal("Something went wrong, Please try again later!")
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...company];
    list[index][name] = value;
    setcompany(list);
  };


  const redirectFun =(id)=>{
    window.location.href = DASHBOARD_URL + "/b2b_entry_details?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    // if (display == true)
    // {
    //   debugger;
    //   $(".corporate_company_form").removeClass("hide")
    // }

    const fetchData = async () => {
      setLoading(true)
      const registration_details = await axios.get( API_URL + '/accounts/DisplayRegistrationDetails/', {'id':url_data});
      registration_details.data.sort(function(a, b) { 
        return b.id - a.id;
      });
      // const booking_details = GROUPBOOKING_LIST;
      set_registration_details(registration_details.data);
      console.log("registration_details.data")
      console.log(registration_details.data)
      var booking_type = registration_details.data.trip_type;
      setBookingType(booking_type)
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  const handleDisplay = async (type,id) => {
    debugger
    setdisplay("")
    if(type == "create"){
      $('.update').addClass("hide")
      $('.create').removeClass("hide")
    }
    if(type == "update"){
      $('.update').removeClass("hide")
      $('.create').addClass("hide")
      const getData = await axios.post( API_URL + '/accounts/DisplayCorporateCompanyDetailsById/', {"id": id});
      console.log("getdata")
      console.log(getData)
      setcompany([getData.data])

    }
    
  }

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } 
  else {
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>

            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">  
                  <div class="card">
                    <div class="container">
                    <h2 style={{"margin":"15px 0px"}}>
                    B2B Registration Enquiries
                    </h2>
                      <div>
                      <div class="table-responsive" style={{"max-height":"490px"}}>
                    <table class="table align-items-center table-flush groupbooking_table b2b_entry_table" style={{"text-align": "center"}}>
                      <thead class="thead-light">
                        <tr>
                          {/*<th scope="col">
                          <input
                          type="checkbox"

                          /></th>*/}
                          <th scope="col">Id</th>
                          <th scope="col">Agency Name</th>
                          <th scope="col">Company Contact No</th>
                          <th scope="col">contact person name</th>
                          <th scope="col">business type</th>
                          <th scope="col">Activation</th>
                          {/* <th scope="col">User First Name</th>
                           <th scope="col">User email_id</th> */}
                          {/*<th scope="col">gst_no</th>
                          <th scope="col">address</th> */}
                          {/* <th scope="col">Trip Type</th>
                          <th scope="col">Status</th> */}
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          registration_details?.map((obj, indx)=>{
                            return(
                          <tr>
                            <td scope="col">{obj.id}</td>
                            <td scope="col">{obj.cre_agency_name}</td>
                            <td scope="col">{obj.cre_contact_no}</td>
                            <td scope="col">{obj.cre_fname}</td>
                            <td scope="col">{obj.cre_business_type}</td>
                            <td>
                              <input 
                                type="checkbox" 
                                style={{'cursor':'pointer'}} 
                                id={"checkbox"+obj.id} 
                                defaultChecked={obj.isactive} 
                                className={obj.isactive == true ? 'disabled'  : ''} 
                                onClick={() => {ActivationClick(obj.id, obj.cre_contact_no)}}
                              />
                            </td>
                            <td scope="col"> 
                              <button class="btn btn-primary" style={{cursor:"pointer"}} onClick = {
                                    (e)=>{
                                  e.preventDefault()
                                    redirectFun(obj.id)
                                  }

                                  } variant="primary"> View Details 
                              </button> 
                            </td>
                            {/* <td scope="col"> 
                              <button class="btn btn-primary" style={{cursor:"pointer"}} onClick = {() => handleDisplay("update", obj.id)}
                                     variant="primary"> Edit 
                              </button> 
                            </td> */}
                          </tr>
                            )
                          }
                          )
                        }
                      </tbody>
                    </table>
                      
                  </div>
                          
                        {/* <p><b>{company_details.contact_no ? "Contact no: " : ""}</b>{company_details.contact_no ? company_details.contact_no:""}</p>
                        <p><b>{company_details.contact_person_name ? "contact person name: " : ""}</b>{company_details.contact_person_name ? company_details.contact_person_name:""}</p>
                        <p><b>{company_details.email_id ? "Email Id: " : ""}</b>{company_details.email_id ? company_details.email_id:""}</p>
                        <p><b>{company_details.gst_no ? "gst no: " : ""}</b>{company_details.gst_no ? company_details.gst_no:""}</p>
                        <p><b>{company_details.address ? "address: " : ""}</b>{company_details.address ? company_details.address:""}</p>
                        <p><b>{company_details.corporate_id ? "Corporate ID: " : ""}</b>{company_details.corporate_id ? company_details.corporate_id:""}</p>
                        <p><b>{company_details.pan_no ? "Pan No: " : ""}</b>{company_details.pan_no ? company_details.pan_no:""}</p> */}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default CorporateRegistrationEntry;