import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
} from "../const";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import Moment from "react-moment";
import moment from "moment";
var list = [];
function PetBooking()
{
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [statusValue, setStatusValue] = useState(null); 

    // handle input change
  const handleInputChange = (e, index) => {
    // debugger;
    list.push({ "id": index, "booking_status": e.target.value });
    setInputList(list);
  };
  
  // const handleAddClick = () => {
  //   const update_booking_id = axios.post( API_URL + '/group_bookings/update_status_group_booking/', list)
  //   window.location.reload();
  // };

  const handleAddClick = () => {
    const update_booking_id = axios.post( API_URL + '/group_bookings/update_Pet_Status/', list)
    window.location.reload();
  };

  const redirectFun =(id)=>{
    
    window.location.href = DASHBOARD_URL + "/groupBookingDetails?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      debugger;
      setLoading(true)
      const booking_details = await axios.get( API_URL + '/group_bookings/getPetBookings/')
      set_booking_details_list(booking_details.data)
      setLoading(false)
    };

    fetchData();
  }, [updateResponse]);

  // add <td> by dipanshu
  // <td scope="col">
  //   <select className="btn btn-sm Pet_Status" id="Pet_Status" onChange={e => handleInputChange(e, obj.id)}>
  //       <option value="COMPLETE" selected={obj.Pet_Status == "COMPLETE" ? 'selected':''}>COMPLETE</option>
  //       <option value="PENDING" selected={obj.Pet_Status == "PENDING" ? 'selected':''}>PENDING</option>
  //       <option value="CANCELLED" selected={obj.Pet_Status == "CANCELLED" ? 'selected':''}>CANCELLED</option>
  //       <option value="INPROGRESS" selected={obj.Pet_Status == "INPROGRESS" ? 'selected':''}>INPROGRESS</option>
  //   </select>
  // </td>


  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <h2>Pets Booking</h2>
            <br/>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive" style={{"max-height":"490px"}}>
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          {/*<th scope="col">
                          <input
                          type="checkbox"

                          /></th>*/}
                          <th scope="col">From City</th>
                          <th scope="col">To City</th>
                          <th scope="col">Trip Type</th>
                          <th scope="col">Departure Date</th>
                          <th scope="col">Return Date</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Contact Number</th>
                          <th scope="col">Email Id</th>    
                          <th scope="col">Pet Type</th>                      
                          <th scope="col">Breed</th>
                          <th scope="col">Pet's Date of Birth</th>
                          <th scope="col">Pet's Length</th>
                          <th scope="col">Pet's Height</th>
                          <th scope="col">Pet's Width</th>
                          <th scope="col">Is Cage Available</th>
                          <th scope="col">Cage Length</th>
                          <th scope="col">Cage Width</th>
                          <th scope="col">Cage Height</th>
                          {/*<th scope="col">Pet Status</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          booking_details_list?.map((obj, indx)=>{
                            debugger
                            console.log(obj.isCageAvailable)
                            if(obj.isCageAvailable == true){
                              var cage_avail = "Yes"
                            }
                            else{
                              var cage_avail = "No"
                            }
                            if(obj.arrival){
                              var arrival = moment(obj.arrival).format("MMM Do YY");
                            }
                            else{
                              var arrival = "-";
                            }
                            return(
                          <tr>
                            <td scope="col">{obj.from_city}</td>
                            <td scope="col">{obj.to_city}</td>
                            <td scope="col">{obj.trip_type}</td>
                            <td scope="col">{moment(obj.departure).format("MMM Do YY")}</td>
                            <td scope="col">{arrival}</td>

                            <td scope="col">{obj.first_Name}</td>
                            <td scope="col">{obj.last_Name}</td>
                            <td scope="col">{obj.mobile_no}</td>
                            <td scope="col">{obj.emailId}</td>
                            <td scope="col">{obj.pet_type}</td>
                            <td scope="col">{obj.pet_breed}</td>
                            <td scope="col">{moment(obj.pet_dob).format("MMM Do YYYY")}</td>
                            <td scope="col">{obj.pet_length}</td>
                            <td scope="col">{obj.pet_height}</td>
                            <td scope="col">{obj.pet_width}</td>
                            <td scope="col">{cage_avail}</td>
                            <td scope="col">{obj.cage_length}</td>
                            <td scope="col">{obj.cage_width}</td>
                            <td scope="col">{obj.cage_height}</td>
                          </tr>
                            )
                          }
                          )
                        }
                      </tbody>
                    </table>

                    {
                      inputList.map((x, i) => {
                          return(
                            <div>
                                 {inputList.length - 1 === i && <button 
                              className="btn btn-danger" 
                              id="fixedbutton" 
                              style={{
                                "position":"fixed",
                                "bottom":"0px",
                                "right":"0px",
                                "cursor":"pointer"
                                 }}
                              onClick={handleAddClick}
                                 >
                              Save Changes
                              </button>}
                            </div>
                            )
                        })
                    }
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );    
}
}


export default PetBooking;