import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { packageBookings } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import $ from 'jquery';
function PackageBookingDetails({ match }) {
  const [room_id, set_room_id] = useState(null);
  const [guest_details, set_guest_details] = useState(null);
  const [guests, setguests] = useState(null);
  const [room_details, set_room_details] = useState(null);
  const [booking_details, set_booking_details] = useState(null);
  const [user_phone_number, set_phone_number] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [html, setHtml] = useState(false);
  const test2 = ""
  let url_data = window.location.href;
  url_data = url_data.split("?")[1];

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }


    const fetchData = async () => {
      setLoading(true)
      const booking_details = await axios.post( API_URL + '/cancel-tripjack/DisplayPackageBookingById/', {"id":url_data});
      const room_details = await axios.post( API_URL + '/cancel-tripjack/DisplayPackageRooms/', {'package_booking_id':url_data});
      const allCustomers = await axios.get( API_URL + '/accounts/showAllCx/')
      allCustomers.data.map((obj,i) => {
        var filtered = allCustomers.data.find(x => x.id == booking_details.data.user_id);
          obj.phone_number = filtered.phone_number
          set_phone_number(filtered.phone_number)
      })
      set_booking_details(booking_details.data);
      if(room_details.data != "No Rooms"){
        set_room_details(room_details.data);
      }
      setLoading(false)

    };
    fetchData();
  }, [updateResponse]);

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {

      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>

            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <h2>
                      Package Booking Details{console.log(booking_details)}
                    </h2>
                    <div class="container">
                      <div>
                        <p>User Contact Number: {user_phone_number}</p>
                        <p>Package Name: {booking_details.pkg_name}</p>
                        <p>Package Id: {booking_details.pkg_id}</p>
                        <p>No. Of Nights: {booking_details.pkg_nights}</p>
                        <p>No. Of Days: {booking_details.pkg_days}</p>
                        <p>No. Of Guests: {booking_details.pkg_guests}</p>
                        <p>Validity: {booking_details.pkg_validity}</p>
                        <p>Travel Date: {booking_details.pkg_travel_date}</p>
                        <p>Booking Date: {booking_details.pkg_booking_date}</p>
                        <p>Total Amount: {booking_details.pkg_total_amount}</p>
                        <p>Redeem Points: {booking_details.pkg_redeem_points}</p>
                        <p>Reward Points: {booking_details.pkg_reward_points}</p>
                        <p>Total Amount after Deduction: {booking_details.pkg_total_after_discount}</p>
                        {/*<p>Booking Type: {booking_details.book_type}</p>*/}
                        {/*<p>API Type: {booking_details.api_type}</p>*/}
                        <div>
                          {
                            booking_details.pack_room.map((room,i) => {
                              return(
                                <div>
                                  <h6>Room {i+1}</h6>
                                  <div style={{"margin-left":"20px"}}>
                                    <p>{room.no_of_adt != "" ? `No of adults: ${room.no_of_adt}` : ""}</p>
                                    <p>{room.no_of_chd != "" ? `No of child: ${room.no_of_chd}` : ""}</p>
                                      <div>
                                      {room.pack_guest.length > 0 ? <p><b>Guests</b></p> : ""}
                                      
                                        {
                                          room.pack_guest.map((guest,i) => {
                                            return(
                                               <p>
                                                 <span>{guest.fname != "" ? `${guest.fname}` : ""} </span>
                                                 <span>{guest.lname != "" ? `${guest.lname}` : ""}</span>
                                               </p>
                                              )
                                          })
                                        }
                                      </div>
                                  </div>
                                </div>
                                )
                            })
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    
  }
}

export default PackageBookingDetails;
