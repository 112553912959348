import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import $ from "jquery";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import { SupplierMaster } from "../dummyDb";

// var id="";
function SupplierMasterScreen(){

    var data = [];
    const [supplier_list, set_supplier_list] = useState([{
      supplier_name:"",
      contact_no:"",
      email_id:"",
      address:"",
    }])

    const [edit_flag, set_edit_flag] = useState(false)
    const [clear_flag, set_clear_flag] = useState(false)
    const [id, set_id] = useState("")

    useEffect(() => {
      ;
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
      const fetchData = async () => {
        const supplier_details = await axios.get( API_URL + '/packages/DisplayAllSupplierDetails/')
        set_supplier_list(supplier_details.data)
      };
      fetchData();

    },[])

    useEffect ( () => {
      
      if(edit_flag == true){
        
        getData(id)
      }
      if (clear_flag == true){
        
        document.getElementById("supplier_name").value = ""
        document.getElementById("contact_no").value = ""
        document.getElementById("email_id").value = ""
        document.getElementById("address").value = ""
        document.getElementById("create").classList.remove("hide");
        document.getElementById("update").classList.add("hide");
      }
    }, [edit_flag] [clear_flag])

  var columns = [
      {
        name: 'Supplier Name',
        selector: 'supplier_name',
      },
      {
        name: 'Supplier Contact',
        selector: 'contact_no',
      },
      {
        name: 'Supplier Email',
        selector: 'email_id',
      },
      {
        name: 'Supplier Address',
        selector: 'address',
      },
      {
        name: '',
        selector: 'edit',
      },
  ];

  const getData = async (id) => {         
    
    data = await axios.post( API_URL + '/packages/Display_Supplier_Details/', {'id':id})
    data = data.data
    document.getElementById("supplier_name").value = data.supplier_name
    document.getElementById("contact_no").value = data.contact_no
    document.getElementById("email_id").value = data.email_id
    document.getElementById("address").value = data.address
    document.getElementById("create").classList.add("hide");
    document.getElementById("update").classList.remove("hide");
    set_edit_flag(false)
    set_clear_flag(false)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return(
      <>
      <nav
        class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
      >
        <Navbar />
      </nav>
      <div class="main-content" id="panel">
        <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
          <TopNavbar />
        </nav>
        <div className="ml-5 mt-5 mr-5">
          <div className="container">
          <Button className="btn btn-sm" id="" onClick={e => set_clear_flag(true)}>+Add New</Button>
            <Form className="packageModal">
            <div className="row">
              <div className="col">
                <Form.Group>
                  <Form.Label>Supplier Name</Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Supplier Name"
                    name="supplier_name"
                    id="supplier_name"
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group>
                  <Form.Label>Supplier Contact</Form.Label>
                  <Form.Control
                    className=""
                    type="number"
                    placeholder="Supplier Contact"
                    name="contact_no"
                    id="contact_no"
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group>
                  <Form.Label>Supplier Email</Form.Label>
                  <Form.Control
                    className=""
                    type="email"
                    placeholder="Supplier Email"
                    name="email_id"
                    id="email_id"
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group>
                  <Form.Label>Supplier Address</Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Supplier Address"
                    name="address"
                    id="address"
                  />
                </Form.Group>
              </div>
            </div>
            </Form>
            <Button className="" onClick={(e) => setData("", "create")} id="create">Create</Button>
            <Button className="hide" onClick={(e) => setData(id, "update")} id="update">Update</Button>
          </div>
          <div className="card">
            {supplier_list.map((obj, index) => {
                var datalist = {
                "supplier_name":obj.supplier_name,
                "contact_no":obj.contact_no,
                "email_id":obj.email_id,
                "address":obj.address,
                "edit":
                <span id={obj.id}
                style={{"cursor":"pointer"}}
                onClick={async () => {
                  ;
                  getData(obj.id)
                  await set_id(obj.id) 
                  console.log("obj.id")
                  console.log(obj)
                  set_edit_flag(true)
                  set_clear_flag(false)
                }}
                >
                  Edit
                </span>,
              }
              data.push(datalist)
            })}
            <DataTableExtensions
                      title="SupplierMaster"
                      columns={columns}
                      data={data}
                      print={false}
                      export={false}
                      filter={false}
                      filterPlaceholder="search"
                      filterHidden={true}
                      filterDigit={1}
                    >
              <DataTable
                nHeader
                highlightOnHover
                pagination
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
      
      </>
    )
}

const createApi = async(req) => {      
  
  const supplier_details = await axios.post( API_URL + '/packages/Create_and_Update_Supplier_Details/', req)
  if(supplier_details.data == "Created successfully" || supplier_details.data == "Updated successfully"){
    
    swal(supplier_details.data)
    setTimeout(() => {window.location.reload()}, 1000)
    // swal("Created Successfully")
  }
  else{
    swal("Something went wrong, please try again")
  }
  console.log("supplier details")
  console.log(supplier_details)
}

const setData = (id, type) => {        
  
  if($("#supplier_name").val() == "" || $("#contact_no").val() == "" || $("#email_id").val() == "" || $("#address").val() == ""){
    swal("All fields required")
  }
  else{
    if(type == "create"){
      var obj = {
        supplier_name: document.getElementById("supplier_name").value,
        contact_no: document.getElementById("contact_no").value,
        email_id: document.getElementById("email_id").value,
        address: document.getElementById("address").value,
      }
    }
    if(type == "update"){
      
      var obj ={
        id:id,
        supplier_name: document.getElementById("supplier_name").value,
        contact_no: document.getElementById("contact_no").value,
        email_id: document.getElementById("email_id").value,
        address: document.getElementById("address").value,
      }
    }
    console.log("obj")
    console.log(obj)
    createApi(obj)
  }
}

export default SupplierMasterScreen