import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from 'sweetalert2'
import swal from "sweetalert"

function NewWalletAccount() {
  debugger;

  const [members, set_members] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);

  let url_data = window.location.href;

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      debugger
      setLoading(true)
      const show_all_members = await axios.get( API_URL + '/accounts/showAllCx/')
      console.log("show_all_members")
      console.log(show_all_members)
      set_members(show_all_members.data);
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    var array = [];
    members?.map((obj,indx) => {
      
      array.push(<option key={obj.id} value={obj.id}>{obj.phone_number}</option>)
    })
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>
            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="container">
                    <h2 className="mt-3 my-3">
                      Digital Wallet Account Creation
                    </h2>
                      <div>
                        <Form>
                        <Form.Group>
                            <Form.Label>Select Contact Number:</Form.Label>
                            <Form.Select id="CUSTOMER_ID" aria-label="Default select example">
                            {array}
                            </Form.Select>
                            <br></br>
                            <Form.Label>GDV Account Id:</Form.Label>
                            <Form.Control id="GDV_ID"
                              type="text"
                              placeholder=""
                              aria-label="Disabled input example"
                              placeholder="Enter GDV Account Id"
                            />
                            <br></br>
                            <Form.Label>Account type:</Form.Label>
                            <Form.Select id="ACCOUNT_TYPE" aria-label="Default select example">
                              <option>select account type</option>
                              <option value="1">M</option>
                              <option value="2">P</option>
                            </Form.Select>
                            <br></br>
                            <Form.Label>Points:</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control id="VAULT_BALANCE" aria-label="Amount" defaultValue="0" onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}/>
                            </InputGroup>
                          </Form.Group>
                          <Button variant="primary" className="mb-3" onClick={handleClick}>Create Digital wallet</Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
}
const handleClick = event => {
    
    var CUSTOMER_ID = document.getElementById('CUSTOMER_ID').value
    var GDV_ID = document.getElementById('GDV_ID').value
    var ACCOUNT_TYPE = document.getElementById('ACCOUNT_TYPE').value
    var VAULT_BALANCE = document.getElementById('VAULT_BALANCE').value
    var CREATED_BY = JSON.parse(localStorage.getItem("response"))
    var CREATED_BY = CREATED_BY.id
    if(CUSTOMER_ID != "" && GDV_ID != "" && VAULT_BALANCE != "" && ACCOUNT_TYPE != "select account type" && CREATED_BY != null){
    var newWallet = {
      "CUSTOMER_ID":CUSTOMER_ID,
      "GDV_ID":GDV_ID,
      "ACCOUNT_TYPE":ACCOUNT_TYPE,
      "VAULT_BALANCE":VAULT_BALANCE,
      "CREATED_BY":CREATED_BY
    } 
    Swal.fire({
        title: 'Create Digital Wallet?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create it!'
      }).then((result) => {
        
        if (result.isConfirmed) {
          const callAPI = async () => {
            const apiResponse = await axios.post( API_URL + '/points_system/addDigitalWallet/',newWallet)
            console.log("apiResponse")
            console.log(apiResponse)
            if(apiResponse.data == "Created Successfully"){
              Swal.fire(
                'Done!',
                'Digital Wallet created.',
                'success'
              ).then((result) => {
                window.location.href = DASHBOARD_URL + "/walletMembers/"
              })
            }
            else if(apiResponse.data == "GDV Account Id already exists"){
              swal(apiResponse.data)
            }
            else{
              swal("Something went wrong, Please try again later")
            }
          }
          callAPI()
        }
      })
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Digital Wallet Account not created.',
        text: 'Please provide all the fields to open an account!',
      })
    }
}
export default NewWalletAccount;