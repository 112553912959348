import React, { useState, useEffect } from "react";
import { Container, Row, ToggleButton, Badge} from "react-bootstrap";
import { GROUPBOOKING_LIST } from "../dummyDb";
import axios from "axios";
import { MDBDataTable, MDBInput } from 'mdbreact';
import { objectOf } from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import Swal from 'sweetalert2'
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";
import $ from "jquery"
import swal from "sweetalert";

var current_request = [{
      "booking_id":"",
      "booking_status":"",
      "booking_date":"",
      "comment":""	
  }]
var memberOrNot = []
function DirectPayScreen() {
  const [digitalWallet, setdigitalWallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [dataoftable, setdataoftable] = useState(null);
//   const [updatereq, setupdatereq] = useState([{
//     "booking_id":"",
//     "booking_status":"",
//     "booking_date":"",
//     "comment":""	
// }]);
  var dataTable;
  const redirectFun =(id)=>{
  
    window.location.href = DASHBOARD_URL + "/groupBookingDetails?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
    const fetchData = async () => {
      setLoading(true)
      const DisplayDirectpay = await axios.get( API_URL + '/Aggrepayment/DisplayDirectpay/')
      setdigitalWallet(DisplayDirectpay.data)
      const rowData = DisplayDirectpay.data      //old code
      var pushrow = [];
      rowData?.map((obj, indx) => {
        obj.name ? 
          pushrow.push({
            id: obj.id,
            name: obj.name,
            email: obj.email,
            phone_no: obj.contact_no,
            order_status: obj.order_status,
            amount: obj.amount,
            service: obj.service,
            description: obj.description,
            transaction_id: obj.Transaction_id,
            transaction_date_time: obj.Transaction_date,
            booking_status : <select name="booking_status" id={"booking_status"+obj.id} onChange={(e) => handleInputChange(e, 0, obj.id)}> <option value="PENDING" selected={obj.booking_status == "PENDING" ? 'selected' : ""}>Pending</option> <option value="SUCCESS" selected={obj.booking_status == "SUCCESS" ? "selected" : ""}>Success</option>  <option value="CANCELLED" selected={obj.booking_status == "CANCELLED" ? 'selected' : ""}>Cancel</option> </select>,
            booking_id : <input type="text" name="booking_id" id={"booking_id"+obj.id} placeholder="booking_id" defaultValue = {obj.booking_id} onChange={(e) => handleInputChange(e, 0, obj.id)}/>,
            booking_date : <input type="date" name="booking_date" id={"booking_date"+obj.id} defaultValue = {obj.booking_date} onChange={(e) => handleInputChange(e, 0, obj.id)}/>,
            comment : <textarea name="comment" id={"comment"+obj.id} onChange={(e) => handleInputChange(e, 0, obj.id)} placeholder="comment" rows="4" col="50"  defaultValue = {obj.comment}></textarea>,
            update : <Button variant="primary" className="updateBtn" onClick={() => UpdateDirectPay(current_request[0])}>Update</Button>
          })
        :
        console.log("Order Id not found in MisPayment")
      });
      dataTable =  {
        columns: [
          {
            label: "id",
            field: "id",
            width: 150,
          },
          {
            label: "Name",
            field: "name",
            width: 150,
          },
          {
            label: "Email Address",
            field: "email",
            width: 150,
          },
          {
            label: "Phone Number",
            field: "phone_no",
            width: 150,
          },
          {
            label: "Order Status",
            field: "order_status",
            width: 150,
          },
          {
            label: "Amount",
            field: "amount",
            width: 150,
          },
          {
            label: "Service",
            field: "service",
            width: 150,
          },
          {
            label: "Description",
            field: "description",
            width: 150,
          },
          {
            label: "Transaction id",
            field: "transaction_id",
            width: 150,
          },
          {
            label: "Transaction date Time",
            field: "transaction_date_time",
            width: 150,
          },
          {
            label: "Booking Status",
            field: "booking_status",
            width: 150,
          },
          {
            label: "Booking id",
            field: "booking_id",
            width: 150,
          },
          {
            label: "Booking Date",
            field: "booking_date",
            width: 150,
          },
          {
            label: "Comment",
            field: "comment",
            width: 150,
          },
          {
            label: "",
            field: "update",
            width: 150,
          },
        ],
        rows: pushrow,
      }
      setdataoftable(dataTable)
      setLoading(false)
    };
    fetchData();
  }, [updateResponse]);


const handleInputChange = (e, index, id) => {
  debugger
  current_request = [{
    "id":id,
    "booking_id":$("#booking_id"+id).val(),
    "booking_status":$("#booking_status"+id).val(),
    "booking_date":$("#booking_date"+id).val(),
    "comment":$("#comment"+id).val()
  }]
  // const { name, value } = e.target;
  // const list = [...current_request];
  // list[index][name] = value;
  // list[index]["id"] = id;
};

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {     
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
          <h2 className="mt-3 my-3">
            DirectPay
          </h2>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive vault_table">
                    <MDBDataTable Striped small bordered data={dataoftable} noBottomColumns={true} sortable={false} />
                  </div>
                </div>
              </div>
            </div>
            {/* <Button variant="primary" class="Save" onClick={UpdateMembers} style={{"left":"90%"}}>Save</Button> */}
          </div>
        </div>
      </>
    );
  }
}
const handleClick = event => {
  var userId = event.currentTarget.id;
  var willbeMember = event.target.checked
  if (event.target.checked) {
    memberOrNot.push({
      "userId":userId,
      "member_status":willbeMember,
    })
  }
  else {
    memberOrNot.push({
      userId:willbeMember
    })
  }
  localStorage.setItem("UpdateMember",JSON.stringify(memberOrNot))
}

const UpdateDirectPay = async (request) => {
  debugger;
  // var update_member_req = JSON.parse(localStorage.getItem("UpdateMember"))

  // const updateCx = axios.post( API_URL + '/Aggrepayment/UpdateDirectpay/',{"id":3,"booking_id":"TJS202500356428","booking_status":"SUCCESS","booking_date":"28/09/2022","comment":"xyz"})
  const updateCx = await axios.post( API_URL + '/Aggrepayment/UpdateDirectpay/',request)
  if(updateCx.data == "Updated Successfully"){
    swal("Updated Successfully")
    // Swal.fire({
    //   // position: 'top-end',
    //   icon: 'success',
    //   title: 'Your work has been Updated',
    //   showConfirmButton: false,
    //   timer: 1300
    // })
  }
  else{
    swal("Something went wrong, Please try again")
  }
}



export default DirectPayScreen;
