import React, { useState, useEffect } from "react";
import { Container, Toast } from "react-bootstrap";
import axios from "axios";
import { API_URL, DASHBOARD_URL, TYPE } from "../const";
import { MARKUP_LIST } from "../dummyDb";
import MarkUpCreateModal from "../Components/MarkUpCreateModal";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function MarkUpScreen() {
  const [markupList, setMarkupList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [formType, setFormType] = useState("create");
  const [markUpRowData, setMarkUpRowData] = useState(null);

  const [listenToRes, setListenToRes] = useState(false);

  const [createMarkupres, setCreateMarkupres] = useState(false);

  const [deleteState, setDeleteState] = useState(false);

  const setUpModal = (modalType) => {
    if (modalType == "create") {
      setFormType("create");
      setModalShow(true);
    } else if (modalType == "update") {
      setFormType("update");
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      if (TYPE == "TEST") {
        var token = JSON.parse(localStorage.getItem("response")).access;

        setLoading(true);
        try {
          const { data: response } = await axios.get(
            API_URL + "/dashboard/create_mark_up/"
          );
          setMarkupList(response);
        } catch (error) {
          console.error(error.message);
        }
        setLoading(false);
      } else {
        setMarkupList(MARKUP_LIST);
        setLoading(false);
        console.log(markupList);
        console.log(loading);
      }
    };

    fetchData();
  }, [listenToRes, createMarkupres,deleteState]);

  //update active status for markup
  const updateStatusApi = async (urlData, requestData) => {
    var token = JSON.parse(localStorage.getItem("response")).access;
    try {
      const { data: response } = await axios.put(
        API_URL + urlData,
        { requestData }
      );

      console.log(response.status);

      if (response.status == "success") {
        if (listenToRes) {
          setListenToRes(false);
        } else {
          setListenToRes(true);
        }
      }
      return response;
    } catch (error) {
      return error;
      // console.error(error.message);
    }
  };

  const updateStatus = (markupId) => {
    console.log(markupId);
    updateStatusApi("/dashboard/update_status/", markupId.id);
  };

  const handleMarkupDelete = async (markupRow)=>{
    var token = JSON.parse(localStorage.getItem("response")).access;
    // var requestData = {"Markup_id":markupRow.id}
    var urlData = '/dashboard/delete_mark_up/';
    try {
      const { data: response } = await axios.post(
        API_URL + urlData,
        {"Markup_id":markupRow.id}
      );
      if(deleteState){
        setDeleteState(false);
      }
      else{
        setDeleteState(true);
      }
      console.log(response);

      return response;
    } catch (error) {
      return error;
      // console.error(error.message);
    }
  }

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>
          <div className="m-5">
         
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0">MarkUp Price Set</h3>
                      </div>
                      <div class="col text-right">
                        <a
                          href="#!"
                          onClick={() => setModalShow(true)}
                          class="btn btn-sm btn-primary"
                        >
                          Create New
                        </a>

                        <MarkUpCreateModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          formType={formType}
                          setLoading={setLoading}
                          markUpRowData={markUpRowData}
                          setCreateMarkupres={setCreateMarkupres}
                          markupres={createMarkupres}
                          modalType={"MARKUP"}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">MarkUp Name</th>
                          <th scope="col">Created On</th>
                          {/* <th scope="col">product</th>

                          <th scope="col">Travell Type</th> */}
                          <th scope="col">MarkUp Type</th>
                          <th scope="col">Markup Value</th>
                          <th scope="col" style={{'textAlign':'center'}}>Delete</th>

                          {/* <th scope="col">Pax Types</th> */}
                          {/* <th scope="col">Edit</th>
                          <th scope="col">state</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {markupList.markup_list.map((markupRow, indx) => {
                          var paxVar = "";
                          console.log(markupRow);
                          if (
                            markupRow.pax_Adult == false &&
                            markupRow.pax_child == false &&
                            markupRow.pax_infant == false
                          ) {
                            paxVar = "ALL";
                          } else {
                            if (markupRow.pax_Adult) {
                              paxVar += "A,";
                            }
                            if (markupRow.pax_child) {
                              paxVar += "C,";
                            }
                            if (markupRow.pax_infant) {
                              paxVar += "I";
                            }
                          }

                          return (
                            <tr
                              className={
                                markupRow.markup_status
                                  ? "activeMarkUPCls"
                                  : "inactiveMarkUPCls"
                              }
                            >
                              <th scope="row">{markupRow.markup_name}</th>
                              <td>{markupRow.created_on}</td>

                              <td>{markupRow.markup_type}</td>
                              <td>
                                <i class="fas fa-arrow-up text-success mr-3"></i>{" "}
                                {markupRow.amount_value}{" "}
                                {markupRow.markup_type == "PERCENTAGE"
                                  ? "%"
                                  : "₹"}
                              </td>
                              <td style={{'textAlign':'center'}}>
                              <a href="#" onClick={(e)=>{handleMarkupDelete(markupRow)}}><img src={process.env.PUBLIC_URL + "/assets/img/icons/common/dustbin.png"} class="navbar-brand-img" alt="..." /></a>
                              </td>

                              {/* <td>{paxVar}</td> */}
                              {/* <td>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    setMarkUpRowData(markupRow);
                                    setUpModal("update");
                                  }}
                                >
                                  Edit
                                </a>
                              </td>

                              <td className="">
                                <span
                                  class="badge badge-dot mr-4 cursorCls"
                                  onClick={(e) => {
                                    updateStatus(markupRow);
                                  }}
                                >
                                  <i
                                    className={
                                      markupRow.markup_status
                                        ? "bg-success"
                                        : "bg-warning"
                                    }
                                  ></i>
                                  <span class="status">
                                    {markupRow.markup_status
                                      ? "active"
                                      : "inactive"}
                                  </span>
                                </span>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MarkUpScreen;
