import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { packageBookings } from "../dummyDb";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

function PackageGroupBooking() {
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);

  const redirectFun =(id)=>{
    
    window.location.href = DASHBOARD_URL + "/packageGroupDetails?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }


    const fetchData = async () => {
      debugger;
      setLoading(true)
      // const booking_details = await axios.get( API_URL + '/group_bookings/getFlightBookingDetails/')
      const booking_details = packageBookings.groupbookingList
      set_booking_details_list(booking_details)
      console.log("array")
      console.log(booking_details)
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  




  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Group Booking ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">No. of Passengers</th>
                          <th scope="col">Is International</th>
                          <th scope="col">Package</th>
                          <th scope="col">Travelling Date</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          booking_details_list?.map((obj, indx)=>{
                            return(
                          <tr>
                            <td scope="col">{obj.ght_grp_booking_id}</td>
                            <td scope="col">{obj.cust_Name}</td>
                            <td scope="col">{obj.passenger_Guest_Count}</td>
                            <td scope="col">{obj.is_International?'true':'false'}</td>
                            <td scope="col">{obj.package_Name}</td>
                            <td scope="col">{obj.check_availability}</td>
                            <td scope="col"> 
                              <button class="btn btn-primary" style={{cursor:"pointer"}} onClick = {
                                    (e)=>{
                                  e.preventDefault()
                                    redirectFun(obj.id)
                                  }

                                  } variant="primary"> View Details 
                              </button> 
                            </td>
                          </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageGroupBooking;
