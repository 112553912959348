import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { COMPANY_MAP_LIST, MARKUP_LIST } from "../dummyDb";
import MarkUpCreateModal from "../Components/MarkUpCreateModal";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

// Be sure to include styles at some point, probably during your bootstrapping
// import "react-select/dist/css/react-select.css";

function CompanyMarkupScreen() {
  const [markupList, setMarkupList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [markUpName, setMarkUpName] = useState("");

  const [companySelectedOptn, setCompanySelectedOptn] = useState("");
  const [markupSelectedOptn, setMarkupSelectedOptn] = useState("");
  const [product, setProduct] = useState("");
  const [travelType, setTravelType] = useState('DOMESTIC');

  const [responseApi, setResponseApi] = useState("");

  const [updateResponse, setUpdateResponse] = useState(false);

  var options = { value: "vanilla", label: "Vanilla" };

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      console.log("DDDDDDDDDDDDDDDDDdd");
      console.log(product);
      console.log(markupSelectedOptn);


      if (TYPE == "TEST") {
        setLoading(true);
        var token = JSON.parse(localStorage.getItem("response")).access;
        try {
          const { data: response } = await axios.get(
            API_URL + "/dashboard/get_company_marup_list/");
          console.log(response);

          setMarkupList(response);
        } catch (error) {
          // swal(error)
          console.error(error.message);
        }
        setLoading(false);
      } else {
        setMarkupList(COMPANY_MAP_LIST);
        setLoading(false);
      }
    };

    fetchData();
  }, [updateResponse]);

  const putApiCall = async (urlData, requestData) => {
    var token = JSON.parse(localStorage.getItem("response")).access;
    try {
      const { data: response } = await axios.put(
        API_URL + urlData,

        { requestData }
      );
      if (response.status == "success") {
        if (updateResponse) {
          setUpdateResponse(false);
        } else {
          setUpdateResponse(true);
        }
      }
      return response;
    } catch (error) {
      return error;
      // console.error(error.message);
    }
  };

  const setUpCompanyMarupFun = () => {
    if (
      companySelectedOptn == "" &&
      markupSelectedOptn == "" &&
      product == ""&&
      travelType == ""
    ) {
      swal("Fields cannot be empty");
      return;
    }
    if (companySelectedOptn == "") {
      swal("select a company");
      return;
    }
    if (markupSelectedOptn == "") {
      swal("select a markup");
      return;
    }
    if (product == "") {
      swal("select a product");
      return;
    }

    var responseData = JSON.stringify({
      company_id: companySelectedOptn.selectedOptions.value.id,
      markup_id: markupSelectedOptn.selectedOptions.value.id,
      product_value:product.selectedOptions.label,
      travell_type:travelType.selectedOptions.value.id,
    });

    putApiCall("/dashboard/get_company_marup_list/", responseData);

  };

  const updateProduct = (value) => {
    setProduct(value);
  };

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <CustomToggle
                          toggle_type={COMPANY_TOGGLE}
                          product_type={product}
                          selectedOptn={setCompanySelectedOptn}
                        />
                      </div>

                      <div class="col">
                        <CustomToggle
                          toggle_type={PRODUCT_TOGGLE}
                          product_type={product}
                          selectedOptn={updateProduct}
                        />
                      </div>

                      <div class="col">
                        <CustomToggle
                          toggle_type={TRAVEL_TYPE}
                          product_type={product}
                          selectedOptn={setTravelType}
                        />
                      </div>

                      <div class="col">
                        <CustomToggle
                          toggle_type={MARKUP_TOGGLE}
                          product_type={product}
                          selectedOptn={setMarkupSelectedOptn}
                        />
                      </div>
                      <div class="col text-right">
                        <a
                          href="#!"
                          class="btn btn-sm btn-primary"
                          onClick={setUpCompanyMarupFun}
                        >
                          Set Markup
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Company Name</th>
                          <th scope="col">Markup Name</th>
                          <th scope="col">Product</th>
                          <th scope="col">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          markupList?.markupComp.map((obj, indx)=>{
                            return(
                              <tr>
                            <td scope="col">{obj.company_id}</td>
                            <td scope="col">{obj.markup}</td>
                            <td scope="col">{obj.product}</td>
                            <td scope="col">{obj.product_type}</td>
                          </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyMarkupScreen;
