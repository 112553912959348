import axios from "axios";
import React, { useState, useEffect } from "react";
import MarkUpCreateModal from "../Components/MarkUpCreateModal";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { API_URL, TYPE } from "../const";
import { MARKUP_LIST } from "../dummyDb";

function ServiceScreen() {
  const [markupList, setMarkupList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [formType, setFormType] = useState("create");
  const [markUpRowData, setMarkUpRowData] = useState(null);

  const [listenToRes, setListenToRes] = useState(false);

  const [createMarkupres, setCreateMarkupres] = useState(false);
  const [deleteState, setDeleteState] = useState(false);

  const setUpModal = (modalType) => {
    if (modalType == "create") {
      setFormType("create");
      setModalShow(true);
    } else if (modalType == "update") {
      setFormType("update");
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      if (TYPE == "TEST") {
        var token = JSON.parse(localStorage.getItem("response")).access;

        setLoading(true);
        try {
          const { data: response } = await axios.get(

            API_URL + "/dashboard/create_service_up/"
          );
          setMarkupList(response);
        } catch (error) {
          console.error(error.message);
        }
        setLoading(false);
      } else {
        setMarkupList(MARKUP_LIST);
        setLoading(false);
      }
    };

    fetchData();
  }, [listenToRes, createMarkupres, deleteState]);

  //update active status for markup
  const updateStatusApi = async (urlData, requestData) => {
    var token = JSON.parse(localStorage.getItem("response")).access;
    try {
      const { data: response } = await axios.put(
        API_URL + urlData,
        { requestData }
      );

      if (response.status == "success") {
        if (listenToRes) {
          setListenToRes(false);
        } else {
          setListenToRes(true);
        }
      }
      return response;
    } catch (error) {
      return error;
    }
  };


  const handleServiceDelete = async (markupRow) => {
    var token = JSON.parse(localStorage.getItem("response")).access;
    // var requestData = {"Markup_id":markupRow.id}
    var urlData = "/dashboard/delete_service_up/";
    try {
      const { data: response } = await axios.post(API_URL + urlData, {
        service_id: markupRow.id,
      });
      if (deleteState) {
        setDeleteState(false);
      } else {

        setDeleteState(true);
      }
      return response;
    } catch (error) {
      return error;
      // console.error(error.message);
    }

  };

  const updateStatus = (markupId) => {
    updateStatusApi("/dashboard/update_status/", markupId.id);
  };
  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>
          <div className="m-5">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0">Service List</h3>
                      </div>
                      <div class="col text-right">
                        <a
                          href="#!"
                          onClick={() => setModalShow(true)}
                          class="btn btn-sm btn-primary"
                        >
                          Create New Service Charge
                        </a>

                        <MarkUpCreateModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          formType={formType}
                          setLoading={setLoading}
                          markUpRowData={markUpRowData}
                          setCreateMarkupres={setCreateMarkupres}
                          markupres={createMarkupres}
                          modalType={"SERVICE"}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Service Name</th>
                          <th scope="col">Created On</th>
                          {/* <th scope="col">product</th>
  
                            <th scope="col">Travell Type</th> */}
                          <th scope="col">Service Type</th>
                          <th scope="col">Service Charge Value</th>
                          <th scope="col" style={{'textAlign':'center'}}>Delete</th>

                          {/* <th scope="col">Pax Types</th> */}
                          {/* <th scope="col">Edit</th>
                            <th scope="col">state</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {markupList.service_list.map((markupRow, indx) => {
                          var paxVar = "";

                          return (
                            <tr
                              className={
                                markupRow.markup_status
                                  ? "activeMarkUPCls"
                                  : "inactiveMarkUPCls"
                              }
                            >
                              <th scope="row">{markupRow.service_name}</th>
                              <td>{markupRow.created_on}</td>

                              <td>{markupRow.service_type}</td>
                              <td>
                                <i class="fas fa-arrow-up text-success mr-3"></i>{" "}
                                {markupRow.amount_value}{" "}
                                {markupRow.service_type == "PERCENTAGE"
                                  ? "%"
                                  : "₹"}
                              </td>

                              <td style={{ textAlign: "center" }}>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    handleServiceDelete(markupRow);
                                  }}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/img/icons/common/dustbin.png"
                                    }
                                    class="navbar-brand-img"
                                    alt="..."
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceScreen;
