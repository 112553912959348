import {
    CUSTOMER_LIST_REQUEST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAILURE,
    TRAINER_LIST_REQUEST,
    TRAINER_LIST_SUCCESS,
    TRAINER_LIST_FAILURE,
    DATA_TYPE,
    GET_USER_LIST,
    MEMBER_LIST_DATA
} from "../const";



export const getCustDataReducer = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER_LIST_REQUEST:
            return {
                loading: true
            }

        case CUSTOMER_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                MEMBER_LIST_DATA: action.payload
            }

        case CUSTOMER_LIST_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const getTrainerListReducer = (state = {}, action) => {
    switch (action.type) {
        case TRAINER_LIST_REQUEST:
            return {
                loading: true
            }

        case TRAINER_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                TRAINER_LIST_DATA: action.payload
            }

        case TRAINER_LIST_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

