import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { packageBookings } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

function GroupBookingDetails({ match }) {

  const [booking_details, set_booking_details] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  
  let url_data = window.location.href;
  url_data = url_data.split("?")[1];

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }


    const fetchData = async () => {
      setLoading(true)
      // const booking_details = await axios.post( API_URL + '/group_bookings/get_flight_detailed_booking/', {'id':url_data});
      const booking_details = packageBookings.groupbookingDetails[url_data];
      set_booking_details(booking_details);
      console.log(booking_details)
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  




  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {

      debugger;
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>

            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <h2>
                      Group Booking Details
                    </h2>
                    <div class="container">
                      <div>
                        <p>Name: {booking_details.cust_Name}</p>
                        <p>Email: {booking_details.cust_Email}</p>
                        <p>Contact: {booking_details.cust_Phone}</p>
                        <p>Package Name: {booking_details.package_Name}</p>
                        <p>Customer City: {booking_details.city_Name}</p>
                        <p>Package Cost: {booking_details.package_Cost}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    
  }
}

export default GroupBookingDetails;
