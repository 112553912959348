import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
} from "../const";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import swal from "sweetalert"

function UpdateAccountStatus() {
  const [members, set_members] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);

  function myFunction(event)
  {
    console.log(event.target.value);
    console.log(array2);
    var gdv_account_id = array2.filter(person => person.phone_number == event.target.value)
    console.log(gdv_account_id[0].gdv_account_id)
    // document.getElementById("accountId").value(gdv_account_id);
    document.getElementById("accountId").value = gdv_account_id[0].gdv_account_id;
  }
  useEffect(() => {
    
    const fetchData = async () => {
        
      setLoading(true)
      const show_all_members = await axios.get( API_URL + '/points_system/showCustomers/')
      // var filtered = allCustomers.data.find(x => x.id == obj.customer_id);
      // obj.phone_number = filtered.phone_number
      const allCustomers = await axios.get( API_URL + '/accounts/showAllCx/')
      show_all_members.data.map((obj,i) => {
        var filtered = allCustomers.data.find(x => x.id == obj.customer_id);
          obj.phone_number = filtered.phone_number
      })
      set_members(show_all_members.data)
      setLoading(false)
      
      
    };

    fetchData();
  }, [updateResponse]);

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    var array = [];
    var array2 = [];

    members?.map((obj,indx) => {
      console.log(members)
      // array.push(<option key={obj.gdv_account_id} value={obj.gdv_account_id}>{obj.gdv_account_id}</option>)
      array.push(<option key={obj.phone_number} value={obj.phone_number}>{obj.phone_number}</option>) 
      array2.push({"phone_number":obj.phone_number,"gdv_account_id":obj.gdv_account_id})

    })
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>
            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="container">
                    <h2 className="mt-3 my-3">
                      Account Status Update
                    </h2>
                      <div>
                        <Form>
                        <Form.Group>
                            <Form.Label>Mobile Number:</Form.Label>
                            <Form.Select id="Mobile_number" aria-label="Default select example" onChange={myFunction}>
                            <option>Select Mobile Number</option>
                            {array}
                            </Form.Select>
                            <br></br>
                            <Form.Label>Account Id:</Form.Label> <br/> 
                            <Form.Control id="accountId"
                            readOnly
                            
                              
                            />
                        
                            {/* <Form.Select id="account_status" aria-label="Default select example"> 
                              <option>select account Id</option>
                              
                            </Form.Select> */}
                            <br></br>
                            <Form.Label>Account Status:</Form.Label>
                            <Form.Select id="ACCOUNT_STATUS" aria-label="Default select example">
                              <option>select account Status</option>
                              <option value="1">Active</option>
                              <option value="2">InActive</option>
                            </Form.Select>
                            <br></br>
                          </Form.Group>
                          <Button variant="primary" className="mb-3" onClick={handleClick}>Update Status</Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
}
const handleClick = event => {
    // var phone_number = document.getElementById("accountId").value = gdv_account_id[0].gdv_account_id;
    
    var GDV_ACCOUNT_ID = document.getElementById('accountId').value
    var ACCOUNT_STATUS = document.getElementById('ACCOUNT_STATUS').value
    if(/*phone_number = 'Select Mobile Numbers' &&*/ ACCOUNT_STATUS != 'select account Status' && GDV_ACCOUNT_ID != 'select account Id')
    {
    var updateReq = {
      // "id":phone_number,
      "id":GDV_ACCOUNT_ID,
      "account_status":ACCOUNT_STATUS
    } 
    Swal.fire({
        title: 'Change Account status?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create it!'
      }).then((result) => {
        if (result.isConfirmed) {
          const apiResponse = axios.post( API_URL + '/points_system/updateWalletAccountStatus/',updateReq)
          Swal.fire(
            'Done!',
            'Account Status Changed.',
            'success'
          )
          .then((result) => {
            window.location.href = DASHBOARD_URL + "/walletMembers/"
          })
        }
      })
}
else{
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Select All the Fields!',
      })
}
}
export default UpdateAccountStatus;