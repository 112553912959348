import React, { useState, useEffect } from "react";
import { Container, Row, ToggleButton, Badge } from "react-bootstrap";
import { GROUPBOOKING_LIST } from "../dummyDb";
import axios from "axios";
import { MDBDataTable, MDBInput } from 'mdbreact';
import { objectOf } from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import Swal from 'sweetalert2'
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";
import swal from "sweetalert";


var memberOrNot = []
function DigitalVaultScreen() {
  const [digitalWallet, setdigitalWallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [dataoftable, setdataoftable] = useState(null);
  const [membercheck, setmembercheck] = useState([{
    "userId":"",
    "member_status":""
  }]);
  var dataTable;
  const redirectFun =(id)=>{
  
    window.location.href = DASHBOARD_URL + "/groupBookingDetails?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
    const handleCheck = async (e) => {
      const { name, checked} = e.target;
      const list = [...membercheck];
      list[0][name] = checked;
      list[0]["userId"] = e.target.id;
      await setmembercheck(list);
      AddRemoveMember()
    };

    const fetchData = async () => {
      setLoading(true)
      const showAllCx = await axios.get( API_URL + '/accounts/showAllCx/')
      setdigitalWallet(showAllCx.data)
      const rowData = showAllCx.data
      var pushrow = [];
      rowData?.map((obj, indx) => {
          pushrow.push({
            first_name: obj.first_name,
            last_name: obj.last_name,
            mobile: obj.phone_number,
            email: obj.email,
            city: obj.city,
            country: obj.country,
            state: obj.state,
            zipcode: obj.zipcode,
            // member:obj.ght_member ? <MDBInput type ="checkbox" class="makeMember" id={obj.phone_number} name="member_status" onChange={handleClick}/> :<MDBInput type ="checkbox" class="makeMember" id={obj.phone_number} onChange={handleClick}/>,
            member_check : <MDBInput type ="checkbox" class="makeMember" name="member_status" defaultChecked={obj.ght_member == true ? true : false} id={obj.phone_number} onChange={(e) => handleCheck(e)}/>

          });
      });
      dataTable =  {
        columns: [
          {
            label: "First Name",
            field: "first_name",
            width: 150,
          },
          {
            label: "Last Name",
            field: "last_name",
            width: 150,
          },
          {
            label: "Mobile number",
            field: "mobile",
            width: 100,
          },
          {
            label: "Email Id",
            field: "email",
            width: 200,
          },
          {
            label: "City",
            field: "city",
            width: 150,
          },
          {
            label: "Country",
            field: "country",
            width: 100,
          },
          {
            label: "State",
            field: "state",
            width: 150,
          },
          {
            label: "Zipcode",
            field: "zipcode",
            width: 150,
          },
          // {
          //   label: "Status",
          //   field: "status",
          //   width: 150,
          // },
          // {
          //   label: "Member Status",
          //   field: "member_status",
          //   width: 150,

          // },
          {
            label: "Add Member",
            field: "member_check",
            width: 150,
          },
        ],
        rows: pushrow,
      }
      setdataoftable(dataTable)
      setLoading(false)
    };
    fetchData();
  }, [updateResponse]);

  const AddRemoveMember = async () => {
    swal({
        text: `Do you want to update?`,
        buttons: [
                'No',
                'Yes'
              ],
    })
    .then(async function(isConfirm) {
      if (isConfirm){
        const member_check = await axios.post( API_URL + '/accounts/updateMember/',membercheck)
        if(member_check){
          swal("Member Updated")
        }
        else{
          swal("Member not updated")
        }
      }
    });
    
  }

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {     
    return (
      <>
      <span>{console.log("membercheck")}</span>
      <span>{console.log(membercheck)}</span>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
          <h2 className="mt-3 my-3">
          Card Membership
          </h2>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive vault_table">
                    <MDBDataTable Striped small bordered data={dataoftable} noBottomColumns={true} sortable={false} />
                  </div>
                </div>
              </div>
            </div>
            {/*<Button variant="primary" class="Save" onClick={UpdateMembers} style={{"left":"90%"}}>Save</Button>*/}
          </div>
        </div>
      </>
    );
  }
}
const handleClick = event => {
  var userId = event.currentTarget.id;
  var willbeMember = event.target.checked
  if (event.target.checked) {
    memberOrNot.push({
      "userId":userId,
      "member_status":willbeMember,
    })
  }
  else {
    memberOrNot.push({
      userId:willbeMember
    })
  }
  localStorage.setItem("UpdateMember",JSON.stringify(memberOrNot))
}

function UpdateMembers(){
  var update_member_req = JSON.parse(localStorage.getItem("UpdateMember"))
  const updateCx = axios.post( API_URL + '/accounts/updateMember/',update_member_req)
  Swal.fire({
    // position: 'top-end',
    icon: 'success',
    title: 'Your work has been saved',
    showConfirmButton: false,
    timer: 1300
  })
}

export default DigitalVaultScreen;
