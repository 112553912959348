import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import {
	Form,
	Button
} from "react-bootstrap";
import React, { useState,useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { API_URL, SUPPORT_URL } from "../const";
import axios from "axios";
const DirectPay = () => {
	useEffect(()=>{
		if (localStorage.getItem("response") === null) {
	      window.location.href = "/login";
	    }
	},[])
	const [formData, setFormData] = useState({
		name: undefined,
		email: undefined,
		phone: undefined,
		amount: undefined,
		payforservices: undefined,
		reason: undefined,
	});
	const [isName, setIsName] = useState(true);
	const [isEmail, setIsEmail] = useState(true);
	const [isPhone, setIsPhone] = useState(true);
	const [isAmount, setIsAmount] = useState(true);
	const [isPayForServices, setIsPayForServices] = useState(true);
	const [isReason, setIsReason] = useState(true);
	const [isSuccess,setIsSuccess] = useState(false);
	const handleFormChange = (e, key, val, isValid) => {
		e.preventDefault();
		let tempFormArr = { ...formData };
		tempFormArr[key] = val;
		setFormData(tempFormArr);
		if (val == "" || val == undefined) {
			isValid(false);
			// e.target.style.border = "1px solid red";
		} else {
			if (key == "name") {
				setIsName(true);
			}
			if (key == "email") {
				setIsEmail(true);
			}
			if (key == "phone") {
				setIsPhone(true);
			}
			if (key == "amount") {
				setIsAmount(true);
			}
			if (key == "payforservices") {
				setIsPayForServices(true);
			}
			if (key == "reason") {
				setIsReason(true);
			}
		}
		if (key == "payforservices" && val != 6) {
			setIsReason(true);
		}
	};
	const validateForm = () => {
		var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		for (let key in formData) {
			if (key != "reason") {
				if (formData[key] == "" || formData[key] == undefined) {
					if (
						key == "name" &&
						(formData[key] == "" || formData[key] == undefined)
					) {
						setIsName(false);
					}
					if (
						key == "email" &&
						(formData[key] == "" || formData[key] == undefined)
					) {
						setIsEmail(false);
					}
					if (
						key == "phone" &&
						(formData[key] == "" || formData[key] == undefined)
					) {
						setIsPhone(false);
					}
					if (
						key == "amount" &&
						(formData[key] == "" || formData[key] == undefined)
					) {
						setIsAmount(false);
					}
					if (
						key == "payforservices" &&
						(formData[key] == "" || formData[key] == undefined)
					) {
						setIsPayForServices(false);
					}

					return false;
				}
				if (key == "email" && !formData[key].match(mailformat)) {
					setIsEmail(false);

					return false;
				}
				if (key == "phone" && !formData[key].match(phoneno)) {
					setIsPhone(false);

					return false;
				}
			}
		}
		if (
			formData["payforservices"] == 6 &&
			(formData["reason"] == "" || formData["reason"] == undefined)
		) {
			setIsReason(false);
			return false;
		}
		return true;
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		// checks if form has all validated values
		if (!validateForm()) {
			return;
		}else{
			let response = JSON.parse(localStorage.response)

			axios.post(API_URL + "/mispay/mispayments/",{

				"phone" : response.username,
				"service" : formData['payforservices'],
				"email" : formData['email'],
				"name" : formData['name'],
				"contact" : formData['phone'],
				"amount" : formData['amount'],
				"reason" : formData['reason'],
				"link": SUPPORT_URL+'/pay'


			}).then(res=>{
				setIsSuccess(true);
				setFormData({
					name: '',
					email: '',
					phone: '',
					amount: '',
					payforservices: '',
					reason: '',
				});
				console.log(res)
			}).catch(err=>{console.log(err)})
		}
		
	};
	return (
		<>
			<nav
				className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
				id="sidenav-main"
			>
				<Navbar />
			</nav>
			<div className="main-content" id="panel">
				<nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
					<TopNavbar />
				</nav>

				<div className="m-5 card">
					<div className="row justify-content-md-center">
						<Form className="m-5" onSubmit={handleSubmit}>
						<Alert
								variant="success col-md-8 ml-3"
								className={`${isSuccess ? "" : "hide"}`}
							>
								Send Mail Successfully
							</Alert>
						<div className="col-md-12 row">
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicName"
								>
									<Form.Label>Name<span style={{color: "red"}}>*</span></Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Name" value={formData['name']}
										onChange={(e) => {
											handleFormChange(
												e,
												"name",
												e.target.value,
												setIsName
											);
										}}
									/>
									<Form.Text
										className={`text-muted ${
											isName ? "hide" : "invalidCls"
										}`}
									>
										<span style={{color: "red"}}>Name is required</span>
									</Form.Text>
								</Form.Group>
							</div>
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicEmail"
								>
									<Form.Label>Email address<span style={{color: "red"}}>*</span></Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter Email" value={formData['email']}
										onChange={(e) => {
											handleFormChange(
												e,
												"email",
												e.target.value,
												setIsEmail
											);
										}}
									/>
									<Form.Text
										className={`text-muted ${
											isEmail ? "hide" : "invalidCls"
										}`}
									><span style={{color: "red"}}>Email is required</span>
										
									</Form.Text>
								</Form.Group>
							</div>
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicContact"
								>
									<Form.Label>Contact<span style={{color: "red"}}>*</span></Form.Label>
									<Form.Control
										type="number"
										placeholder="Contact" value={formData['phone']}
										onChange={(e) => {
											handleFormChange(
												e,
												"phone",
												e.target.value,
												setIsPhone
											);
										}}
									/>
									<Form.Text
										className={`text-muted ${
											isPhone ? "hide" : "invalidCls"
										}`}
									><span style={{color: "red"}}>Contact is required</span>
										
									</Form.Text>
								</Form.Group>
							</div>
						</div>	
						<div className="col-md-12 row">
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicAmount"
								>
									<Form.Label>Amount<span style={{color: "red"}}>*</span></Form.Label>
									<Form.Control
										type="number"
										placeholder="Amount" value={formData['amount']}
										onChange={(e) => {
											handleFormChange(
												e,
												"amount",
												e.target.value,
												setIsAmount
											);
										}}
									/>
									<Form.Text
										className={`text-muted ${
											isAmount ? "hide" : "invalidCls"
										}`}
									><span style={{color: "red"}}>Amount is required</span>
										
									</Form.Text>
								</Form.Group>
							</div>
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicSelectSearvices"
								>
									<Form.Label>Service<span style={{color: "red"}}>*</span></Form.Label>
									<Form.Select
										aria-label="Default select example"
										className="form-control"
										placeholder="Select Services"  value={formData['payforservices']}
										onChange={(e) => {
											handleFormChange(
												e,
												"payforservices",
												e.target.value,
												setIsPayForServices
											);
										}}
									>
										<option>---Select Services---</option>
										<option value="7">Flight</option>
										<option value="8">Hotel</option>
										<option value="9">Bus</option>
										<option value="1">Holidays Packages</option>
										<option value="2">Adventure</option>
										<option value="3">Cabs</option>
										<option value="4">Things To Do</option>
										<option value="5">Train</option>
										<option value="6">Others</option>
									</Form.Select>
									<Form.Text
										className={`text-muted ${
											isPayForServices ? "hide" : "invalidCls"
										}`}
									><span style={{color: "red"}}>Pay For Services is required</span>
										
									</Form.Text>
								</Form.Group>
							</div>
							<div className="col-md-4">
								<Form.Group
									className="mb-3"
									controlId="formBasicReason"
								>
									<Form.Label>Description</Form.Label>
									<Form.Control
										type="text"
										placeholder="Description" value={formData['reason']}
										onChange={(e) => {
											handleFormChange(
												e,
												"reason",
												e.target.value,
												setIsReason
											);
										}}
									/>
									<Form.Text
										className={`text-muted ${
											isReason ? "hide" : "invalidCls"
										}`}
									><span style={{color: "red"}}>Description is required</span>
										
									</Form.Text>
								</Form.Group>
							</div>
						</div>
						<div className="row justify-content-md-center m-3">
							<Button variant="primary" type="submit" style={{width:'125px'}}>
								Send Mail
							</Button>
						</div>
							
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};

export default DirectPay;
