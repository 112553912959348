import React, { useState, useEffect, useMemo } from "react";
import { Container, ToggleButton, Dropdown } from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { GROUPBOOKING_LIST, package_list } from "../dummyDb";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

var list = [];
function GroupBookingScreen() {
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [statusValue, setStatusValue] = useState(null); 

    // handle input change
  const handleInputChange = (e, index) => {
    // debugger;
    list.push({ "id": index, "booking_status": e.target.value });
    setInputList(list);
  };
  
  const handleAddClick = () => {
    const update_booking_id = axios.post( API_URL + '/group_bookings/update_status_group_booking/', list)
    window.location.reload();
  };

  const redirectFun =(id)=>{
    
    window.location.href = DASHBOARD_URL + "/groupBookingDetails?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      debugger;
      setLoading(true)
      const booking_details = await axios.get( API_URL + '/group_bookings/getFlightBookingDetails/')
      set_booking_details_list(booking_details.data)
      console.log("booking_details.data")
      console.log(booking_details.data)
      setLoading(false)
    };

    fetchData();
  }, [updateResponse]);


  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive" style={{"max-height":"490px"}}>
                    <table class="table align-items-center table-flush groupbooking_table">
                      <thead class="thead-light">
                        <tr>
                          {/*<th scope="col">
                          <input
                          type="checkbox"

                          /></th>*/}
                          <th scope="col">Group Booking ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Departure</th>
                          <th scope="col">From City</th>
                          <th scope="col">To City</th>
                          <th scope="col">Fare Type</th>
                          <th scope="col">Trip Type</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          booking_details_list?.map((obj, indx)=>{
                            return(
                          <tr>
                            <td scope="col">{obj.ght_group_booking_id}</td>
                            <td scope="col">{obj.cust_Name}</td>
                            <td scope="col">
                              {
                                obj.flight_details.length >= 1 ? obj.flight_details[0].flight_dept:"" 
                              }
                            </td>
                            <td scope="col">
                              {
                                obj.flight_details.length >= 1 ? obj.flight_details[0].from_city:""
                              }
                            </td>
                            <td scope="col">
                              {
                                obj.flight_details.length >= 1 ? obj.flight_details[0].to_city:""
                              }
                            </td>
                            <td scope="col">{obj.trip_type}</td>
                            <td scope="col">{obj.trip_type}</td>
                            <td scope="col">
                              <select className="btn btn-sm bk_status" id="bk_status" onChange={e => handleInputChange(e, obj.id)}>
                                  <option value="COMPLETE" selected={obj.booking_status == "COMPLETE" ? 'selected':''}>COMPLETE</option>
                                  <option value="PENDING" selected={obj.booking_status == "PENDING" ? 'selected':''}>PENDING</option>
                                  <option value="CANCELLED" selected={obj.booking_status == "CANCELLED" ? 'selected':''}>CANCELLED</option>
                                  <option value="INPROGRESS" selected={obj.booking_status == "INPROGRESS" ? 'selected':''}>INPROGRESS</option>
                              </select>
                            </td>
                            <td scope="col"> 
                              <button class="btn btn-primary" style={{cursor:"pointer"}} onClick = {
                                    (e)=>{
                                  e.preventDefault()
                                    redirectFun(obj.id)
                                  }

                                  } variant="primary"> View Details 
                              </button> 
                            </td>
                          </tr>
                            )
                          }
                          )
                        }
                      </tbody>
                    </table>

                    {
                      inputList.map((x, i) => {
                          return(
                            <div>
                                 {inputList.length - 1 === i && <button 
                              className="btn btn-danger" 
                              id="fixedbutton" 
                              style={{
                                "position":"fixed",
                                "bottom":"0px",
                                "right":"0px",
                                "cursor":"pointer"
                                 }}
                              onClick={handleAddClick}
                                 >
                              Save Changes
                              </button>}
                            </div>
                            )
                        })
                    }
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );    
}
}

export default GroupBookingScreen;
