export default class ErrorCls{
    constructor(errCode) {
        this.errCode = errCode;  
      }
    
      getErrorMsg = ()=>{
            if(this.errCode == 404) return "Please fill all the fields";
      }

      getLoginErrorMsg =()=>{
        if(this.errCode == 401) return "Invalid credentials";
        if(this.errCode == 400) return "Unauthorized";
      }


}

