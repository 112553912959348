import axios from "axios";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { API_URL } from "../const";
import { useHistory } from "react-router-dom";
import ErrorCls from "../error";
import { Alert } from "react-bootstrap";

function Login() {
  debugger
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorCode, setErrorCode] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  const loginApiFun = async (requestData, urlData) => {
    
    console.log(requestData);
    var URL_DATA = API_URL + urlData;
    try {
      const { data: response } = await axios.post(URL_DATA, {
        username: requestData.username,
        password: requestData.password,
      });
      //   console.log(response.access)
      localStorage.setItem("response", JSON.stringify(response));
      window.location.href = "/";
    } catch (error) {
      console.log(error.response.status);
      var errObj = new ErrorCls(error.response.status);
      var err_msg = errObj.getLoginErrorMsg();
      setErrorMsg(err_msg);
      setErrorCode(true);
    }
  };

  const submitLogin = (e) => {
    console.log(username);
    console.log(password);

    if (username == "" || password == "") {
      setErrorMsg("Fields cannot be empty");
      setErrorCode(true);
      return;
    }
    e.preventDefault();
    var reqData = { username: username, password: password };
    console.log("here");
    loginApiFun(reqData, "/dashboard/user/login/");
  };

  return (
    <>
      <div>
        <body class="bg-default">
          <div class="main-content">
            <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
              <div class="container"></div>
              <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                  x="0"
                  y="0"
                  viewBox="0 0 2560 100"
                  preserveAspectRatio="none"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon
                    class="fill-default"
                    points="2560 0 2560 100 0 100"
                  ></polygon>
                </svg>
              </div>
            </div>
            <div class="container mt--8 pb-5">
              <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <div class="text-center text-muted mb-4">
                        <small>
                          <a
                            class="navbar-brand brandCls"
                            href="javascript:void(0)"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/Gohoptriplogo.png"
                              }
                              class="navbar-brand-img"
                              alt="..."
                            />
                          </a>
                        </small>
                      </div>
                      <form role="form">
                        <div class="form-group mb-3">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="ni ni-email-83"></i>
                              </span>
                            </div>
                            <input
                              class="form-control"
                              placeholder="username"
                              value={username}
                              onChange={(e) => {
                                setUsername(e.target.value);
                              }}
                              type="email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="ni ni-lock-circle-open"></i>
                              </span>
                            </div>
                            <input
                              class="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              type="password"
                            />
                          </div>
                        </div>

                        <div class="text-center">
                          <button
                            type="button"
                            class="btn btn-primary my-4"
                            onClick={(e) => {
                              submitLogin(e);
                            }}
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                      <br />
                      <div
                        className={
                          errorCode ? "text-center text-muted mb-4" : "hide"
                        }
                      >
                        <h3 className="invalidCls">{errorMsg}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="py-5" id="footer-main"></footer>
        </body>
      </div>
    </>
  );
}

export default Login;
