import React, { useState, useEffect } from "react";
import { Container, Row, ToggleButton, Modal, Badge } from "react-bootstrap";
import { GROUPBOOKING_LIST } from "../dummyDb";
import axios from "axios";
import { MDBDataTable, MDBInput } from 'mdbreact';
import { objectOf } from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import Swal from 'sweetalert2'
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";


var memberOrNot = []
function PackageTravellerDetailsScreen() {
  const [modalShow, setModalShow] = useState(false);
  const [digitalWallet, setdigitalWallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [dataoftable, setdataoftable] = useState(null);
  var dataTable;
  const redirectFun = (id) => {

    window.location.href = DASHBOARD_URL + "/groupBookingDetails?" + id
  }
  
  const handleShow = () => {
    setModalShow(true)
  }

  const handleClose = () => setModalShow(false);

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
    const fetchData = async () => {
      setLoading(true)
      const showAllCx = await axios.get(API_URL + '/accounts/showAllCx/')
      setdigitalWallet(showAllCx.data)
      // const rowData = showAllCx.data      //old code
      const rowData = [{
        "package_name": "Chokho Rajasthan",
        "package_id": "GHTPCK241662738187348",
        "contact_no": "8433547505",
        "email_id": "xyz@gmail.com",
        "date_of_travel": "14-10-2022",
        "city": "Jaipur",
        "number_of_nights_and_days": "6 Nights - 7 days",
        "traveller_details": "hello",
        "booking_id": "GHTPCK241662738187348",
        "booking_date": "",
        "booking_time": "",
        "transaction_id": "GHTPCK241662738187348",
        "transaction_date": "",
        "transaction_time": "",
        "comment": "",
      }];
      var pushrow = [];
      rowData?.map((obj, indx) => {
        pushrow.push({
          package_name: obj.package_name,
          package_id: obj.package_id,
          contact_no: obj.contact_no,
          email_id: obj.email_id,
          date_of_travel: obj.date_of_travel,
          city: obj.city,
          number_of_nights_and_days: obj.number_of_nights_and_days,
          traveller_details: <button onClick={handleShow} class="btn btn-primary" style={{ cursor: "pointer", width: "100px", height: "25px", fontSize: "10px" }} variant="primary"> View Details</button>,
          booking_id: obj.booking_id,
          booking_date: <input type="date" name="date_picker" />,
          // booking_time: <input type="time" name="time_picker"/>,
          transaction_id: obj.transaction_id,
          transaction_date: <input type="date" name="date_picker" />,
          // transaction_time : <input type="time" name="time_picker"/>,
          comment: <textarea name="comment" placeholder="comment" rows="3" col="80" style={{ width: "20rem" }}></textarea>,
        });
      });
      dataTable = {
        columns: [
          {
            label: "Package Name",
            field: "package_name",
            width: 150,
          },
          {
            label: "Package Id",
            field: "package_id",
            width: 150,
          },
          {
            label: "Contact Number",
            field: "contact_no",
            width: 150,
          },
          {
            label: "Email Address",
            field: "email_id",
            width: 150,
          },
          {
            label: "Date of Travel",
            field: "date_of_travel",
            width: 150,
          },
          {
            label: "City",
            field: "city",
            width: 150,
          },
          {
            label: "Number of Nights and Days",
            field: "number_of_nights_and_days",
            width: 150,
          },
          {
            label: "Traveller Details",
            field: "traveller_details",
            width: 150,
          },

          {
            label: "Booking id",
            field: "booking_id",
            width: 150,
          },
          {
            label: "Booking Date",
            field: "booking_date",
            width: 150,
          },
          // {
          //     label: "Booking Time",
          //     field: "booking_time",
          //     width: 150,
          // },
          {
            label: "Transaction id",
            field: "transaction_id",
            width: 150,
          },
          {
            label: "Transaction Date",
            field: "transaction_date",
            width: 150,
          },
          // {
          //     label: "Transaction Time",
          //     field: "transaction_time",
          //     width: 150,
          // },
          {
            label: "Comment",
            field: "comment",
            width: 400,
          },
        ],
        rows: pushrow,
      }
      setdataoftable(dataTable)
      setLoading(false)
    };
    fetchData();
  }, [updateResponse]);


  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <h2 className="mt-3 my-3">
              Package Traveller Details
            </h2>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive vault_table">
                    <MDBDataTable Striped small bordered data={dataoftable} noBottomColumns={true} sortable={false} />
                  </div>
                </div>
              </div>
            </div>
            <Button variant="primary" class="Save" onClick={UpdateMembers} style={{ "left": "90%" }}>Save</Button>
          </div>
        </div>
        <Modal show={modalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Traveller Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            View Traveller Details
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={close} appearance="primary">
              Confirm
            </Button>
            <Button onClick={close} appearance="subtle">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const handleClick = event => {
  var userId = event.currentTarget.id;
  var willbeMember = event.target.checked
  if (event.target.checked) {
    memberOrNot.push({
      "userId": userId,
      "member_status": willbeMember,
    })
  }
  else {
    memberOrNot.push({
      userId: willbeMember
    })
  }
  localStorage.setItem("UpdateMember", JSON.stringify(memberOrNot))
}

function UpdateMembers() {
  var update_member_req = JSON.parse(localStorage.getItem("UpdateMember"))
  const updateCx = axios.post(API_URL + '/accounts/updateMember/', update_member_req)
  Swal.fire({
    // position: 'top-end',
    icon: 'success',
    title: 'Your work has been saved',
    showConfirmButton: false,
    timer: 1300
  })
}

export default PackageTravellerDetailsScreen;
