import React, { useContext, createContext, useState, useEffect } from "react";
import $ from "jquery";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  ButtonGroup,
  ToggleButton,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../const";
import swal from "sweetalert";
import ErrorCls from "../error";
import { UserContext } from "../Screens/PackageScreen";
import Accordion from 'react-bootstrap/Accordion';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';

function PackageCreateModal(props) {
  const [flag, setflag] = useState(false);
  const [HotelTypes, setHotelTypes] = useState([{ hotel_type: "" }]);
  const [SupplierData, setSupplierData] = useState([{ supplier_name: "" }]);
  const [HomeImg, setHomeImg] = useState("");
  const [BannerImg, setBannerImg] = useState("");
  var {
    createValue,
    updateValue,
    dayValue,
    checkedInclusionValue, //03-11-22 last change
    highlightValue,
    inclusionValue,
    exclusionValue,
    thingValue,
    tipValue,
    hoteldetailValue,
    gst_chargeValue,
    typeValue,
    idValue,
    banner_imgValue,
    home_imgValue,
  } = useContext(UserContext);

  //calling useEffect on tipValue change
  useEffect(() => {
    settype(typeValue);
    setPkid(idValue);

    if (updateValue == true) {
      var createBtn = document.getElementById("create");
      var updateBtn = document.getElementById("update");
      createBtn.classList.add("hide");
      updateBtn.classList.remove("hide");
      setupdate(true);
      setcreate(false);
    }

    if (createValue == true) {
      var createBtn = document.getElementById("create");
      var updateBtn = document.getElementById("update");
      createBtn.classList.remove("hide");
      updateBtn.classList.add("hide");
      $("#pk_homeimg").addClass('hide')
      $("#pk_bannerimg").addClass('hide')
      setcreate(true);
      setupdate(false);
    }

    if (dayValue.length > 0) {
      setdayList(dayValue);
    }
    if (checkedInclusionValue.length > 0) {
      setcheckedInclusionList(checkedInclusionValue);
    }
    if (highlightValue.length > 0) {
      sethighlightList(highlightValue);
    }
    if (inclusionValue.length > 0) {
      setinclusionList(inclusionValue);
    }
    if (exclusionValue.length > 0) {
      setexclusionList(exclusionValue);
    }
    if (thingValue.length > 0) {
      setthingList(thingValue);
    }
    if (tipValue.length > 0) {
      settipList(tipValue);
    }
    if (hoteldetailValue.length > 0) {
      sethoteldetailList(hoteldetailValue);
    }
    if (gst_chargeValue.length > 0) {
      setgst_chargeList(gst_chargeValue);
    }
    // console.log("home_imgValue")
    // console.log(home_imgValue)
    // console.log("banner_imgValue")
    // console.log(banner_imgValue)
    setHomeImg(home_imgValue)
    setBannerImg(banner_imgValue)
  }, [tipValue, home_imgValue]);

//calling useEffect once on render
  useEffect(() => {
    const getHotelTypes = async () => {
      const hotel_type_data = await axios.get(
        API_URL + "/packages/DisplayAllHotelTypeDetails/"
      );
      await setHotelTypes(hotel_type_data.data);
    };
    const getSupplierData = async () => {
      const supplier_data = await axios.get(
        API_URL + "/packages/DisplayAllSupplierDetails/"
      );
      await setSupplierData(supplier_data.data);
    };
    getHotelTypes();
    getSupplierData();
  }, []);

  const [priceTotal, setpriceTotal] = useState(0);

  const checkedinclusionlist = {
    meals: false,
    sightseeing: false,
    hotels: false,
  };
  const [create, setcreate] = useState(true);
  const [update, setupdate] = useState(false);
  const [Pkid, setPkid] = useState("");
  const [type, settype] = useState([
    {
      type: "Domestic",
      subcategory1: "Mountains",
      subcategory2: "Indian",
    },
  ]);
  //maintaining state for pk create and edit request format----------------------------
  const [dayList, setdayList] = useState([
    {
      day_no: "",
      day_title: "",
      day_content: "",
      day_details: "",
      day_img: "",
    },
  ]);
  const [highlightList, sethighlightList] = useState([{ list_item: "" }]);
  const [inclusionList, setinclusionList] = useState([{ list_item: "" }]);
  const [exclusionList, setexclusionList] = useState([{ list_item: "" }]);
  const [thingList, setthingList] = useState([{ list_item: "" }]);
  const [tipList, settipList] = useState([{ list_item: "" }]);
  const [hoteldetailList, sethoteldetailList] = useState([
    {
      hotel_type: HotelTypes.length > 0 ? HotelTypes[0].hotel_type : "",
      hotel_place: "",
      hotel_name: "",
      hotel_link: "",
      Base_Cost: "",
      supplier_package: SupplierData.length > 0 ? SupplierData[0].supplier_name : "",
      for_day_no: "all",
    },
  ]);
  const [gst_chargeList, setgst_chargeList] = useState([
    {
      bank_remittance: "",
      trav_insurance: "",
      visa: "",
      supplier_total: "",
      miscellaneous: "",
      gross_total: "",
      tour_leader_cost: "",
      tour_leader_salary: "",
      mountain_bike_charges: "",
      acquisition: "",
      give_aways: "",
      staff_incentive: "",
      ght_travel_insurance: "",
      travel_cost: "",
      travel_type: "",
      frn_commision: "",
      frn_service_tax: "",
      total_cost: "",
      ght_markup_percent: "",
      ght_markup_figure: "",
      total_selling_price: "",
      display_price: true,
      currency: "",
    },
  ]);
  const [checkedInclusionList, setcheckedInclusionList] = useState([
    checkedinclusionlist,
  ]);
  //maintaining state for pk create and edit request format end----------------------------


  //calling create and update API
  const createApi = async (obj) => {
    const package_details = await axios.post(
      API_URL + "/packages/Create_and_Update_Holiday_packages_Details/",
      obj
    );
    if (package_details.data == "Created Successfully") {
      swal("Created Successfully")
      setTimeout(() => {window.location.reload()}, 1000)
    } else if (package_details.data == "Updated Successfully") {
      swal("Updated Successfully")
      setTimeout(() => {window.location.reload()}, 1000)
    } else {
      swal("Something went wrong, please try again");
    }
  };
  //calling create and update API end

  //maintaining state for pk create and edit request format end-------------------

  const handleInputChange = async (e, index) => {
    // debugger
    const { name, value } = e.target;
    const list = [...dayList];
    list[index][name] = value;
    setdayList(list);
    // if(name == "day_img"){
    //   console.log("e.target.files[0]")
    //   console.log(e.target.files[0])
    //   // dayList[index].day_img = e.target.files[0]
    //   getBase64(e.target.files[0])
    //   setTimeout(() => {dayList[index].day_img = imgBase64}, 1000)
    // }
    dayList[index].day_no = index + 1;
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...dayList];
    list.splice(index, 1);
    setdayList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setdayList([
      ...dayList,
      {
        day_no: "",
        day_title: "",
        day_content: "",
        day_details: "",
        day_img: "",
      },
    ]);
  };
  //for days end---------------------------------------------------

  //for highlights---------------------------------------------------
  const highlightChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...highlightList];
    list[index][name] = value;
    sethighlightList(list);
  };

  // handle click event of the Remove button
  const highlightRemoveClick = (index) => {
    const list = [...highlightList];
    list.splice(index, 1);
    sethighlightList(list);
  };

  // handle click event of the Add button
  const highlightAddClick = () => {
    sethighlightList([...highlightList, { list_item: "" }]); //handle_Highlights
  };

  //for highlights end---------------------------------------------------
  //for inclusions---------------------------------------------------
  const inclusionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inclusionList];
    list[index][name] = value;
    setinclusionList(list);
  };

  // handle click event of the Remove button
  const inclusionRemoveClick = (index) => {
    const list = [...inclusionList];
    list.splice(index, 1);
    setinclusionList(list);
  };

  // handle click event of the Add button
  const inclusionAddClick = () => {
    setinclusionList([...inclusionList, { list_item: "" }]); //handle_inclusions
  };

  //for inclusions end---------------------------------------------------
  //for exclusions---------------------------------------------------
  const exclusionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...exclusionList];
    list[index][name] = value;
    setexclusionList(list);
  };

  // handle click event of the Remove button
  const exclusionRemoveClick = (index) => {
    const list = [...exclusionList];
    list.splice(index, 1);
    setexclusionList(list);
  };

  // handle click event of the Add button
  const exclusionAddClick = () => {
    setexclusionList([...exclusionList, { list_item: "" }]); //handle_exclusions
  };

  //for exclusions end---------------------------------------------------
  //for things---------------------------------------------------
  const thingChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...thingList];
    list[index][name] = value;
    setthingList(list);
  };

  // handle click event of the Remove button
  const thingRemoveClick = (index) => {
    const list = [...thingList];
    list.splice(index, 1);
    setthingList(list);
  };

  // handle click event of the Add button
  const thingAddClick = () => {
    setthingList([...thingList, { list_item: "" }]); //handle_Things to do
  };

  //for things end---------------------------------------------------
  //for tips---------------------------------------------------
  const tipChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...tipList];
    list[index][name] = value;
    settipList(list);
  };

  // handle click event of the Remove button
  const tipRemoveClick = (index) => {
    const list = [...tipList];
    list.splice(index, 1);
    settipList(list);
  };

  // handle click event of the Add button
  const tipAddClick = () => {
    settipList([...tipList, { list_item: "" }]); //handle_Tips
  };

  //for tips end---------------------------------------------------
  //for hoteldetails---------------------------------------------------
  const hoteldetailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...hoteldetailList];
    list[index][name] = value;
    sethoteldetailList(list);
  };

  // handle click event of the Remove button
  const hoteldetailRemoveClick = (index) => {
    const list = [...hoteldetailList];
    list.splice(index, 1);
    sethoteldetailList(list);
  };

  // handle click event of the Add button
  const hoteldetailAddClick = () => {
    sethoteldetailList([
      ...hoteldetailList,
      {
        hotel_type: HotelTypes.length > 0 ? HotelTypes[0].hotel_type : "",
        hotel_place: "",
        hotel_name: "",
        hotel_link: "",
        Base_Cost: "",
        supplier_package: SupplierData.length > 0 ? SupplierData[0].supplier_name : "",
        for_day_no: "all",
      },
    ]);
  };

  //for hoteldetails end---------------------------------------------------
  //for gst_charges---------------------------------------------------
  const gst_chargeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...gst_chargeList];
    list[index][name] = value;
    setgst_chargeList(list);
    var basePrice = 0;
    var taxPrice = 0;
    var othertaxPrice = 0;
    basePrice = parseFloat(document.getElementById("Base_Cost").value);
    taxPrice = parseFloat(document.getElementById("tax").value);
    othertaxPrice = parseFloat(document.getElementById("other_charges").value);
    if (basePrice == "" || isNaN(basePrice)) {
      basePrice = 0;
    }
    if (taxPrice == "" || isNaN(taxPrice)) {
      taxPrice = 0;
    }
    if (othertaxPrice == "" || isNaN(othertaxPrice)) {
      othertaxPrice = 0;
    }
    var pricetotal = basePrice + taxPrice + othertaxPrice;
    setpriceTotal(pricetotal);
    gst_chargeList[0].total = pricetotal;
    document.getElementById("total").value = pricetotal;
  };

  //for gst_charges end---------------------------------------------------
  //for checkedInclusions---------------------------------------------------
  const checkedInclusionChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...checkedInclusionList];
    list[index][name] = checked;
    setcheckedInclusionList(list);
  };

  //for checkedInclusions end---------------------------------------------------

  // handle input change end

  return (
    <div
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="packageModal"
    >
    {/*<span>{console.log("HomeImg")}</span>*/}
    {/*<span>{console.log(HomeImg)}</span>*/}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Package
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Accordion defaultActiveKey="0">
            {/* Accordion Item 0 of New Accordion 0 */}
            <Accordion.Item eventKey="0">
            <Accordion.Header><label className="form-label main_heading">Package Details</label></Accordion.Header>
            <AccordionBody>
              <Form.Group>
                <Form.Label>Package Id</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder="Package ID"
                  name="pk_id"
                  id="pk_id"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  className="test"
                  type="text"
                  name="pk_name"
                  id="pk_name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Heading</Form.Label>
                <Form.Control type="text" name="pk_heading" id="pk_heading" />
              </Form.Group>
              <Form.Group>
                <Form.Label> Subheading</Form.Label>
                <Form.Control type="text" name="pk_subheading" id="pk_subheading" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Type</Form.Label>
                {type.length > 0 ? 
                  <Form.Control as="select" name="pk_type" id="pk_type">
                    <option
                      value="Domestic"
                      selected={type[0].type == "Domestic" ? "selected" : ""}
                    >
                      Domestic
                    </option>{" "}
                    &&
                    <option
                      value="International"
                      selected={type[0].type == "International" ? "selected" : ""}
                    >
                      International
                    </option>
                  </Form.Control>
                 : 
                  <Form.Control as="select" name="pk_type" id="pk_type">
                    <option value="Domestic" selected="selected">
                      Domestic
                    </option>{" "}
                    &&
                    <option value="International">International</option>
                  </Form.Control>
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Category 1</Form.Label>
                <Form.Control
                  as="select"
                  id="pk_subcategory1"
                  name="pk_subcategory1"
                >
                  <option value="Mountains">Mountains</option>
                  <option value="Beach Holiday">Beach Holiday</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Category 2</Form.Label>
                <Form.Control
                  as="select"
                  name="pk_subcategory2"
                  id="pk_subcategory2"
                >
                  <option value="Indian">Indian</option>
                  <option value="International">International</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Home Image</Form.Label>
                <Form.Control
                  type="file"
                  name="pk_home_img"
                  id="pk_home_img"
                  onChange={async (e) => {
                    await setHomeImg(e.target.files[0]);
                  }}
                />
                <img id="pk_homeimg" class="package_imgs hide" src="" />
                <p style={{"color":"red", "font-size":"12px"}}>Please Upload Image of Size - 600 X 412</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Banner Image</Form.Label>
                <Form.Control 
                  type="file" 
                  name="pk_banner_img" 
                  id="pk_banner_img" 
                  onChange={async (e) => {
                      await setBannerImg(e.target.files[0]);
                    }}
                />
                <img id="pk_bannerimg" class="package_imgs hide" src="" />
                <p style={{"color":"red", "font-size":"12px"}}>Please Upload Image of Size - 960 X 561</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>No. of Nights</Form.Label>
                <Form.Control type="number" name="pk_nights" id="pk_nights" />
              </Form.Group>
              <Form.Group>
                <Form.Label>No. of Days</Form.Label>
                <Form.Control type="number" name="pk_days" id="pk_days" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Start Date</Form.Label>
                <Form.Control
                  type="date"
                  rows={3}
                  name="pk_stdate"
                  id="pk_stdate"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package End Date</Form.Label>
                <Form.Control
                  type="date"
                  rows={3}
                  name="pk_enddate"
                  id="pk_enddate"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Validity</Form.Label>
                <Form.Control
                  type="date"
                  rows={3}
                  name="pk_validity_date"
                  id="pk_validity_date"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Visiting Country</Form.Label>
                <Form.Control
                  type="text"
                  rows={3}
                  name="pk_visiting_country"
                  id="pk_visiting_country"
                />
              </Form.Group>
              {/*gross total*/}
              <Form.Group>
                <Form.Label>Package Published Min Cost</Form.Label>
                <Form.Control
                  type="number"
                  name="pk_pub_min_cost"
                  id="pk_pub_min_cost"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Summary</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="pk_summary"
                  id="pk_summary"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Order No</Form.Label>
                <Form.Control type="number" name="pk_order" id="pk_order" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Package Rating</Form.Label>
                <Form.Control as="select" name="pk_rating" id="pk_rating">
                  <option value="1 star" class="1star">
                    1 star
                  </option>
                  <option value="2 star" class="2star">
                    2 star
                  </option>
                  <option value="3 star" class="3star">
                    3 star
                  </option>
                  <option value="4 star" class="4star">
                    4 star
                  </option>
                  <option value="5 star" class="5star">
                    5 star
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                {checkedInclusionList.map((x, i) => {
                  return (
                    <div>
                      <Form.Group>
                        <Form.Label>Select Inclusions</Form.Label>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            style={{ opacity: "1", "z-index": "1" }}
                            // defaultChecked={x.meals ? true : false}
                            checked={x.meals}
                            name="meals"
                            id="meals"
                            onChange={(e) => checkedInclusionChange(e, i)}
                          />
                          <label class="" for="meals">
                            Meals
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            style={{ opacity: "1", "z-index": "1" }}
                            // defaultChecked={x.sightseeing ? true : false}
                            checked={x.sightseeing}
                            name="sightseeing"
                            id="sightseeing"
                            onChange={(e) => checkedInclusionChange(e, i)}
                          />
                          <label class="" for="sightseeing">
                            Sightseeing
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            style={{ opacity: "1", "z-index": "1" }}
                            // defaultChecked={x.hotels ? true : false}
                            checked={x.hotels}
                            name="hotels"
                            id="hotels"
                            onChange={(e) => checkedInclusionChange(e, i)}
                          />
                          <label class="" for="hotels">
                            Hotels
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  );
                })}

              </Form.Group>
            </AccordionBody>
            </Accordion.Item>
            {/* Accordion Item 1 of New Accordion 0 */}
            <Form.Group>
              <Accordion.Item eventKey="1">
              <Accordion.Header><label className="form-label main_heading">Add Itinerary</label></Accordion.Header>
              <AccordionBody>
                <div className="Mainbox" id="DayBox">
                  {dayList.map((x, i) => {
                    return (
                      <div className="box">
                        {/* Sub Accordion 0 of Accordion 0 */}
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <AccordionHeader><Form.Label>Day {i + 1}</Form.Label></AccordionHeader>
                            <AccordionBody>
                              <div className="form-group">
                                <Form.Label>Day {i + 1}</Form.Label>
                                <input
                                  type="hidden"
                                  className="form-control"
                                  name="day_no"
                                  id={"day_no" + i}
                                  value={i + 1}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="form-group">
                                <Form.Label>Day Title</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="day_title"
                                  id={"day_title" + i}
                                  value={x.day_title}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="form-group">
                                <Form.Label>Day Content</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="day_content"
                                  id={"day_content" + i}
                                  value={x.day_content}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="form-group">
                                <Form.Label>Day Details</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="day_details"
                                  id={"day_details" + i}
                                  value={x.day_details}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="form-group">
                                <Form.Label>Day Image</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="day_img"
                                  id={"day_img" + i}
                                  value={x.day_img}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="">
                                <AccordionHeader>
                                  {dayList.length !== 1 && (
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      -
                                    </button>
                                  )}
                                  {dayList.length - 1 === i && (
                                    <span
                                      className="btn btn-primary btn-sm"
                                      id={"dayAdd" + i}
                                      onClick={handleAddClick}
                                    >
                                      +
                                    </span>
                                  )}
                                </AccordionHeader>
                              </div>
                            </AccordionBody>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  })}
                </div>
              </AccordionBody>
              </Accordion.Item>
            </Form.Group>
            {/*added last*/}
          </Accordion>
          <br/>
          <Form.Group>
            <Form.Label for="" className="main_heading">Tour Details</Form.Label>
          </Form.Group>
          <div className="Mainbox">
            <Form.Group>
              <Form.Label for="">Introduction</Form.Label>
              <Form.Control
                type="text"
                name="introduction"
                id="introduction"
              />
            </Form.Group>
            {/*sections*/}
              {/* New Accordion 0 for highlights with four sub Accordion item */}
              <Accordion defaultActiveKey="0">
                {/* Accordion item 0 of Highlights accordion 0 */}
                <Accordion.Item eventKey="0">
                  <AccordionHeader>
                    <Form.Group>
                      <Form.Label for="">Highlights</Form.Label>
                    </Form.Group>
                  </AccordionHeader>
                  <AccordionBody>
                    <div id="HlBox">
                      {highlightList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            {/* New separate Sub Accordion 0 of Tour details for Highlights*/}
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader>
                                  <Form.Label for="">Highlight {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Control
                                    type="text"
                                    name="list_item"
                                    value={x.list_item}
                                    onChange={(e) => highlightChange(e, i)}
                                  />

                                  <div className="">
                                    <AccordionHeader>
                                      {highlightList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => highlightRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {highlightList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={highlightAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>

                                </AccordionBody>
                              </Accordion.Item>
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>

                {/* Accordion item 1 of Inclusion accordion 0 */}
                <Accordion.Item eventKey="1">
                  <AccordionHeader>
                    <Form.Group>
                      <Form.Label for="">Inclusions</Form.Label>
                    </Form.Group>
                  </AccordionHeader>
                  <AccordionBody>
                    <div id="IncBox">
                      {inclusionList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            {/* New separate Sub Accordion 0 of Tour details for Inclusion*/}
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader>
                                  <Form.Label for="">Inclusion {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Control
                                    type="text"
                                    name="list_item"
                                    id=""
                                    value={x.list_item}
                                    onChange={(e) => inclusionChange(e, i)}
                                  />

                                  <div className="">
                                    <AccordionHeader>
                                      {inclusionList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => inclusionRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {inclusionList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={inclusionAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>

                                </AccordionBody>
                              </Accordion.Item>
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>

                {/* Accordion item 2 of Exclusion accordion 0 */}
                <Accordion.Item eventKey="2">  
                  <AccordionHeader>
                    <Form.Group>
                      <Form.Label for="">Exclusions</Form.Label>
                    </Form.Group>
                  </AccordionHeader>
                  <AccordionBody>
                    <div id="ExcBox">
                      {exclusionList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            {/* New separate Sub Accordion 0 of Tour details for Exclusions*/}
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader> 
                                  <Form.Label for="">Exclusion {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Control
                                    type="text"
                                    name="list_item"
                                    id=""
                                    value={x.list_item}
                                    onChange={(e) => exclusionChange(e, i)}
                                  />

                                  <div className="">
                                    <AccordionHeader>
                                      {exclusionList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => exclusionRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {exclusionList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={exclusionAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>
                                </AccordionBody>
                              </Accordion.Item> 
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>  

                {/* Accordion item 3 of Thing to do accordion 0 */}
                <Accordion.Item eventKey="3">  
                  <AccordionHeader>
                    <Form.Group>
                      <Form.Label for="">Things To Do</Form.Label>
                    </Form.Group>
                  </AccordionHeader>
                  <AccordionBody>
                    <div id="ThingBox">
                      {thingList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            {/* New separate Sub Accordion 0 of Tour details for Thing to Do*/}
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader> 
                                  <Form.Label for="">Things To Do {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Control
                                    type="text"
                                    name="list_item"
                                    id=""
                                    value={x.list_item}
                                    onChange={(e) => thingChange(e, i)}
                                  />

                                  <div className="">
                                    <AccordionHeader>
                                      {thingList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => thingRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {thingList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={thingAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>
                                </AccordionBody>
                              </Accordion.Item> 
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>

                {/* Accordion item 4 of Tips for traveller accordion 0 */}
                <Accordion.Item eventKey="4">  
                  <AccordionHeader>
                    <Form.Group>
                      <Form.Label for="">Tips for travellers</Form.Label>
                    </Form.Group>
                  </AccordionHeader>
                  <AccordionBody>
                    <div id="TipBox">
                      {tipList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            {/* New separate Sub Accordion 0 of Tour details for Tips for travellers*/}
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader> 
                                  <Form.Label for="">Tip {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Control
                                    type="text"
                                    name="list_item"
                                    id=""
                                    value={x.list_item}
                                    onChange={(e) => tipChange(e, i)}
                                  />

                                  <div className="">
                                    <AccordionHeader>
                                      {tipList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => tipRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {tipList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={tipAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>
                                </AccordionBody>
                              </Accordion.Item> 
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>
              </Accordion>
            {/*sections end*/}
            <br />
            <Form.Group>
              <Form.Label for="">Other Comments</Form.Label>
              <Form.Control
                type="text"
                name="other_comments"
                id="other_comments"
              />
            </Form.Group>
          </div>

          
            <Form.Group>
              <Accordion defaultActiveKey="1">
              {/*Hotels*/}
                {/* Accordion Item 0 of New Accordion 1 */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Form.Label for="" className="main_heading">Hotel Details</Form.Label>
                  </Accordion.Header>
                  <AccordionBody>
                    <div className="Mainbox" id="HotelBox">
                      {hoteldetailList.map((x, i) => {
                        return (
                          <Form.Group className="box">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <AccordionHeader>
                                  <Form.Label for="">Hotel {i + 1}</Form.Label>
                                </AccordionHeader>
                                <AccordionBody>
                                  <Form.Label>Hotel Type</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="hotel_type"
                                    id="hotel_type"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  >
                                    <option
                                      value=""
                                      selected={x.hotel_type == "" ? "selected" : ""}
                                    >
                                      Select
                                    </option>
                                    {HotelTypes.map((y, j) => {
                                      return (
                                        <option
                                          selected={
                                            x.hotel_type == y.hotel_type ? "selected" : ""
                                          }
                                        >
                                          {y.hotel_type}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                  <Form.Label>Hotel Place</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="hotel_place"
                                    id="hotel_place"
                                    value={x.hotel_place}
                                    // placeholder = "Hotel Place"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  />
                                  <Form.Label>Hotel Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="hotel_name"
                                    id="hotel_name"
                                    value={x.hotel_name}
                                    // placeholder = "Hotel Name"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  />
                                  <Form.Label>Hotel Link</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="hotel_link"
                                    id="hotel_link"
                                    value={x.hotel_link}
                                    // placeholder = "Hotel Link"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  />
                                  <Form.Label>Base Cost</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="Base_Cost"
                                    id="Base_Cost"
                                    value={x.Base_Cost}
                                    // placeholder = "Base Cost"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  />
                                  <Form.Label>Supplier Package</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="supplier_package"
                                    id="supplier_package"
                                    value={parseInt(x.supplier_package)}
                                    // placeholder = "Supplier Name"
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  >
                                    <option
                                      value=""
                                      selected={x.supplier_package == "" ? "selected" : ""}
                                    >
                                      Select
                                    </option>
                                    {SupplierData.map((y, j) => {
                                      return (
                                        <option
                                          selected={
                                            x.supplier_package == y.id ? "selected" : ""
                                          }
                                          value={y.id}
                                        >
                                          {y.supplier_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                  <Form.Label>For Day</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="for_day_no"
                                    id="for_day_no"
                                    value={parseInt(x.for_day_no)}
                                    onChange={(e) => hoteldetailChange(e, i)}
                                  >
                                    <option
                                      value="all"
                                      selected={x.for_day_no == "all" ? "selected" : ""}
                                    >
                                      All
                                    </option>
                                    {dayList.map((day, i) => {
                                      return(
                                        day.day_no != "" ? <option value={day.day_no}>{"Day " + day.day_no}</option> : ""
                                        )
                                    })}
                                  </Form.Control>
                                  <div className="">
                                    <AccordionHeader>
                                      {hoteldetailList.length !== 1 && (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => hoteldetailRemoveClick(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {hoteldetailList.length - 1 === i && (
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={hoteldetailAddClick}
                                        >
                                          +
                                        </button>
                                      )}
                                    </AccordionHeader>
                                  </div>
                                </AccordionBody>
                              </Accordion.Item>
                            </Accordion>
                          </Form.Group>
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion.Item>
              {/*Hotels end*/}

            {/*Package Costs*/}
              {/* Accordion Item 1 of New Accordion 1 */}
              <Accordion.Item eventKey="1">
                <AccordionHeader>
                  <Form.Group>
                    <Form.Label for="" className="main_heading">Package Costs</Form.Label>
                  </Form.Group>
                </AccordionHeader>
                <AccordionBody>
                  <div className="Mainbox">
                    {gst_chargeList.map((x, i) => {
                      return (
                        <Form.Group className="box">
                          <Form.Label for="">Tax Price</Form.Label>
                          <br></br>
                          <br></br>
                          <Form.Label for="">Bank Remittance Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="bank_remittance"
                            id="bank_remittance"
                            value={x.bank_remittance}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Traveller Insurance Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="trav_insurance"
                            id="trav_insurance"
                            value={x.trav_insurance}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Visa Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="visa"
                            id="visa"
                            value={x.visa}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Supplier Total Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="supplier_total"
                            id="supplier_total"
                            value={x.supplier_total}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Miscellaneous Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="miscellaneous"
                            id="miscellaneous"
                            value={x.miscellaneous}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Gross Total</Form.Label>
                          <Form.Control
                            type="number"
                            name="gross_total"
                            id="gross_total"
                            value={x.gross_total}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Total Leader Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="tour_leader_cost"
                            id="tour_leader_cost"
                            value={x.tour_leader_cost}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Total Leader Salary</Form.Label>
                          <Form.Control
                            type="number"
                            name="tour_leader_salary"
                            id="tour_leader_salary"
                            value={x.tour_leader_salary}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Mountain Bike Charges</Form.Label>
                          <Form.Control
                            type="number"
                            name="mountain_bike_charges"
                            id="mountain_bike_charges"
                            value={x.mountain_bike_charges}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Acquisition Charges</Form.Label>
                          <Form.Control
                            type="number"
                            name="acquisition"
                            id="acquisition"
                            value={x.acquisition}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Give Aways</Form.Label>
                          <Form.Control
                            type="number"
                            name="give_aways"
                            id="give_aways"
                            value={x.give_aways}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Staff Incentive</Form.Label>
                          <Form.Control
                            type="number"
                            name="staff_incentive"
                            id="staff_incentive"
                            value={x.staff_incentive}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">GHT Travel Insurance</Form.Label>
                          <Form.Control
                            type="number"
                            name="ght_travel_insurance"
                            id="ght_travel_insurance"
                            value={x.ght_travel_insurance}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Travel Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="travel_cost"
                            id="travel_cost"
                            value={x.travel_cost}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Travel Type</Form.Label>
                          <Form.Control
                            type="text"
                            name="travel_type"
                            id="travel_type"
                            value={x.travel_type}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Franchisee Commission @ 8%</Form.Label>
                          <Form.Control
                            type="number"
                            name="frn_commision"
                            id="frn_commision"
                            value={x.frn_commision}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">
                            Service Tax On FRN Commission @ 18% on Franchisee
                            Commission
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="frn_service_tax"
                            id="frn_service_tax"
                            value={x.frn_service_tax}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Total Cost</Form.Label>
                          <Form.Control
                            type="number"
                            name="total_cost"
                            id="total_cost"
                            value={x.total_cost}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">GHT Mark Up in %</Form.Label>
                          <Form.Control
                            type="number"
                            name="ght_markup_percent"
                            id="ght_markup_percent"
                            value={x.ght_markup_percent}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">GHT Mark Up in Figures</Form.Label>
                          <Form.Control
                            type="number"
                            name="ght_markup_figure"
                            id="ght_markup_figure"
                            value={x.ght_markup_figure}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Total Selling Price</Form.Label>
                          <Form.Control
                            type="number"
                            name="total_selling_price"
                            id="total_selling_price"
                            value={x.total_selling_price}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                          <Form.Label for="">Currency</Form.Label>
                          <Form.Control
                            type="text"
                            name="currency"
                            id="currency"
                            value={x.currency}
                            onChange={(e) => gst_chargeChange(e, i)}
                          />
                        </Form.Group>
                      );
                    })}
                  </div>
                </AccordionBody>
              </Accordion.Item>
            {/*Package Costs end*/}
            </Accordion>  
            <br />
            <Form.Label className="main_heading">Package Status</Form.Label>
            <Form.Control as="select" name="pk_status" id="pk_status">
              <option value="Inactive">Inactive</option>
              <option value="Active">Active</option>
            </Form.Control>

            <Form.Check
              className="custom-control custom-checkbox"
              style={{ opacity: "1", "z-index": "1" }}
              type="checkbox"
              name="is_new"
              id="is_new"
              label="Is New"
            >
            </Form.Check>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="" onClick={(e) => setData(e)} id="create">
          Create
        </Button>
        <Button className="hide" onClick={(e) => setData(e)} id="update">
          Update
        </Button>
        {/*<Button onClick={props.onHide}>Close</Button>*/}
      </Modal.Footer>
    </div>
  );

  function setData(e) {
    var highlights_str = "";
    var inclusions_str = "";
    var exclusions_str = "";
    var things_str = "";
    var tip_str = "";
    // code for removing extra /n and for adding /n after each value in Database.
    // Highlights
    var hl = highlightList.map((obj, i) => {
      if (i + 1 == highlightList.length) {
        highlights_str += obj.list_item;
      } else {
        highlights_str += obj.list_item + "/n/";
      }
    });

    // Inclusions
    var inc = inclusionList.map((obj, i) => {
      if (i + 1 == inclusionList.length) {
        inclusions_str += obj.list_item;
      } else {
        inclusions_str += obj.list_item + "/n/";
      }
    });

    // Exclusions
    var inc = exclusionList.map((obj, i) => {
      if (i + 1 == exclusionList.length) {
        exclusions_str += obj.list_item;
      } else {
        exclusions_str += obj.list_item + "/n/";
      }
    });

    // Things to do
    var things = thingList.map((obj, i) => {
      if (i + 1 == thingList.length) {
        things_str += obj.list_item;
      } else {
        things_str += obj.list_item + "/n/";
      }
    });

    // Tips
    var tip = tipList.map((obj, i) => {
      if (i + 1 == tipList.length) {
        tip_str += obj.list_item;
      } else {
        tip_str += obj.list_item + "/n/";
      }
    });

    var obj = {};

    //creating req format for create -------------------------------
    if (create == true) {

      // proper validation alignment of keys with reference to React fronthend form
      obj = {
        pk_name: document.getElementById("pk_name").value,
        pk_heading: document.getElementById("pk_heading").value,
        pk_subheading: document.getElementById("pk_subheading").value,
        pk_type: document.getElementById("pk_type").value,
        pk_category: "Holiday Package",     //not mentioned in validation
        pk_subcategory1: document.getElementById("pk_subcategory1").value,
        pk_subcategory2: document.getElementById("pk_subcategory2").value,
        pk_home_img: HomeImg,
        pk_banner_img: BannerImg,
        pk_inclusions: checkedInclusionList[0],     //not mentioned in validation
        pk_summary: document.getElementById("pk_summary").value,
        pk_nights: document.getElementById("pk_nights").value,
        pk_days: document.getElementById("pk_days").value,
        pk_status: document.getElementById("pk_status").value,
        pk_stdate: document.getElementById("pk_stdate").value,
        pk_enddate: document.getElementById("pk_enddate").value,
        currency: document.getElementById("currency").value,
        pk_validity_date: document.getElementById("pk_validity_date").value,
        pk_visiting_country: document.getElementById("pk_visiting_country").value,
        //Package Published Min Cost database mai store nhi ho raha h iska key bhi nhi h react mai is page mai.
        pk_ght_star_ranking: document.getElementById("pk_rating").value,
        pk_itinerary: dayList,
        introduction: document.getElementById("introduction").value,
        highlights: highlights_str,
        tr_inclusion: inclusions_str,
        tr_exclusion: exclusions_str,
        things_to_do: things_str,
        tips: tip_str,
        other_comments: document.getElementById("other_comments").value,
        pk_hotel_details: hoteldetailList,
        ght_sub_total: [                         //not mentioned in validation
          {
            "hotel_type": "Standard",
            "total_price": "130000",
            "display_price": false
          },
          {
            "hotel_type": "Deluxess",
            "total_price": "130000",
            "display_price": true
          }
        ],
        bank_remittance: gst_chargeList[0].bank_remittance,
        trav_insurance: gst_chargeList[0].trav_insurance,
        visa: gst_chargeList[0].visa,
        supplier_total: gst_chargeList[0].supplier_total,
        miscellaneous: gst_chargeList[0].miscellaneous,
        gross_total: gst_chargeList[0].gross_total,
        tour_leader_cost: gst_chargeList[0].tour_leader_cost,
        tour_leader_salary: gst_chargeList[0].tour_leader_salary,
        mountain_bike_charges: gst_chargeList[0].mountain_bike_charges,
        acquisition: gst_chargeList[0].acquisition,
        give_aways: gst_chargeList[0].give_aways,
        staff_incentive: gst_chargeList[0].staff_incentive,
        ght_travel_insurance: gst_chargeList[0].ght_travel_insurance,
        travel_cost: gst_chargeList[0].travel_cost,
        travel_type: gst_chargeList[0].travel_type,
        frn_commision: gst_chargeList[0].frn_commision,
        frn_service_tax: gst_chargeList[0].frn_service_tax,
        total_cost: gst_chargeList[0].total_cost,
        ght_markup_percent: gst_chargeList[0].ght_markup_percent,
        ght_markup_figure: gst_chargeList[0].ght_markup_figure,
        total_selling_price: gst_chargeList[0].total_selling_price,

        display_price: false,   //not mentioned in validation, i have to add checkbox for it.
        pk_isnew: $('#is_new').is(':checked'),    //not mentioned in validation Package is new or not  
        pk_order: document.getElementById("pk_order").value,
        pk_enq_id: document.getElementById("pk_id").value,    //not mentioned in validation, need to save in Django DB backend
        pk_rating: document.getElementById("pk_rating").value,
      };
      console.log("object")
      console.log(obj)
      var flag = 1;
      
      [obj].map(async (object, i) => {
        if (
          object.pk_name == "" ||
          object.pk_heading == "" ||
          object.pk_subheading == "" ||
          object.pk_type == "" ||
          object.pk_subcategory1 == "" ||
          object.pk_subcategory2 == "" ||
          object.pk_home_img == "" ||
          object.pk_banner_img == "" ||
          object.pk_nights == "" ||
          object.pk_days == "" ||
          object.pk_stdate == "" ||
          object.pk_enddate == "" ||
          object.pk_validity_date == "" ||
          object.pk_visiting_country == "" ||
          object.pk_summary == "" ||
          object.pk_order == "" ||
          object.pk_rating == "" ||
          object.pk_ght_star_ranking == "" ||
          object.introduction == "" ||
          object.other_comments == "" ||
          object.currency == ""
        ) {
          debugger
          swal("All fields are required");
          flag = 0;
          return;
        } else {
          dayList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                console.log("el[property")
                console.log(el[property])
                swal("Please Fill all day details "+el[property]);
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });
          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          highlightList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required2");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });
          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          inclusionList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required3");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });
          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          exclusionList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required4");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });

          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          thingList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required5");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });

          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          tipList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required6");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });

          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          hoteldetailList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required7");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });

          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
          gst_chargeList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("required8");
                flag = 0;
                return;
              }
            });
            if (flag == 0) {
              return;
            }
          });
          if (flag == 0) {
            return;
          } else {
            flag = 1;
          }
        }

        if (flag == 1) {
          // Form Data request ----------------------------
            var formdata = new FormData()
            formdata.append("pk_name", document.getElementById("pk_name").value)
            formdata.append("pk_heading", document.getElementById("pk_heading").value)
            formdata.append("pk_subheading", document.getElementById("pk_subheading").value)
            formdata.append("pk_type", document.getElementById("pk_type").value)
            formdata.append("pk_category", "Holiday Package")
            formdata.append("pk_subcategory1", document.getElementById("pk_subcategory1").value)
            formdata.append("pk_subcategory2", document.getElementById("pk_subcategory2").value)
            formdata.append("pk_home_img", HomeImg)
            formdata.append("pk_banner_img", BannerImg)
            formdata.append("pk_inclusions", JSON.stringify(checkedInclusionList[0]))
            formdata.append("pk_summary", document.getElementById("pk_summary").value)
            formdata.append("pk_nights", document.getElementById("pk_nights").value)
            formdata.append("pk_days", document.getElementById("pk_days").value)
            formdata.append("pk_status", document.getElementById("pk_status").value)
            formdata.append("pk_stdate", document.getElementById("pk_stdate").value)
            formdata.append("pk_enddate", document.getElementById("pk_enddate").value)
            formdata.append("currency", document.getElementById("currency").value)
            formdata.append("pk_validity_date", document.getElementById("pk_validity_date").value)
            formdata.append("pk_visiting_country", document.getElementById("pk_visiting_country").value)
            formdata.append("pk_ght_star_ranking", document.getElementById("pk_rating").value)
            formdata.append("pk_itinerary", JSON.stringify(dayList))
            formdata.append("introduction", document.getElementById("introduction").value)
            formdata.append("highlights", highlights_str)
            formdata.append("tr_inclusion", inclusions_str)
            formdata.append("tr_exclusion", exclusions_str)
            formdata.append("things_to_do", things_str)
            formdata.append("tips", tip_str)
            formdata.append("other_comments", document.getElementById("other_comments").value)
            formdata.append("pk_hotel_details", JSON.stringify(hoteldetailList))
            formdata.append("ght_sub_total",JSON.stringify([
                                {
                                    "hotel_type":"Standarddd",
                                    "total_price":"130000",
                                    "display_price":false
                                },
                                {
                                    "hotel_type":"Deluxeee",
                                    "total_price":"130000",
                                    "display_price":true
                                }
                            ]))
            formdata.append("bank_remittance", gst_chargeList[0].bank_remittance)
            formdata.append("trav_insurance", gst_chargeList[0].trav_insurance)
            formdata.append("visa", gst_chargeList[0].visa)
            formdata.append("supplier_total", gst_chargeList[0].supplier_total)
            formdata.append("miscellaneous", gst_chargeList[0].miscellaneous)
            formdata.append("gross_total", gst_chargeList[0].gross_total)
            formdata.append("tour_leader_cost", gst_chargeList[0].tour_leader_cost)
            formdata.append("tour_leader_salary", gst_chargeList[0].tour_leader_salary)
            formdata.append("mountain_bike_charges", gst_chargeList[0].mountain_bike_charges)
            formdata.append("acquisition", gst_chargeList[0].acquisition)
            formdata.append("give_aways", gst_chargeList[0].give_aways)
            formdata.append("staff_incentive",gst_chargeList[0].staff_incentive)
            formdata.append("ght_travel_insurance", gst_chargeList[0].ght_travel_insurance)
            formdata.append("travel_cost", gst_chargeList[0].travel_cost)
            formdata.append("travel_type", gst_chargeList[0].travel_type)
            formdata.append("frn_commision", gst_chargeList[0].frn_commision)
            formdata.append("frn_service_tax", gst_chargeList[0].frn_service_tax)
            formdata.append("total_cost", gst_chargeList[0].total_cost)
            formdata.append("ght_markup_percent", gst_chargeList[0].ght_markup_percent)
            formdata.append("ght_markup_figure", gst_chargeList[0].ght_markup_figure)
            formdata.append("total_selling_price", gst_chargeList[0].total_selling_price)
            formdata.append("pk_isnew", true)
            formdata.append("pk_order", document.getElementById("pk_order").value)
            formdata.append("pk_enq_id", document.getElementById("pk_id").value)
            formdata.append("pk_rating", document.getElementById("pk_rating").value)

            // createApi(formdata)
          // form Data request end----------------------
          createApi(formdata);
        }
      });
      
    }

    //creating req format for update in formData--------------------------------
    // console.log("HomeImg before update")
    // console.log(HomeImg)
    if (update == true) {
      obj = {
        id: Pkid,
        pk_name: document.getElementById("pk_name").value,
        pk_heading: document.getElementById("pk_heading").value,
        pk_subheading: document.getElementById("pk_subheading").value,
        pk_type: document.getElementById("pk_type").value,
        pk_category: "Holiday Package",
        pk_subcategory1: document.getElementById("pk_subcategory1").value,
        pk_subcategory2: document.getElementById("pk_subcategory2").value,
        pk_home_img: HomeImg,
        pk_banner_img: BannerImg,
        pk_inclusions: checkedInclusionList[0],
        pk_summary: document.getElementById("pk_summary").value,
        pk_nights: document.getElementById("pk_nights").value,
        pk_days: document.getElementById("pk_days").value,
        pk_status: document.getElementById("pk_status").value,
        pk_stdate: document.getElementById("pk_stdate").value,
        pk_enddate: document.getElementById("pk_enddate").value,
        currency: document.getElementById("currency").value,
        pk_validity_date: document.getElementById("pk_validity_date").value,
        pk_visiting_country: document.getElementById("pk_visiting_country")
          .value,
        pk_ght_star_ranking: document.getElementById("pk_rating").value,
        pk_itinerary: dayList,
        introduction: document.getElementById("introduction").value,
        highlights: highlights_str,
        tr_inclusion: inclusions_str,
        tr_exclusion: exclusions_str,
        things_to_do: things_str,
        tips: tip_str,
        other_comments: document.getElementById("other_comments").value,
        pk_hotel_details: hoteldetailList,
        ght_sub_total: [
          {
            hotel_type: "Standarddd",
            total_price: "130000",
            display_price: false,
          },
          {
            hotel_type: "Deluxeee",
            total_price: "130000",
            display_price: true,
          },
        ],
        bank_remittance: gst_chargeList[0].bank_remittance,
        trav_insurance: gst_chargeList[0].trav_insurance,
        visa: gst_chargeList[0].visa,
        supplier_total: gst_chargeList[0].supplier_total,
        miscellaneous: gst_chargeList[0].miscellaneous,
        gross_total: gst_chargeList[0].gross_total,
        tour_leader_cost: gst_chargeList[0].tour_leader_cost,
        tour_leader_salary: gst_chargeList[0].tour_leader_salary,
        mountain_bike_charges: gst_chargeList[0].mountain_bike_charges,
        acquisition: gst_chargeList[0].acquisition,
        give_aways: gst_chargeList[0].give_aways,
        staff_incentive: gst_chargeList[0].staff_incentive,
        ght_travel_insurance: gst_chargeList[0].ght_travel_insurance,
        travel_cost: gst_chargeList[0].travel_cost,
        travel_type: gst_chargeList[0].travel_type,
        frn_commision: gst_chargeList[0].frn_commision,
        frn_service_tax: gst_chargeList[0].frn_service_tax,
        total_cost: gst_chargeList[0].total_cost,
        ght_markup_percent: gst_chargeList[0].ght_markup_percent,
        ght_markup_figure: gst_chargeList[0].ght_markup_figure,
        total_selling_price: gst_chargeList[0].total_selling_price,
        display_price: false,
        pk_isnew: $("#is_new").is(":checked"),
        pk_order: document.getElementById("pk_order").value,
        pk_enq_id: document.getElementById("pk_id").value,
        pk_rating: document.getElementById("pk_rating").value,
      };

      //Validation working
      // proper validation alignment of keys with reference to React fronthend form
      var flag2 = 1;
      [obj].map(async (object, i) => {
        // object.pk_name == "" ? swal("Package Name is required") :
        // debugger
        if (
          object.pk_name == "" ||
          object.pk_heading == "" ||
          object.pk_subheading == "" ||
          object.pk_type == "" ||
          object.pk_subcategory1 == "" ||
          object.pk_subcategory2 == "" ||
          object.pk_home_img == "" ||
          object.pk_banner_img == "" ||
          object.pk_nights == "" ||
          object.pk_days == "" ||
          object.pk_stdate == "" ||
          object.pk_enddate == "" ||
          object.pk_validity_date == "" ||
          object.pk_visiting_country == "" ||
          object.pk_summary == "" ||
          object.pk_order == "" ||
          object.pk_rating == "" ||
          object.pk_ght_star_ranking == "" ||
          object.introduction == "" ||
          object.other_comments == "" ||
          object.currency == ""
        ) {
          swal("All fields are required");
          flag2 = 0;
          return;
        } else {
          dayList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill all Day Details");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });
          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          highlightList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Highlights");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });
          if (flag2 == 0) {
            return swal("out from [obj].map]");
          } else {
            flag2 = 1;
          }

          inclusionList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Inclusions");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });
          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          exclusionList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Exclusions");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });

          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          thingList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Things to do");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });

          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          tipList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Tips for travellers");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });

          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          hoteldetailList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Hotel Details");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });

          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }

          gst_chargeList.forEach(function (el) {
            Object.keys(el).forEach(function (property) {
              if (el[property] === "") {
                swal("Please fill Package Costs");
                flag2 = 0;
                return;
              }
            });
            if (flag2 == 0) {
              return;
            }
          });
          if (flag2 == 0) {
            return;
          } else {
            flag2 = 1;
          }
        }

        if (flag2 == 1) {
          var formdata = new FormData()
          formdata.append("id", Pkid)
          formdata.append("pk_name", document.getElementById("pk_name").value)
          formdata.append("pk_heading", document.getElementById("pk_heading").value)
          formdata.append("pk_subheading", document.getElementById("pk_subheading").value)
          formdata.append("pk_type", document.getElementById("pk_type").value)
          formdata.append("pk_category", "Holiday Package")
          formdata.append("pk_subcategory1", document.getElementById("pk_subcategory1").value)
          formdata.append("pk_subcategory2", document.getElementById("pk_subcategory2").value)
          formdata.append("pk_home_img", HomeImg)
          formdata.append("pk_banner_img", BannerImg)
          formdata.append("pk_inclusions", JSON.stringify(checkedInclusionList[0]))
          formdata.append("pk_summary", document.getElementById("pk_summary").value)
          formdata.append("pk_nights", document.getElementById("pk_nights").value)
          formdata.append("pk_days", document.getElementById("pk_days").value)
          formdata.append("pk_status", document.getElementById("pk_status").value)
          formdata.append("pk_stdate", document.getElementById("pk_stdate").value)
          formdata.append("pk_enddate", document.getElementById("pk_enddate").value)
          formdata.append("currency", document.getElementById("currency").value)
          formdata.append("pk_validity_date", document.getElementById("pk_validity_date").value)
          formdata.append("pk_visiting_country", document.getElementById("pk_visiting_country").value)
          formdata.append("pk_ght_star_ranking", document.getElementById("pk_rating").value)
          formdata.append("pk_itinerary", JSON.stringify(dayList))
          formdata.append("introduction", document.getElementById("introduction").value)
          formdata.append("highlights", highlights_str)
          formdata.append("tr_inclusion", inclusions_str)
          formdata.append("tr_exclusion", exclusions_str)
          formdata.append("things_to_do", things_str)
          formdata.append("tips", tip_str)
          formdata.append("other_comments", document.getElementById("other_comments").value)
          formdata.append("pk_hotel_details", JSON.stringify(hoteldetailList))
          formdata.append("ght_sub_total",JSON.stringify([
                              {
                                  "hotel_type":"Standarddd",
                                  "total_price":"130000",
                                  "display_price":false
                              },
                              {
                                  "hotel_type":"Deluxeee",
                                  "total_price":"130000",
                                  "display_price":true
                              }
                          ]))
          formdata.append("bank_remittance", gst_chargeList[0].bank_remittance)
          formdata.append("trav_insurance", gst_chargeList[0].trav_insurance)
          formdata.append("visa", gst_chargeList[0].visa)
          formdata.append("supplier_total", gst_chargeList[0].supplier_total)
          formdata.append("miscellaneous", gst_chargeList[0].miscellaneous)
          formdata.append("gross_total", gst_chargeList[0].gross_total)
          formdata.append("tour_leader_cost", gst_chargeList[0].tour_leader_cost)
          formdata.append("tour_leader_salary", gst_chargeList[0].tour_leader_salary)
          formdata.append("mountain_bike_charges", gst_chargeList[0].mountain_bike_charges)
          formdata.append("acquisition", gst_chargeList[0].acquisition)
          formdata.append("give_aways", gst_chargeList[0].give_aways)
          formdata.append("staff_incentive",gst_chargeList[0].staff_incentive)
          formdata.append("ght_travel_insurance", gst_chargeList[0].ght_travel_insurance)
          formdata.append("travel_cost", gst_chargeList[0].travel_cost)
          formdata.append("travel_type", gst_chargeList[0].travel_type)
          formdata.append("frn_commision", gst_chargeList[0].frn_commision)
          formdata.append("frn_service_tax", gst_chargeList[0].frn_service_tax)
          formdata.append("total_cost", gst_chargeList[0].total_cost)
          formdata.append("ght_markup_percent", gst_chargeList[0].ght_markup_percent)
          formdata.append("ght_markup_figure", gst_chargeList[0].ght_markup_figure)
          formdata.append("total_selling_price", gst_chargeList[0].total_selling_price)
          formdata.append("pk_isnew", true)
          formdata.append("pk_order", document.getElementById("pk_order").value)
          formdata.append("pk_enq_id", document.getElementById("pk_id").value)
          formdata.append("pk_rating", document.getElementById("pk_rating").value)
        // form Data request end----------------------
        createApi(formdata);
        }
      });
    }
  }
}

export default PackageCreateModal;
