import React, { useState, useEffect } from "react";
import axios from "axios";
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";
import Moment from "moment";
import {CSVLink, CSVDownload} from 'react-csv';


var dataTable;
function TransactionHistory(){
// const [digitalWallet, setdigitalWallet] = useState(null);
const [loading, setLoading] = useState(true);
const [updateResponse, setUpdateResponse] = useState(false);
const [dataoftable, setdataoftable] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("response") === null) {
          window.location.href = "/login";
        }
    
        const fetchData = async () => {
          setLoading(true)
          const vaultTransactions = await axios.get( API_URL + '/points_system/vaultTransactions/')
          const vaultCustomers = await axios.get( API_URL + '/points_system/showCustomers/')
          const allCustomers = await axios.get( API_URL + '/accounts/showAllCx/')
          vaultTransactions.data.map((obj,i) => {
            var filtered1 = vaultCustomers.data.find(x => x.id == obj.gdvt_account_id)
            console.log("filtered1")
            console.log(filtered1)
            var filtered2 = allCustomers.data.find(x => x.id == filtered1.customer_id);
              obj.phone_number = filtered2.phone_number
          })
          const rowData = vaultTransactions.data
          var pushrow = [];
          rowData?.map((obj, indx) => {
            var transaction_date = Moment(obj.transaction_date).format('MMMM Do YYYY, h:mm:ss a')
            var updated_date = Moment(obj.updated_at).format('MMMM Do YYYY, h:mm:ss a')
              pushrow.push({
                contact: obj.phone_number,
                gdvt_id: obj.gdvt_id,
                gdvt_account_id: obj.gdvt_account_id,
                transaction_type: obj.debit == true ? "Debit" : obj.credit == true ? "Credit" : "",
                transaction_status: obj.transaction_status,
                transaction_date: transaction_date,
                // updated_by: obj.updated_by,
                updated_at: updated_date,
                booking_id: obj.booking_id,
                points: obj.points,
                comment1: obj.comment1,
                comment2: obj.comment2,
                // points_percent:obj.points_percent,
              });
          });
          dataTable =  {
            columns: [
              {
                label: "Customer Contact",
                field: "contact",
                // width: 150,
              },
              {
                label: "Transaction Id",
                field: "gdvt_id",
                // width: 150,
              },
              {
                label: "Transaction Account Id",
                field: "gdvt_account_id",
                // width: 150,
              },
              {
                label: "Transaction Type",
                field: "transaction_type",
                // width: 100,
              },
              {
                label: "Transaction Status",
                field: "transaction_status",
                // width: 200,
              },
              {
                label: "Transaction Date",
                field: "transaction_date",
                // width: 50,
              },
              // {
              //   label: "Last Updated By",
              //   field: "updated_by",
              //   // width: 150,
              // },
              {
                label: "Updated On",
                field: "updated_at",
                // width: 50,
              },
              {
                label: "Product booking Id",
                field: "booking_id",
                // width: 150,
              },
              {
                label: "points",
                field: "points",
                // width: 100,
              },
              {
                label: "Comment 1",
                field: "comment1",
                // width: 250,
              },
              {
                label: "Comment 2",
                field: "comment2",
                // width: 250,
              },
              // {
              //   label: "Member Status",
              //   field: "member_status",
              //   // width: 50,
    
              // },
              // {
              //   label: "Points Percentage",
              //   field: "points_percent",
              //   // width: 150,
              // },
            ],
            rows: pushrow,
          }
          setdataoftable(dataTable)
          setLoading(false)
        };
        fetchData();
      }, [updateResponse]);
    if(loading){
        return (
            <>
              <h1>Loading</h1>
            </>
          );

    }
    else
    {
    return (

        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>
  
            <div className="m-5">
            <h2 className="mt-3 my-3">
            Digital Transactions
            </h2>
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="table-responsive vault_table">
                      <MDBDataTable Striped small bordered autoWidth={true} data={dataoftable} noBottomColumns={true} exportToCSV={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );

}
}
export default TransactionHistory;