export const CUSTOMER_LIST = {
    "membersList":[
        {"f_name":"Aida", "l_name":"Bugg", "img":"assets/img/pasportImg.jpg", "status":true, "mem_plan":"3_MONTHS", "percentage":70, "wallet":"2500"}, 
     
    ], 
    "total_members":150,
    "deadline_members":20,
    "new_members":10,
    "active_members":100,
    "in_active_members":50,
    "status":{"status_type":"success", "status_code":200}
}

export const MARKUP_LIST = {
    "markup_list":[
        {"markup_name":"Default Name", "created_on":"Feb 8, 2022 7:27 PM", "product":"Flight", "travell_type":"ALL", "markup_type":"AMOUNT", "amount_value":12, "pax_Adult":true, "pax_child":true, "pax_infant":true, "markup_status":true},
        {"markup_name":"Bajaj Price", "created_on":"Feb 8, 2022 7:27 PM", "product":"Flight", "travell_type":"ALL", "markup_type":"AMOUNT", "amount_value":12, "pax_Adult":true, "pax_child":false, "pax_infant":false, "markup_status":true},
        {"markup_name":"Cyphware", "created_on":"Feb 8, 2022 7:27 PM", "product":"Flight", "travell_type":"ALL", "markup_type":"AMOUNT", "amount_value":12, "pax_Adult":true, "pax_child":true, "pax_infant":true, "markup_status":false},
        {"markup_name":"Default Name", "created_on":"Feb 8, 2022 7:27 PM", "product":"Flight", "travell_type":"ALL", "markup_type":"AMOUNT", "amount_value":12, "pax_Adult":true, "pax_child":true, "pax_infant":true, "markup_status":true},
    ]
}


export const COMPANY_MAP_LIST = {
    "company_markup":[
        {"company_name":"test Company" ,"markup_name":"Default Name", "set_on":"Feb 8, 2022 7:27 PM"},
        {"company_name":"test Company" ,"markup_name":"Default Name", "set_on":"Feb 8, 2022 7:27 PM"},
        {"company_name":"test Company" ,"markup_name":"Default Name", "set_on":"Feb 8, 2022 7:27 PM"},
        {"company_name":"test Company" ,"markup_name":"Default Name", "set_on":"Feb 8, 2022 7:27 PM"},

    ]
}


export const TRAINER_LIST = {
    "trainerList":[
        {"f_name":"Aida", "l_name":"Bugg", "img":"assets/img/pasportImg.jpg", "active_pt":1, "total_pt":70, "shift":""},
        {"f_name":"Ajay", "l_name":"Dactyl", "img":"assets/img/pasportImg.jpg", "active_pt":2, "total_pt":10, "shift":""},
        {"f_name":"Liz", "l_name":"erd", "img":"assets/img/pasportImg.jpg", "active_pt":3, "total_pt":30, "shift":""},
        {"f_name":"Minnie", "l_name":"ryder", "img":"assets/img/pasportImg.jpg", "active_pt":0, "total_pt":45, "shift":""},
    ]
}

export const GROUPBOOKING_LIST = {
    "groupbookingList":[
        {
            "booking_status": "Pending",
            "id": 1,
            "ght_booking_id": "GHT11000194",
            "flight_details": [
                {
                    "flight_name": "zxczxc",
                    "flight_number": 12,
                    "from_city": "zxcasd",
                    "to_city": "qweasd",
                    "flight_dept": "2022-06-22 12:59:23"
                },
                {
                    "flight_name": "tesfasd",
                    "flight_number": 234,
                    "from_city": "TEST2",
                    "to_city": "TEST3",
                    "flight_dept": "2022-06-23 05:52:13"
                }
            ],
            "cust_Name": "Nikunj",
            "trip_type": "Oneway"
        },
        {
            "booking_status": "Pending",
            "id": 2,
            "ght_booking_id": "GHT11000195",
            "flight_details": [],
            "cust_Name": "qweasdzxc",
            "trip_type": "Oneway"
        }
    ],

    "groupbookingDetails":[
        {
            "ght_booking_id": "GHT11000194",
            "inquiry_Type": "group_booking",
            "cust_Name": "Nikunj",
            "cust_Email": "nikunj.lapasia@cyphware.com",
            "cust_Phone": "9699821398",
            "passenger_Guest_Count": 9,
            "cust_City": "Mumbai",
            "cust_Country": "India",
            "is_International": false,
            "flight_class": "ECO",
            "adult_Count": 7,
            "child_Count": 2,
            "infant_Count": 1,
            "booking_status": "Pending",
            "trip_type": "Oneway",
            "media": "web",
            "flight_details": [
                {
                    "flight_name": "zxczxc",
                    "flight_number": 12,
                    "from_city": "zxcasd",
                    "to_city": "qweasd",
                    "flight_dept": "2022-06-22 12:59:23"
                },
                {
                    "flight_name": "tesfasd",
                    "flight_number": 234,
                    "from_city": "TEST2",
                    "to_city": "TEST3",
                    "flight_dept": "2022-06-23 05:52:13"
                }
            ],
            "remarks":"hello world",
        },
        {
            "ght_booking_id": "GHT11000194",
            "inquiry_Type": "group_booking",
            "cust_Name": "Nikunj",
            "cust_Email": "nikunj.lapasia@cyphware.com",
            "cust_Phone": "9699821398",
            "passenger_Guest_Count": 9,
            "cust_City": "Mumbai",
            "cust_Country": "India",
            "is_International": false,
            "flight_class": "ECO",
            "adult_Count": 7,
            "child_Count": 2,
            "infant_Count": 1,
            "booking_status": "Pending",
            "trip_type": "Oneway",
            "media": "web",
            "flight_details": [
                {
                    "flight_name": "zxczxc",
                    "flight_number": 34545,
                    "from_city": "zxcasd",
                    "to_city": "qweasd",
                    "flight_dept": "2022-06-22 12:59:23"
                },
                {
                    "flight_name": "tesfasd",
                    "flight_number": 2345434,
                    "from_city": "TEST2",
                    "to_city": "TEST3",
                    "flight_dept": "2022-06-23 05:52:13"
                }
            ],
            "remarks":"hello world",
        }
    ]
}

export const packageBookings = {
    "groupbookingList":[
        {
            "id": 0,
            "ght_grp_booking_id": "grpaszxc",
            "BOOKING_STATUS": "PENDING",
            "cust_Name": "Muskan",
            "passenger_Guest_Count": 42,
            "is_International": true,
            "check_availability": "2022-06-28",
            "package_Name": "The Middle Land - Spiti Valley",
        },
        {
            "id": 1,
            "ght_grp_booking_id": "grpaszxc",
            "BOOKING_STATUS": "PENDING",
            "cust_Name": "Muskan 2",
            "passenger_Guest_Count": 42,
            "is_International": false,
            "check_availability": "2022-06-28",
            "package_Name": "The Middle Land - Spiti Valley",
        }
    ],

    "groupbookingDetails":[
        {
            "ght_booking_id":"GHT11000194",
            "cust_Name": "Muskan",
            "cust_Phone": "8433547505",
            "cust_Email": "muskan.patel@cyphware.com",
            "package_Name": "The Middle Land - Spiti Valley",
            "city_Name": "Mumbai",
            "package_Cost": "₹47,999",
        },
        {
            "ght_booking_id":"GHT11000195",
            "cust_Name": "Muskan 2",
            "cust_Phone": "8433547505",
            "cust_Email": "muskan.patel@cyphware.com",
            "package_Name": "The Middle Land - Spiti Valley",
            "city_Name": "Mumbai",
            "package_Cost": "₹48,999",
        }
    ]
}

export const PackageLists = [
    {
        "id":"1",
        "pk_name":"Scenic Package",
        "pk_heading":"Scenic Package",
        "pk_subheading":"2 Nights | 3 Days",
        "pk_type":"Domestic",
        "pk_category":"Holiday package",
        "pk_subcategory1":"Mountains",
        "pk_subcategory2":"Indian",
        "pk_home_img":"https://gohoptrip.com/public/website/assets/images/new_images/pack-ladakh.png",
        "pk_banner_img":"https://gohoptrip.com/public/website/assets/images/new_images/scenic_ladakh_banner.png",
        "pk_inclusions":{

            "meals":true,
            "sightseeing":true,
            "hotels":false,
        },
        "pk_summary":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India. ",
        "pk_nights":"2",
        "pk_days":"3",
        "pk_status":"Active",
        "pk_stdate":"2022-07-22",
        "pk_enddate":"2022-07-22",
        "pk_validity_date":"2022-07-22",
        "pk_visiting_country":"India",
        "pk_ght_star_ranking":"5 Star",
        "pk_itinerary":[{
                "day_no":"1",
                "day_title":"Arrival At Leh",
                "day_content":"Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details":"Enjoy the nightly stay with a sumptuous dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"2",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"3",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"4",
                "day_title":"The Indus Valley Monastery Tour - Leh 4",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
        ],
        "pk_tour_details":[{
            "introduction":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
            "highlights":[
                {
                    "list_item":"highlight1",
                },
                {
                    "list_item":"highlight2",
                },
            ],
            "inclusions":[{
                    "list_item":"inclusion1",
                },
                {
                    "list_item":"inclusion2",
                },
            ],
            "exclusions":[{
                    "list_item":"exclusion1",
                },
                {
                    "list_item":"exclusion2",
                },
            ],
            "things_to_do":[{
                    "list_item":"siteseeing",
                },
                {
                    "list_item":"Meals",
                },
            ],
            "tips":[{
                    "list_item":" ",
                },
                {
                    "list_item":"carrry your bags",
                },
            ],
            "other_comments":"Please note..."
        }],

        "pk_hotel_details":[
            {   
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
            {   
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
        ],

        "gst_charges":{
            "bank_remittance":"1185",
            "trav_insurance":"510",
            "visa":"0",
            "supplier_total":"117315",
            "miscellaneous":"1000",
            "gross_total":"118825",
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "currency":"INR",
        },
        "ght_sub_total":[
            {
                "hotel_type":"Standard",
                "total_price":"130000",
                "display_price":false,
            },
            {
                "hotel_type":"Deluxe",
                "total_price":"130000",
                "display_price":true,
            },
        ],
        "pk_isnew":"true",
        "pk_order":"3",
        "pk_enq_id":"1223",
        "pk_rating":"4"
    },
    {
        "id":"2",
        "pk_name":"Scenic Package2",
        "pk_heading":"Scenic Package2",
        "pk_subheading":"2 Nights | 3 Days",
        "pk_type":"International",
        "pk_category":"Holiday package",
        "pk_subcategory1":"Mountains",
        "pk_subcategory2":"Indian",
        "pk_home_img":"https://gohoptrip.com/public/website/assets/images/new_images/mesc_pk.webp",
        "pk_banner_img":"https://gohoptrip.com/public/website/assets/images/new_images/scenic_ladakh_banner.png",
        "pk_inclusions":{

            "meals":true,
            "sightseeing":true,
            "hotels":false,
        },
        "pk_summary":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India. ",
        "pk_nights":"2",
        "pk_days":"3",
        "pk_status":"Active",
        "pk_stdate":"2022-07-22",
        "pk_enddate":"2022-07-22",
        "currency":"INR",
        "pk_validity_date":"2022-07-22",
        "pk_visiting_country":"India",
        "pk_ght_star_ranking":"5 Star",
        "pk_itinerary":[{
                "day_no":"1",
                "day_title":"Arrival At Leh",
                "day_content":"Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details":"Enjoy the nightly stay with a sumptuous dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"2",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"3",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
        ],
        "pk_tour_details":[{
            "introduction":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
            "highlights":[{
                    "list_item":"highlight1",
                },
                {
                    "list_item":"highlight2",
                },
            ],
            "inclusions":[{
                    "list_item":"inclusion1",
                },
                {
                    "list_item":"inclusion2",
                },
            ],
            "exclusions":[{
                    "list_item":"exclusion1",
                },
                {
                    "list_item":"exclusion2",
                },
            ],
            "things_to_do":[{
                    "list_item":"siteseeing",
                },
                {
                    "list_item":"Meals",
                },
            ],
            "tips":[{
                    "list_item":" ",
                },
                {
                    "list_item":"carrry your bags",
                },
            ],
            "other_comments":"Please note..."
        }],

        "pk_hotel_details":[
            {   
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_company":"Silver pearl",
            },
            {   
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_company":"Silver pearl",
            },
        ],

        "gst_charges":{
            "bank_remittance":"1185",
            "trav_insurance":"510",
            "visa":"0",
            "supplier_total":"117315",
            "miscellaneous":"1000",
            "gross_total":"118825",
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "display_price":false,
            "currency":"INR",
        },
        "pk_isnew":"true",
        "pk_order":"3",
        "pk_enq_id":"1223",
        "pk_rating":"4"
    },
    {
        "id":"3",
        "pk_name":"Scenic Package3",
        "pk_heading":"Scenic Package3",
        "pk_subheading":"2 Nights | 3 Days",
        "pk_type":"Domestic",
        "pk_category":"Holiday package",
        "pk_subcategory1":"Mountains",
        "pk_subcategory2":"Indian",
        "pk_home_img":"https://gohoptrip.com/public/website/assets/images/new_images/mesc_pk.webp",
        "pk_banner_img":"https://gohoptrip.com/public/website/assets/images/new_images/scenic_ladakh_banner.png",
        "pk_inclusions":{

            "meals":true,
            "sightseeing":true,
            "hotels":false,
        },
        "pk_summary":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India. ",
        "pk_nights":"2",
        "pk_days":"3",
        "pk_status":"Active",
        "pk_stdate":"2022-07-22",
        "pk_enddate":"2022-07-22",
        "currency":"INR",
        "pk_validity_date":"2022-07-22",
        "pk_visiting_country":"India",
        "pk_ght_star_ranking":"5 Star",
        "pk_itinerary":[{
                "day_no":"1",
                "day_title":"Arrival At Leh",
                "day_content":"Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details":"Enjoy the nightly stay with a sumptuous dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"2",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"3",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
        ],
        "pk_tour_details":[{
            "introduction":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
            "highlights":[{
                    "list_item":"highlight1",
                },
                {
                    "list_item":"highlight2",
                },
            ],
            "inclusions":[{
                    "list_item":"inclusion1",
                },
                {
                    "list_item":"inclusion2",
                },
            ],
            "exclusions":[{
                    "list_item":"exclusion1",
                },
                {
                    "list_item":"exclusion2",
                },
            ],
            "things_to_do":[{
                    "list_item":"siteseeing",
                },
                {
                    "list_item":"Meals",
                },
            ],
            "tips":[{
                    "list_item":" ",
                },
                {
                    "list_item":"carrry your bags",
                },
            ],
            "other_comments":"Please note..."
        }],

        "pk_hotel_details":[
            {   
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
            {   
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
        ],

        "gst_charges":{
            "bank_remittance":"1185",
            "trav_insurance":"510",
            "visa":"0",
            "supplier_total":"117315",
            "miscellaneous":"1000",
            "gross_total":"118825",
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "display_price":false,
            "currency":"INR",
        },
        "pk_isnew":"true",
        "pk_order":"3",
        "pk_enq_id":"1223",
        "pk_rating":"5"
    },
    {
        "id":"4",
        "pk_name":"Scenic Package4",
        "pk_heading":"Scenic Package4",
        "pk_subheading":"2 Nights | 3 Days",
        "pk_type":"Domestic",
        "pk_category":"Holiday package",
        "pk_subcategory1":"Mountains",
        "pk_subcategory2":"Indian",
        "pk_home_img":"https://gohoptrip.com/public/website/assets/images/new_images/mesc_pk.webp",
        "pk_banner_img":"https://gohoptrip.com/public/website/assets/images/new_images/scenic_ladakh_banner.png",
        "pk_inclusions":{

            "meals":true,
            "sightseeing":true,
            "hotels":false,
        },
        "pk_summary":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India. ",
        "pk_nights":"2",
        "pk_days":"3",
        "pk_status":"Active",
        "pk_stdate":"2022-07-22",
        "pk_enddate":"2022-07-22",
        "currency":"INR",
        "pk_validity_date":"2022-07-22",
        "pk_visiting_country":"India",
        "pk_ght_star_ranking":"5 Star",
        "pk_itinerary":[{
                "day_no":"1",
                "day_title":"Arrival At Leh",
                "day_content":"Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details":"Enjoy the nightly stay with a sumptuous dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"2",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"3",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
        ],
        "pk_tour_details":[{
            "introduction":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
            "highlights":[{
                    "list_item":"highlight1",
                },
                {
                    "list_item":"highlight2",
                },
            ],
            "inclusions":[{
                    "list_item":"inclusion1",
                },
                {
                    "list_item":"inclusion2",
                },
            ],
            "exclusions":[{
                    "list_item":"exclusion1",
                },
                {
                    "list_item":"exclusion2",
                },
            ],
            "things_to_do":[{
                    "list_item":"siteseeing",
                },
                {
                    "list_item":"Meals",
                },
            ],
            "tips":[{
                    "list_item":" ",
                },
                {
                    "list_item":"carrry your bags",
                },
            ],
            "other_comments":"Please note..."
        }],

        "pk_hotel_details":[
            {   
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
            {   
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
        ],

        "gst_charges":{
            "bank_remittance":"1185",
            "trav_insurance":"510",
            "visa":"0",
            "supplier_total":"117315",
            "miscellaneous":"1000",
            "gross_total":"118825",
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "display_price":false,
            "currency":"INR",
        },
        "pk_isnew":"true",
        "pk_order":"3",
        "pk_enq_id":"1223",
        "pk_rating":"4"
    },
    {
        "id":"5",
        "pk_name":"Scenic Package5",
        "pk_heading":"Scenic Package5",
        "pk_subheading":"2 Nights | 3 Days",
        "pk_type":"Domestic",
        "pk_category":"Holiday package",
        "pk_subcategory1":"Mountains",
        "pk_subcategory2":"Indian",
        "pk_home_img":"https://gohoptrip.com/public/website/assets/images/new_images/mesc_pk.webp",
        "pk_banner_img":"https://gohoptrip.com/public/website/assets/images/new_images/scenic_ladakh_banner.png",
        "pk_inclusions":{

            "meals":true,
            "sightseeing":true,
            "hotels":false,
        },
        "pk_summary":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India. ",
        "pk_nights":"2",
        "pk_days":"3",
        "pk_status":"Inactive",
        "pk_stdate":"2022-07-22",
        "pk_enddate":"2022-07-22",
        "currency":"INR",
        "pk_validity_date":"2022-07-22",
        "pk_visiting_country":"India",
        "pk_ght_star_ranking":"5 Star",
        "pk_itinerary":[{
                "day_no":"1",
                "day_title":"Arrival At Leh",
                "day_content":"Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details":"Enjoy the nightly stay with a sumptuous dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
            {
                "day_no":"2",
                "day_title":"The Indus Valley Monastery Tour - Leh",
                "day_content":"The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details":"Enjoy the day and night stay with splendid breakfast and dinner spread",
                "day_img":"https://gohoptrip.com/public/website/assets/images/new_images/leh_4.jpg",
            },
        ],
        "pk_tour_details":[{
            "introduction":"Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
            "highlights":[{
                    "list_item":"highlight1",
                },
                {
                    "list_item":"highlight2",
                },
            ],
            "inclusions":[{
                    "list_item":"inclusion1",
                },
                {
                    "list_item":"inclusion2",
                },
            ],
            "exclusions":[{
                    "list_item":"exclusion1",
                },
                {
                    "list_item":"exclusion2",
                },
            ],
            "things_to_do":[{
                    "list_item":"siteseeing",
                },
                {
                    "list_item":"Meals",
                },
            ],
            "tips":[{
                    "list_item":" ",
                },
                {
                    "list_item":"carrry your bags",
                },
            ],
            "other_comments":"Please note..."
        }],

        "pk_hotel_details":[
            {   
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
            {   
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
            },
        ],

        "gst_charges":{
            "bank_remittance":"1185",
            "trav_insurance":"510",
            "visa":"0",
            "supplier_total":"117315",
            "miscellaneous":"1000",
            "gross_total":"118825",
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "display_price":false,
            "currency":"INR",
        },
        "pk_isnew":"true",
        "pk_order":"3",
        "pk_enq_id":"1223",
        "pk_rating":"4"
    },
]

export const PackageDisplay = [
    {   
        "id":1,
        "name": "Scenic Ladakh valley",
        "heading": "Scenic Ladakh valley",
        "subheading": "2 nights & 3 days",
        "type": "domestic",
        "category": "Holiday package",
        "subcategory1": "mouintains",
        "subcategory2": "Indian",
        "heading_inclusions": {},
        "summary": "Ladakh (meaning 'land of high mountain passes') is a hilly region in North India.",
        "nights": "3",
        "days": "4",
        "status": "Active",
        "startdate": "14-07-2022",
        "enddate": "14-07-2022",
        "validity_date": "30-07-2022",
        "visiting_country": "India",
        "ght_star_ranking": "3 star",
        "pack_itenary": [
            {
                "id": 53,
                "day_no": "8",
                "day_title": "Arrival At Leh",
                "day_content": "Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details": "Enjoy the nightly stay with a sumptuous dinner spread",
                "package_id": 27
            },
            {
                "id": 54,
                "day_no": "9",
                "day_title": "The Indus Valley Monastery Tour - Leh",
                "day_content": "The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details": "Enjoy the day and night stay with splendid breakfast and dinner spread",
                "package_id": 27
            }
        ],
        "introduction": "Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
        "highlights": "highlights1",
        "tr_inclusion": "inclusion1",
        "tr_exclusion": "exclusion1",
        "things_to_do": "sitessing",
        "tips": "carrry your bags",
        "other_comments": "note abc",
        "pack_hotel": [
            {
                "id": 51,
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
                "supplier_base_cost":"116130",
                "bank_remittance":"1185",
                "trav_insurance":"510",
                "visa":"0",
                "supplier_total":"117315",
                "miscellaneous":"1000",
                "gross_total":"118825",
            },
            {
                "id": 52,
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
                "supplier_base_cost":"116130",
                "bank_remittance":"1185",
                "trav_insurance":"510",
                "visa":"0",
                "supplier_total":"117315",
                "miscellaneous":"1000",
                "gross_total":"118825",
            }
        ],
        "isnew": true,
        "order": "3",
        "enq_id": "1267",
        "rating": "4.7",
        "gst_charges":{
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "display_price":false,
            "currency":"INR",
        },
    },
    {   
        "id":2,
        "name": "Scenic Ladakh valley",
        "heading": "Scenic Ladakh valley",
        "subheading": "2 nights & 3 days",
        "type": "domestic",
        "category": "Holiday package",
        "subcategory1": "mouintains",
        "subcategory2": "Indian",
        "heading_inclusions": {},
        "summary": "Ladakh (meaning 'land of high mountain passes') is a hilly region in North India.",
        "nights": "3",
        "days": "4",
        "status": "Active",
        "startdate": "14-07-2022",
        "enddate": "14-07-2022",
        "pub_min_cost": 34000.0,
        "currency": "INR",
        "validity_date": "30-07-2022",
        "visiting_country": "India",
        "ght_star_ranking": "3 star",
        "pack_itenary": [
            {
                "id": 53,
                "day_no": "8",
                "day_title": "Arrival At Leh",
                "day_content": "Upon your arrival at Leh, a ‘meet and greet representative will receive you at the airport",
                "day_details": "Enjoy the nightly stay with a sumptuous dinner spread",
                "package_id": 27
            },
            {
                "id": 54,
                "day_no": "9",
                "day_title": "The Indus Valley Monastery Tour - Leh",
                "day_content": "The Indus Valley Monastery tour takes you to some of the most famous and luminous",
                "day_details": "Enjoy the day and night stay with splendid breakfast and dinner spread",
                "package_id": 27
            }
        ],
        "introduction": "Ladakh (meaning 'land of high mountain passes') is a hilly region in North India",
        "highlights": "highlights1",
        "tr_inclusion": "inclusion1",
        "tr_exclusion": "exclusion1",
        "things_to_do": "sitessing",
        "tips": "carrry your bags",
        "other_comments": "note abc",
        "pack_hotel": [
            {
                "id": 51,
                "hotel_type":"Standard",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
                "supplier_base_cost":"116130",
                "bank_remittance":"1185",
                "trav_insurance":"510",
                "visa":"0",
                "supplier_total":"117315",
                "miscellaneous":"1000",
                "gross_total":"118825",
            },
            {
                "id": 52,
                "hotel_type":"Deluxe",
                "hotel_place":"Ladakh",
                "hotel_name":"Hotel Name",
                "hotel_link":"www.hotelname.com",
                "Base_Cost":"34000",
                "supplier_package":"Silver pearl",
                "supplier_base_cost":"116130",
                "bank_remittance":"1185",
                "trav_insurance":"510",
                "visa":"0",
                "supplier_total":"117315",
                "miscellaneous":"1000",
                "gross_total":"118825",
            }
        ],
        "isnew": true,
        "order": "3",
        "enq_id": "1267",
        "rating": "4.7",
        "gst_charges":{
            "tour_leader_cost":"0",
            "tour_leader_salary":"0",
            "mountain_bike_charges":"0",
            "acquisition":"0",
            "give_aways":"0",
            "staff_incentive":"0",
            "ght_travel_insurance":"0",
            "travel_cost":"0",
            "travel_type":"Air",
            "frn_commision":"2000",
            "frn_service_tax":" 360",
            "total_cost":"121185",
            "ght_markup_percent":"", 
            "ght_markup_figure":"14115", 
            "total_selling_price":"135300", 
            "currency":"INR",
        },
        "ght_sub_total":[
            {
                "hotel_type":"Standard",
                "total_price":"130000",
                "display_price":false,
            },
            {
                "hotel_type":"Deluxe",
                "total_price":"130000",
                "display_price":true,
            },
        ],
    }
]

export const SupplierMaster = [
    {
    "id":1,
    "supplier_name":"abcd",
    "contact_no":"9898877878",
    "email_id":"abc@gmail.com",
    "address":"abcd",
    },
    {
    "id":2,
    "supplier_name":"xyz",
    "contact_no":"9999999999",
    "email_id":"xyz@gmail.com",
    "address":"xyz",
    }
]

export const HotelTypeMaster = [
    {
    "id":1,
    "hotel_type":"deluxe"
    },
    {
    "id":2,
    "hotel_type":"standard"
    },
    {
    "id":3,
    "hotel_type":"luxury"
    }
]
    
export const digital_vault_list = {
    "test":"test"
}