// CorporateRegistrationDetails

import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { packageBookings } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";

import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import $ from 'jquery';

function CorporateRegistrationDetails({ match }) {
  const [registration_details, set_registration_details] = useState(null);
  const [user_phone_number, set_phone_number] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [html, setHtml] = useState(false);
  const test2 = ""
  let url_data = window.location.href;
  url_data = url_data.split("?")[1];

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }


    const fetchData = async () => {
      setLoading(true)
      const registration_details1 = await axios.post( API_URL + '/accounts/DisplayRegistrationDetailsById/', {"id":url_data});
      set_registration_details(registration_details1.data)
      console.log("registration_details1.data")
      console.log(registration_details1.data)
      setLoading(false)

    };
    fetchData();
  }, [updateResponse]);

  

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {

      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>

            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <h2 style={{"margin":"15px"}}>
                     Corporate Registration Details
                    </h2>
                    <div class="container">
                      <div>
                        {/* <p>User Contact Number: {user_phone_number}</p> */}
                        <p><b>ID:</b> {registration_details.id}</p>
                        <p><b>Contact Person Name:</b> {registration_details.cre_fname + " " + registration_details.cre_lname}</p>
                        <p><b>Agency Name:</b> {registration_details.cre_agency_name}</p>
                        <p><b>Business Type:</b>
                          {
                            registration_details.cre_business_type == 'Travel_Aganecy'
                            ? ' Travel Agent'
                            : registration_details.cre_business_type == 'Corporate'
                            ? ' Corporate'
                            : ''
                          }
                        </p>
                        <p><b>Registration Address:</b> {registration_details.cre_registration_address}</p>
                        <p><b>Gst No:</b> {registration_details.cre_gst_number ? registration_details.cre_gst_number : '-'}</p>
                        <p><b>Tan No:</b> {registration_details.cre_tan_number ? registration_details.cre_tan_number : '-'}</p>
                        <p><b>Email Id:</b> {registration_details.cre_emailid}</p>
                        <p><b>Contact No:</b> {registration_details.cre_contact_no}</p>
                        <p><b>Logo:</b> 
                        {registration_details.cre_logo ? 
                          <img src={registration_details.cre_logo.split('?')[0]} style={{'width':'100px'}} className="b2b_logo"/> : 
                          "None"
                        }
                        </p>
                        <p>
                        {registration_details.cre_pan_Card_copy ? 
                          <a href={registration_details.cre_pan_Card_copy.split('?')[0]} target="_blank">Pan Card Copy</a> : 
                          ""
                        }
                        </p>
                        <p>
                        {registration_details.cre_gst_file ? 
                          <a href={registration_details.cre_gst_file.split('?')[0]} target="_blank">Gst File</a> : 
                          ""
                        }
                        </p>
                        <p><b>Active:</b> 
                          {
                            registration_details.isactive == true
                            ? ' Yes'
                            : registration_details.isactive == false
                            ? ' No'
                            : ''
                          }
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
   
  }
}

export default CorporateRegistrationDetails;