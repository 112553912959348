import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE,
  SERVICE_TOGGLE
} from "../const";
import { COMPANY_MAP_LIST, MARKUP_LIST } from "../dummyDb";
import MarkUpCreateModal from "../Components/MarkUpCreateModal";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

function CompanyService() {
    const [markupList, setMarkupList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false);
  
    const [companyName, setCompanyName] = useState("");
    const [markUpName, setMarkUpName] = useState("");
  
    const [companySelectedOptn, setCompanySelectedOptn] = useState("");
    const [markupSelectedOptn, setMarkupSelectedOptn] = useState("");
    const [product, setProduct] = useState("");
    const [travelType, setTravelType] = useState('DOMESTIC');
  
    const [responseApi, setResponseApi] = useState("");
  
    const [updateResponse, setUpdateResponse] = useState(false);
  
    var options = { value: "vanilla", label: "Vanilla" };
  
    useEffect(() => {
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
  
      const fetchData = async () => {
        console.log("DDDDDDDDDDDDDDDDDdd");
        console.log(product);
        console.log(markupSelectedOptn);
  
        if (TYPE == "TEST") {
          setLoading(true);
          var token = JSON.parse(localStorage.getItem("response")).access;
          try {
            const { data: response } = await axios.get(

              API_URL + "/dashboard/get_company_service_list/",
            );

            console.log(response);
            setMarkupList(response);
          } catch (error) {
            // swal(error)
            console.error(error.message);
          }
          setLoading(false);
        } else {
          // console.log("Aaaaaaaaaaaa");
          setMarkupList(COMPANY_MAP_LIST);
          setLoading(false);
          // console.log(markupList);
          // console.log(loading);
        }
      };
  
      fetchData();
    }, [updateResponse]);
  
    const putApiCall = async (urlData, requestData) => {
      var token = JSON.parse(localStorage.getItem("response")).access;
      try {
        const { data: response } = await axios.put(
          API_URL + urlData,
  
          { requestData }
        );
  
        console.log(response.status);
        if (response.status == "success") {
          if (updateResponse) {
            setUpdateResponse(false);
          } else {
            setUpdateResponse(true);
          }
          // setUpdateResponse(response.status)
  
          console.log(updateResponse);
        }
        return response;
      } catch (error) {
        return error;
        // console.error(error.message);
      }
    };
  
    const setUpCompanyMarupFun = () => {
      console.log("set markkkkkkkkkkkk");
      console.log(companySelectedOptn);
      console.log(markupSelectedOptn.selectedOptions.value);
      console.log(product.selectedOptions.value);
      console.log(travelType.selectedOptions.value.id);
  
      if (
        companySelectedOptn == "" &&
        markupSelectedOptn == "" &&
        product == ""&&
        travelType == ""
      ) {
        swal("Fields cannot be empty");
        return;
      }
      if (companySelectedOptn == "") {
        swal("select a company");
        return;
      }
      if (markupSelectedOptn == "") {
        swal("select a markup");
        return;
      }
      if (product == "") {
        swal("select a product");
        return;
      }
  
      // if (
      //   product.selectedOptions.value !=
      //   markupSelectedOptn.selectedOptions.value.product
      // ) {
      //   swal("markup not valid for selected product");
      //   return;
      // }
      console.log()
      var responseData = JSON.stringify({
        company_id: companySelectedOptn.selectedOptions.value.id,
        service_id: markupSelectedOptn.selectedOptions.value.id,
        product_value:product.selectedOptions.label,
        travell_type:travelType.selectedOptions.value.id,
      });
  
      console.log(responseData);
      // companySelectedOptn.selectedOptions.value.markup_name =
      //   markupSelectedOptn.selectedOptions.value.id;
  
      putApiCall("/dashboard/get_company_service_list/", responseData);
  
      console.log("here Im async");
    };
  
    const updateProduct = (value) => {
  
      setProduct(value);
      console.log(product.selectedOptions.value);
    };
  
    if (loading) {
      return (
        <>
          <h1>Loading</h1>
        </>
      );
    } else {
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>
  
            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <CustomToggle
                            toggle_type={COMPANY_TOGGLE}
                            product_type={product}
                            selectedOptn={setCompanySelectedOptn}
                          />
                        </div>
  
                        <div class="col">
                          <CustomToggle
                            toggle_type={PRODUCT_TOGGLE}
                            product_type={product}
                            selectedOptn={updateProduct}
                            modalType={"SERVICE"}
                          />
                        </div>
  
                        <div class="col">
                          <CustomToggle
                            toggle_type={TRAVEL_TYPE}
                            product_type={product}
                            selectedOptn={setTravelType}
                            modalType={"SERVICE"}
                          />
                        </div>
  
                        <div class="col">
                          <CustomToggle
                            toggle_type={SERVICE_TOGGLE}
                            product_type={product}
                            selectedOptn={setMarkupSelectedOptn}
                            modalType={"SERVICE"}
                          />
                        </div>
                        <div class="col text-right">
                          <a
                            href="#!"
                            class="btn btn-sm btn-primary"
                            onClick={setUpCompanyMarupFun}
                          >
                            Set Service
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th scope="col">Company Name</th>
                            <th scope="col">Service Name</th>
                            <th scope="col">Product</th>
                            <th scope="col">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            // console.log()
                            // console.log(markupList.markupComp)
                            markupList?.serviceComp.map((obj, indx)=>{
                              return(
                                <tr>
                              <td scope="col">{obj.company_id}</td>
                              <td scope="col">{obj.markup}</td>
                              <td scope="col">{obj.product}</td>
                              <td scope="col">{obj.product_type}</td>
                            </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
}

export default CompanyService