import React, { useState, useEffect } from "react";
import { Container, ToggleButton, Card } from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { package_list } from "../dummyDb";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

function ContentManagement(){
  const [array_list, setArray] = useState(null);
  const [updateResponse, setUpdateResponse] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
}, [updateResponse]);


return(

  <>
    <nav
      class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
      id="sidenav-main"
    >
      <Navbar />
    </nav>
    <div class="main-content" id="panel">
      <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <TopNavbar />
      </nav>

      <div class="mt-5 ml-5 w-50">
        <Card>
          <a href="/packageManagement"><Card.Body style={{"cursor":"pointer"}}><strong>Packages</strong></Card.Body></a>
        </Card>
        <Card>
          <a href=""><Card.Body style={{"cursor":"pointer"}}><strong>Events</strong></Card.Body></a>
        </Card>
      </div>
    </div>
  </>

  );

}

export default ContentManagement