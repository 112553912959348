import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { GROUPBOOKING_LIST } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";



function GroupBookingDetails({ match }) {

  const [booking_details, set_booking_details] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [booking_type, setBookingType] = useState(false);

  
  let url_data = window.location.href;
  url_data = url_data.split("?")[1];

  const handleAddClick = () => {
    var id = url_data;
    var booking_id = document.getElementById("input_booking_id").value;
    var list = [];
    // list.push({id: url_data, ght_booking_id: booking_id})
    const update_booking_id = axios.post( API_URL + '/group_bookings/update_booking_id/', {"id": url_data, "ght_booking_id": booking_id});
    window.location.reload();
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      setLoading(true)
      const booking_details = await axios.post( API_URL + '/group_bookings/get_flight_detailed_booking/', {'id':url_data});
      // const booking_details = GROUPBOOKING_LIST;
      set_booking_details(booking_details.data);
      console.log("booking_details.data")
      console.log(booking_details.data)
      var booking_type = booking_details.data.trip_type;
      setBookingType(booking_type)
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  




  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
      return (
        <>
          <nav
            class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
            id="sidenav-main"
          >
            <Navbar />
          </nav>
          <div class="main-content" id="panel">
            <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
              <TopNavbar />
            </nav>

            <div className="m-5">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="container">
                    <h2>
                      Group Booking Details
                    </h2>
                      <div>
                        <p><b>{booking_details.cust_Name ? "Name: " : ""}</b>{booking_details.cust_Name ? booking_details.cust_Name:""}</p>
                        <p><b>{booking_details.cust_Email ? "Email: " : ""}</b>{booking_details.cust_Email ? booking_details.cust_Email:""}</p>
                        <p><b>{booking_details.cust_Phone ? "Contact: " : ""}</b>{booking_details.cust_Phone ? booking_details.cust_Phone:""}</p>
                        <p><b>{booking_details.passenger_Guest_Count ? "Passenger: " : ""}</b>{booking_details.passenger_Guest_Count ? booking_details.passenger_Guest_Count:""}</p>
                        <p><b>{booking_details.cust_City ? "Customer City: " : ""}</b>{booking_details.cust_City ? booking_details.cust_City:""}</p>
                        <p><b>{booking_details.cust_Country ? "Customer Country: " : ""}</b>{booking_details.cust_Country ? booking_details.cust_Country:""}</p>
                        <p><b>Is International: </b> {booking_details.is_International?'true':'false'}</p>
                        <p><b>{booking_details.trip_type ? "Trip Type: " : ""}</b>{booking_details.trip_type ? booking_details.trip_type:""}</p>
                        <p><b>{booking_details.fare_type ? "Fare Type: " : ""}</b>{booking_details.fare_type ? booking_details.fare_type:""}</p>
                        <p><b>{booking_details.booking_status ? "Booking Status: " : ""}</b>{booking_details.booking_status ? booking_details.booking_status:""}</p>
                        <p><b>{booking_details.remarks ? "Remarks: " : ""}</b>{booking_details.remarks ? booking_details.remarks:""}</p>
                        <p><b>{booking_details.ght_group_booking_id ? "Group Booking Id: " : ""}</b>{booking_details.ght_group_booking_id ? booking_details.ght_group_booking_id:""}</p>
                        <p><b>{booking_details.ght_booking_id ? "Booking Id: " : ""}</b>{booking_details.ght_booking_id ? booking_details.ght_booking_id:""}</p>
                        <p> 
                        {
                          booking_details.flight_details.length >= 1 
                          ? 
                          booking_details.flight_details.map((obj, index) => {
                            return(
                              <div>
                              <p><b>Flight Details:</b></p>
                              <p><small>Trip {index+1}</small></p>
                              <table class="table table-bordered mb-4" style={{'margin-top':'20px'}}>
                                <tbody>
                                  <tr>
                                    <th>Flight No.</th>
                                    <th>Flight Name</th>
                                    <th>From City</th>
                                    <th>To City</th>
                                    <th>Departure</th>
                                    <th>Arrival</th>
                                    <th>Class</th>
                                  </tr>
                                  <tr>
                                    <td>{obj.flight_number}</td>
                                    <td>{obj.flight_name}</td>
                                    <td>{obj.from_city}</td>
                                    <td>{obj.to_city}</td>
                                    <td>{obj.flight_dept}</td>
                                    <td>{obj.flight_arrival}</td>
                                    <td>{booking_details.flight_class}</td>
                                  </tr>
                                </tbody>
                              </table>
                              </div>
                              )
                          })

                          :""
                        }
                        </p>
                        <p className="form-group">
                        <input
                        type="text"
                        id="input_booking_id"
                        name="input_booking_id"
                        className="form-control"
                        style={{"width":"30%"}}
                        placeholder="Change Booking Id"
                        />
                        </p>
                      </div>
                      <p><button className="btn btn-primary btn-sm" onClick={handleAddClick}>Save</button></p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    
  }
}

export default GroupBookingDetails;
