import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from "jquery"

var id="";

function ItineraryImageMasterScreen(){
    var data = [];
    const [dayimage_list, set_dayimage_list] = useState([])
    const [edit_flag, set_edit_flag] = useState(false)
    const [clear_flag, set_clear_flag] = useState(false)
    const [id, setid] = useState("")

    useEffect(() => {
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
      const fetchData = async () => {
        const day_images = await axios.get( API_URL + '/packages/DisplayAllItineraryImages/')
        set_dayimage_list(day_images.data)
      };
      fetchData();
    }, [])

    useEffect(() => {
      if(edit_flag == true){
        getData(id)
      }
      if (clear_flag == true){
        document.getElementById("day_img").value = "";
        document.getElementById("create").classList.remove("hide");
        document.getElementById("update").classList.add("hide");
      }
    }, [edit_flag, clear_flag])

  var columns = [
      {
        name: 'Image Urls',
        selector: 'day_img',
      },
      {
        name: '',
        selector: 'edit',
      },
  ];

  const createAPI = async (req) => {
    const day_images = await axios.post( API_URL + '/packages/CreateAndUpdateItinararyImage/', req)
    if(day_images.data == "Created Successfully" || day_images.data == "Updated Successfully"){
      swal(day_images.data)
      setTimeout(() => {window.location.reload()}, 1000)
    }
    else{
      swal("Something went wrong, Please try again later!")
    }
  }

  const setData = (id, type) => {
    
    if($("#day_img").val() == ""){
      swal("Please Choose Image")
    }
    else{
      
      if (type == "create"){
        debugger
        var formdata = new FormData()
        formdata.append('day_img', document.getElementById("day_img").files[0])
        var obj = {
          day_img: document.getElementById("day_img").files[0],
        }
      }
      if (type == "update"){
        var formdata = new FormData()
        formdata.append('id', id)
        formdata.append('day_img', document.getElementById("day_img").files[0])
        var obj = {
          id: id,
          day_img: document.getElementById("day_img").files[0],
        }
      }
      createAPI(formdata)
      console.log("formdata")
      console.log(formdata)
    }
  }

  const getData = async (id) => {
    
    data = await axios.post( API_URL + '/packages/DisplayItineraryImages/', {'id':id})
    data = data.data
    var img_url = data.day_img.split("?")
    document.getElementById("day_img").value = img_url[0]
    document.getElementById("create").classList.add("hide");
    document.getElementById("update").classList.remove("hide");
    set_edit_flag(false)
    set_clear_flag(false)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const delData = async (id) => {
    
    var deleteImage = await axios.post( API_URL + '/packages/DeleteItineraryImages/', {'id':id})
    if(deleteImage.data == "Deleted Successfully"){
      swal(deleteImage.data)
      setTimeout(() => {window.location.reload()}, 1000)
    }
    else{
      swal("Something went wrong, Please try again later!")
    }
  }

  // for copying url to clipboard
  function myFunction() {
    debugger
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  function outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  }
  // for copying url to clipboard end

  return(
      <>
      <nav
        class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
      >
        <Navbar />
      </nav>
      <div class="main-content" id="panel">
        <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
          <TopNavbar />
        </nav>
        <div className="ml-5 mt-5 mr-5">
        <span>{console.log('dayimage_list')}</span>
          <span>{console.log(dayimage_list)}</span>
          <div className="container">
          <Button className="btn btn-sm" id="" onClick={e => set_clear_flag(true)}>+Add New</Button>
            <Form className="packageModal">
            <div className="row">
                <div className="col">
                    <Form.Group>
                      <Form.Label>Itinerary Image</Form.Label>
                      <Form.Control
                        className=""
                        type="file"
                        name="day_img"
                        id="day_img"
                      />
                    </Form.Group>
                </div>
                <div className="col">
                    <br />
                    <Button id="create" onClick={(e) => setData("", "create")}>Create</Button>
                    <Button className="hide" onClick={(e) => setData(id, "update")} id="update">Update</Button>
                </div>
            </div>
            </Form>
          </div>
          <div className="card">
          {dayimage_list.map((obj, i) => {
              var datalist = {
              "day_img":obj.day_img.split("?")[0],
              "edit":
              <div class="edit">
                <div class="toolTip">
                  <span class="tooltiptext" id={'tooltiptext'+i}>Copy to clipboard</span>
                  <button 
                  class="btn btn-sm btn-link" 
                  id={obj.id}
                  style={{"cursor":"pointer"}}
                  onClick={() => {
                    navigator.clipboard.writeText(obj.day_img.split("?")[0])
                    $("#tooltiptext"+i).text("Copied")
                    setTimeout(() => {
                        $("#tooltiptext"+i).text("Copy to clipboard")
                      }, 1000)
                    }}
                  onmouseout={outFunc}
                  onmouseover={$("#tooltiptext"+i).text("Copy to clipboard")}
                  >
                    Copy Url
                  </button>
                  <a class="" onClick={() => {delData(obj.id)}}><img src={process.env.PUBLIC_URL + "/assets/img/icons/common/dustbin.png"} class="navbar-brand-img" alt="..." /></a>
                </div>
              </div>
              ,
            }
            data.push(datalist)
          })}
            <DataTableExtensions
                title="HotelTypeMaster"
                columns={columns}
                data={data}
                print={false}
                export={false}
                filter={false}
                filterPlaceholder="search"
                filterHidden={true}
                filterDigit={1}
              >
              <DataTable
                nHeader
                highlightOnHover
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
     
      </>
    )
}


export default ItineraryImageMasterScreen