import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../const";
import swal from "sweetalert";
import ErrorCls from '../error'

function MarkUpCreateModal(props) {
  const [checked, setChecked] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [travellType, setTravellType] = useState("ALL");
  const [productType, setProductType] = useState("FLIGHT");
  const [markupType, setMarkupType] = useState("AMOUNT");
  const [amount, setAmount] = useState(null);
  const [status, setStatus] = useState(null);

  const [formResponse, setFormResponse] = useState(null);

  // submitting form data
  const handleSubmit = async (e) => {
    // create a new entry
    if (props.formType == "create") {
      props.setLoading(true);
      var token = JSON.parse(localStorage.getItem('response')).access;
      try {

        var url_val = '';

        if(props.modalType == 'MARKUP'){

          var request_data = {
            markup_name: companyName,
            // product: productType,
            // travell_type: travellType,
            markup_type: markupType,
            amount_value: parseInt(amount),
          };

          url_val = '/dashboard/create_mark_up/';
        }
        else if(props.modalType == 'SERVICE'){
          var request_data = {
            service_name: companyName,
            // product: productType,
            // travell_type: travellType,
            service_type: markupType,
            amount_value: parseInt(amount),
          };
          url_val = '/dashboard/create_service_up/';
        }

        const { data: response } = await axios.post(
          API_URL + url_val,
          { headers: { Authorization: `Bearer ${token}` },
           request_data 
         }
          
        );
        setFormResponse(response);
         if(props.modalType == 'MARKUP'){
           swal("New markup created")
         }
         else{
           swal("New service charge created")
         }
         props.onHide();
        if(props.markupres) props.setCreateMarkupres(false);
        else props.setCreateMarkupres(true);
        
      } catch (error) {

        //get error message
        var errObj = new ErrorCls(error.response.status);
        var errorMsg =  errObj.getErrorMsg();
        props.onHide();
        swal(errorMsg);
      }
      props.setLoading(false);
    }

    // update an existing entry
    else if (props.formType == "update") {
      props.setLoading(true);
      var token = JSON.parse(localStorage.getItem('response')).access;

      try {
        const { data: response } = await axios.put(
          API_URL + "/dashboard/create_mark_up/",
          { headers: { Authorization: `Bearer ${token}` } },
          { request_data }
        );
        setFormResponse(response);
        props.setLoading(false)
      } catch (error) {
        console.error(error.message);
      }
      props.setLoading(false);
    }
  };

  useEffect(() => {
    // if update is performed on markup row
    function updatemarkUpRowData() {
      if (props.formType == "update") {
        setCompanyName(props.markUpRowData.companyName);
        setTravellType(props.markUpRowData.travellType);
        setProductType(props.markUpRowData.productType);
        setMarkupType(props.markUpRowData.markupType);
        setAmount(props.markUpRowData.amount);
        setChecked(props.markUpRowData.status);
      }
    }

    updatemarkUpRowData();
  }, []);
  return (
    
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
     
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {props.modalType == 'MARKUP'?'Create Markup':"Create Service"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "0" }}>
        <div class="row">
          <div>
            <div>
              <Form>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-email">
                          {props.modalType == 'MARKUP'?'Markup name':"Service name"}
                        </label>
                        <input
                          type="email"
                          id="input-email"
                          class="form-control"
                          placeholder={props.modalType == 'MARKUP'?'Markup Name':"Service Name"}
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* <div class="col-lg-6">
                      <div class="form-group">
                        <label
                          class="form-control-label"
                          for="input-first-name"
                        >
                          Travell Type
                        </label>
                        <select
                          id=""
                          class="form-control"
                          size="sm"
                          value={travellType}
                          onChange={(e) => {
                            setTravellType(e.target.value);
                          }}
                        >
                          <option class="form-control">ALL</option>
                          <option class="form-control">INTERNATIONAL</option>
                          <option class="form-control">DOMESTIC</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* <hr class="my-4" />

                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label
                          class="form-control-label"
                          for="input-first-name"
                        >
                          Product Type
                        </label>
                        <select
                          id=""
                          class="form-control"
                          size="sm"
                          value={productType}
                          onChange={(e) => {
                            setProductType(e.target.value);
                          }}
                        >
                          <option class="form-control">FLIGHT</option>
                          <option class="form-control">HOTEL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}

                <hr class="my-4" />

                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label
                          class="form-control-label"
                          for="input-first-name"
                        >
                         {props.modalType == 'MARKUP'?'Markup Type':"Service Type"}
                        </label>
                        <select
                          id=""
                          class="form-control"
                          size="sm"
                          value={markupType}
                          onChange={(e) => {
                            setMarkupType(e.target.value);
                          }}form-control
                        >
                          <option class="form-control">AMOUNT</option>
                          <option class={props.modalType == 'SERVICE'?"hide":"form-control"}>PERCENTAGE</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label" for="input-email">
                          Amount
                        </label>
                        <input
                          type="number"
                          id=""
                          class="form-control"  
                          placeholder="0"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="my-4" />
{/* 
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <ToggleButton
                          id="toggle-check"
                          type="checkbox"
                          variant=""
                          checked={checked}
                          value="1"
                          onChange={(e) => setChecked(e.currentTarget.checked)}
                        >
                          status
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ paddingTop: "0" }}>
        <input
          type="submit"
          id="addMemId"
          class="w-25 btn btn-success"
          value="Create"
          onClick={handleSubmit}
        />
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MarkUpCreateModal;
