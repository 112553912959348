//data switch (the switch is used for switching between test data and api data)
export const DATA_TYPE = 'TEST' //TEST, LIVE

// store data
export const USER_LIST_DATA = 'USER_LIST_DATA'

//api action const
export const GET_USER_LIST = 'GET_USER_LIST'

//url data
export const BASE_URL = ''

// User list const 
export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS'
export const CUSTOMER_LIST_FAILURE = 'CUSTOMER_LIST_FAILURE'

//trainer list const
export const TRAINER_LIST_REQUEST = 'TRAINER_LIST_REQUEST'
export const TRAINER_LIST_SUCCESS = 'TRAINER_LIST_SUCCESS'
export const TRAINER_LIST_FAILURE = 'TRAINER_LIST_FAILURE'


//create trainer
export const CREATE_TRAINER_REQUEST = 'CREATE_TRAINER_REQUEST'
export const CREATE_TRAINER_SUCCESS = 'CREATE_TRAINER_SUCCESS'
export const CREATE_TRAINER_FAILURE = 'CREATE_TRAINER_FAILURE'


// toggle type
export const T_MEM = 'T_MEM'
export const N_MEM = 'N_MEM'
export const A_MEM = 'A_MEM'
export const IA_MEM = 'IA_MEM'
export const IN_GRAPH = 'IN_GRAPH'
export const BAR_GRAPH = 'BAR_GRAPH'


export const MONTH_1 = "MONTH_1"
export const MONTH_2 = "MONTH_2"
export const MONTH_3 = "MONTH_3"
export const YEARLY = "YEARLY"


//random data
export const TRAINER = "TRAINER"
export const MEMBER = "MEMBER"

export const POST_REQ = "POST_REQ"
export const GET_REQ = "GET_REQ"
export const PUT_REQ = "PUT_REQ"

export const TYPE = "TEST" //LIVE TEST


// export const WEBSITE_URL = "http://localhost"
// export const DASHBOARD_URL = "http://localhost:3000"
// export const API_URL = "http://127.0.0.1:8000"
// export const SUPPORT_URL = "http://localhost:3000"

// export const WEBSITE_URL = "https://staging.gohoptrip.com"
// export const SUPPORT_URL = "https://stagingsupport.gohoptrip.com"
// export const DASHBOARD_URL = "https://stagingconsole.gohoptrip.com"
// export const API_URL = "https://staginglib.gohoptrip.com"


// export const WEBSITE_URL = "https://dev.gohoptrip.com"
// export const SUPPORT_URL = "https://devsupport.gohoptrip.com"
// export const DASHBOARD_URL = "https://devconsole.gohoptrip.com"
// export const API_URL = "https://devlib.gohoptrip.com"

export const WEBSITE_URL = "https://gohoptrip.com"
export const SUPPORT_URL = "https://support.gohoptrip.com"
export const API_URL = "https://lib.gohoptrip.com"
export const DASHBOARD_URL = "https://console.gohoptrip.com"

export const COMPANY_TOGGLE = 'COMPANY_TOGGLE'
export const MARKUP_TOGGLE = 'MARKUP_TOGGLE'
export const SERVICE_TOGGLE = 'SERVICE_TOGGLE'
export const PRODUCT_TOGGLE = 'PRODUCT_TOGGLE'
export const TRAVEL_TYPE = 'TRAVEL_TYPE'


export const PRODUCT_TYPES= ['FLIGHT', 'HOTEL']
export const TRAVEL_OPT= ['DOMESTIC', 'INTERNATIONAL']



// export const WEBSITE_URL = "https://staging.gohoptrip.com"
// export const DASHBOARD_URL = "https://stagingsupport.gohoptrip.com"
// export const API_URL = "https://staginglib.gohoptrip.com"

// export const WEBSITE_URL = "https://gohoptrip.com"
// export const DASHBOARD_URL = "https://support.gohoptrip.com"
// export const API_URL = "https://lib.gohoptrip.com"

