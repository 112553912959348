// For flight

import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import { JsonToExcel } from "react-json-to-excel";
import CsvDownloadButton from 'react-json-to-csv'
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment'
import $ from "jquery"

var id="";

function TravelAgentMis(){
    var data = [];
    var details = [];
    const [ta_mis_list, set_ta_mis_list] = useState([])
    const [edit_flag, set_edit_flag] = useState(false)
    const [clear_flag, set_clear_flag] = useState(false)
    const [id, setid] = useState("")
    const [loading, setloading] = useState(true)
    const [filterFromDate, setfilterFromDate] = useState("")
    const [filterToDate, setfilterToDate] = useState("")
    const [filterByStatus, setfilterByStatus] = useState("")
    const [filterByCompany, setfilterByCompany] = useState("")
    const [statusName, setstatusName] = useState([])
    const [companyName, setcompanyName] = useState([])

    useEffect(() => {
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
      const fetchData = async () => {
        $(".downloadBtn").text("")
        $(".downloadBtn").removeClass("download-button")
        const misdata = await axios.get( API_URL + '/accounts/get_misdata/')
        set_ta_mis_list(misdata.data)
        setloading(false)
        setTimeout(() => setstatusName(status_name), 1000)
        setTimeout(() => setcompanyName(company_name), 1000)
      };
      fetchData();
    }, [])

    useEffect(() => {
      var html1 = `<option value="">All</option>`
      var html2 = `<option value="">All</option>`
      status_name.map((x,i) => {
        html1 += `<option value=${x}>${x}</option>`
      })
      company_name.map((x,i) => {
        html2 += `<option value=${JSON.stringify(x)}>${x}</option>`
      })
      $("#filterByStatus").html(html1)
      $("#filterByCompany").html(html2)
    },[statusName, companyName])

    var details_col = [
        {
          name: 'Base Fare',
          selector: 'base_fare',
        },
        {
          name: 'TAF',
          selector: 'taf',
        },
        {
          name: 'K3',
          selector: 'k3_tax',
        },
        {
          name: 'Other Tax',
          selector: 'other_tax',
        },
        {
          name: 'Service Charge',
          selector: 'service_charge',
        },
        {
          name: 'GST',
          selector: 'gst',
        },
        {
          name: 'TDS',
          selector: 'tds_deducted',
        },
        {
          name: 'SSRP',
          selector: 'ssrp_total',
        },
        {
          name: 'Amount',
          selector: 'amount',
        },
        {
          name: 'Addon Agency Commissions',
          selector: 'Addon_Agent_Charges',
        },
        {
          name: 'Amendment Type',
          selector: 'amd_type',
        },
        {
          name: 'Amendment Status',
          selector: 'amendment_status',
        },
        {
          name: 'Amendment Charges',
          selector: 'amendment_charges',
        },
        // here
      ]

  const ExpandedComponent = (data) => {
      var masterdetails = data.data
      return (
        <>
          {
            [masterdetails].map((obj, index) => {
              var detailslist = {
                "Addon_Agent_Charges":obj.Addon_Agent_Charges != null ? obj.Addon_Agent_Charges : "-", 
                "base_fare":obj.base_fare != null ? obj.base_fare : "-",
                "taf":obj.taf != null ? obj.taf : "-",
                "k3_tax":obj.taf != null ? obj.k3_tax : "-",
                "other_tax":obj.other_tax != null ? obj.other_tax : "-",
                "service_charge":obj.service_charge != null ? obj.service_charge : "-",
                "gst":obj.gst != null ? obj.gst : "-",
                "tds_deducted":obj.tds_deducted != null ? obj.tds_deducted : "-",
                "ssrp_total":obj.ssrp_total != null ? obj.ssrp_total : "-",
                "amount":obj.amount != null ? obj.amount : "-",
                "amd_type":obj.amd_type != null ? obj.amd_type : "-",
                "amendment_status":obj.amendment_status != null ? obj.amendment_status : "-",
                "amendment_charges":obj.amendment_charges != null ? obj.amendment_charges : "-",
              }
              details = [detailslist]
            }
          )}
          <div className="master_details">
          <DataTableExtensions
              title="TravelAgentMis Details"
              columns={details_col}
              data={details}
              filter={false}
              filterPlaceholder="filter"
              filterHidden={true}
            >
            <DataTable
              nHeader
              striped
              dense
              responsive
            />
          </DataTableExtensions>
          </div>
        </>
      )
  };

  var columns = [
      { 
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Booking Id',
        selector: 'ght_booking_id',
      },
      {
        name: 'Status',
        selector: 'booking_status',
      },
      {
        name: 'Company Name',
        selector: 'company_reg_name',
      },
      {
        name: 'INV Date',
        selector: 'invoice_date',
      },
      {
        name: 'INV No.',
        selector: 'invoice_number',
      },
      {
        name: 'Sector',
        selector: 'sector',
      },
      {
        name:"Pax Name",
        selector: 'pax_name',
      },
      {
        name:"Air Lines",
        selector: 'airline_data',
      },
      {
        name: 'Travel Date',
        selector: 'departure_time',
      },
  ];

  var status_name = []
  var company_name = []
  var flt_status_company = (item) => {
    if (item.booking_status.toLowerCase().includes(filterByStatus.toLowerCase()) && item.company_reg_name.toLowerCase().includes(filterByCompany.toLowerCase())) {
      return item;
    }
  }
  var flt_status = (item) => {
    if (item.booking_status.toLowerCase().includes(filterByStatus.toLowerCase())) {
      return item;
    }
  }
  var flt_company = (item) => {
    if (item.company_reg_name == filterByCompany){
      return item;
    }
  }
  var flt_date = (item) => {
    var dep_date = moment(item.departure_time, 'YYYY-MM-DD').toDate()
    if(dep_date >= filterFromDate && dep_date <= filterToDate){
      if(filterByCompany !== "" && filterByStatus !== ""){
        return flt_status_company(item)
      }
      else if(filterByStatus !== ""){
        return flt_status(item)
      }
      else if(filterByCompany !== ""){
        return flt_company(item)
      }
      else{
        return item;
      }
    }
  }

  var reset = () => {
    setfilterByCompany("")
    setfilterToDate("")
    setfilterFromDate("")
    setfilterByStatus("")
    $("#filterFromDate").val("")
    $("#filterToDate").val("")
    $("#filterByStatus").val("")
    $("#filterByCompany").val("")
  }

  return(
      <>
      <nav
        className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
      >
        <Navbar />
      </nav>
      <div className="main-content ta_table" id="panel">
        <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
          <TopNavbar />
        </nav>
        <div className="container ml-5 mt-5 mr-5">
          <div className="card">
          <div className="card-header border-0">
            <h3 className="mb-0">Flight Travel Agent Bookings</h3>
          </div>
          <Row className="container ml-2 mt-2 mr-2" style={{"padding":0}}>
            <Col xs={12} md={3}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <b><span className="mr-2 label label-default">From -</span></b>
                    </Col>
                    <Col>
                      <input
                        type="date"
                        id="filterFromDate"
                        className="date form-control"
                        placeholder=""
                        onChange={(e) => {
                          setfilterFromDate(moment(e.target.value, 'YYYY-MM-DD').toDate())                
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <b><span className="mr-2 label label-default">To -</span></b>
                    </Col>
                    <Col>
                      <input
                        type="date"
                        id="filterToDate"
                        className="date form-control"
                        placeholder=""
                        onChange={(e) => {
                          setfilterToDate(moment(e.target.value, 'YYYY-MM-DD').toDate())                
                        }}
                        />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <b><span className="mr-2 label label-default">Filter by status-</span></b>
              <br />
              <select
              type="text"
              id="filterByStatus"
              className="form-select"
              placeholder="Filter by status"
              onChange={(e) => {
                setfilterByStatus(e.target.value)
              }}
              >
              </select>
            </Col>
            <Col xs={12} md={2}>
              <b><span className="mr-2 label label-default">Filter by company-</span></b>
              <br />
              <select
              type="text"
              id="filterByCompany"
              className="form-select"
              placeholder="Filter by company"
              onChange={(e) => {
                setfilterByCompany(e.target.value)
              }}
              >
              </select>
            </Col>
            <Col xs={12} md={3}>
              <button
              type="button"
              className="btn btn-primary"
              onClick={() => reset()}
              style={{"float":"right"}}
              >
              Reset
              </button>
            </Col>
          </Row>
          {ta_mis_list.map((obj, index) => {
              var departure_time = obj.departure_time
              var actual_date = departure_time.split("T")
              var datalist = {
              "id":obj.id,
              "ght_booking_id":obj.ght_booking_id,
              "booking_status":obj.booking_status,
              "company_reg_name":obj['company_info'] ? obj['company_info'].company_reg_name : "-",
              "invoice_date":obj.invoice.length > 0 ? obj.invoice[0].invoice_date : "-",
              "invoice_number":obj.invoice.length > 0 ? obj.invoice[0].invoice_number : "-",
              "sector":obj.frm_city_code + " - " + obj.to_city_code,
              "pax_name":obj.travellers[0].title + " " + obj.travellers[0].ft_name + " " + obj.travellers[0].lt_name,
              "departure_time":actual_date[0]+ " " + actual_date[1].substring(0, actual_date[1].length-1),
              "Addon_Agent_Charges":obj.invoice.length > 0 ? obj.invoice[0].Addon_Agent_Charges : "-",
              "base_fare":obj.invoice.length > 0 ? obj.invoice[0].base_fare : "-",
              "taf":obj.invoice.length > 0 ? parseFloat(obj.invoice[0].taf) - parseFloat(obj.invoice[0].k3_tax) : "-",
              "k3_tax":obj.invoice.length > 0 ? obj.invoice[0].k3_tax : "-",
              "other_tax":obj.invoice.length > 0 ? obj.invoice[0].other_tax : "-",
              "service_charge":obj.invoice.length > 0 ? obj.invoice[0].service_charge : "-",
              "gst":obj.invoice.length > 0 ? (Math.round(parseFloat(obj.invoice[0].sgst))) +(Math.round(parseFloat(obj.invoice[0].cgst)))+(Math.round(parseFloat(obj.invoice[0].igst))) : "-",
              "tds_deducted":obj.invoice.length > 0 ? obj.invoice[0].tds_deducted : "-",
              "ssrp_total":obj.invoice.length > 0 ? obj.invoice[0].ssrp_total : "-",
              "amount":obj.invoice.length > 0 ? obj.invoice[0].amount : "-",
              "amd_type":obj.amendments ? obj.amendments[0].amd_type : "-",
              "amendment_status":obj.amendments ? obj.amendments[0].amendment_status : "-",
              "amendment_charges":obj.amendments ? obj.amendments[0].amendment_charges : "-",
              "airline_data":obj.airline_data,

              // here
            }
            if(!status_name.includes(obj.booking_status)){
              status_name.push(obj.booking_status)
            };
            if(!company_name.includes(obj['company_info'] ? obj['company_info'].company_reg_name : "-")){
              company_name.push(obj['company_info'] ? obj['company_info'].company_reg_name : "-")
            };
            data.push(datalist)
          })}
          <div className="exportBtns container ml-2 mt-2 mr-2">
            <JsonToExcel
              title=""
              data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })}
              fileName="FlightTravelAgentMis"
              btnClassName="downloadBtn xls"
            />
            <div>
            <CsvDownloadButton
              data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })} 
              filename="FlightTravelAgentMis"
              className="downloadBtn csv"
            >
            Download as Csv
            </CsvDownloadButton>
            </div>
          </div>
            <DataTableExtensions
                title="TravelAgentMis"
                columns={columns}
                data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })}
                print={false}
                export={false}
                filter={false}
                filterPlaceholder="Filter by all"
                filterHidden={false}
                filterDigit={5}
                fileName="FlightTravelAgentMis"
              >
              <DataTable
                nHeader
                highlightOnHover
                pagination
                defaultSortField="id"
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                responsive
                progressPending={loading}
                // progressComponent={"Loading.."}
                theme="light"
                fixedHeader
                // exportHeaders={true}
                export={false}
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
     
      </>
    )

}


export default TravelAgentMis