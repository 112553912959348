import React, { useState, useEffect } from "react";
import axios from "axios";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
} from "../const";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import Moment from 'moment';

function WalletMemberScreen(){
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [statusValue, setStatusValue] = useState(null); 

  const redirectFun =(id)=>{
    if(id==1){
    window.location.href = DASHBOARD_URL + "/NewWalletAccount/"
    }
    else if(id==2){
      window.location.href = DASHBOARD_URL + "/UpdateAccountStatus/"
    }
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      setLoading(true)
      const show_all_members = await axios.get( API_URL + '/points_system/showCustomers/')
      const allCustomers = await axios.get( API_URL + '/accounts/showAllCx/')
      show_all_members.data.map((obj,i) => {
        var filtered = allCustomers.data.find(x => x.id == obj.customer_id);
          obj.phone_number = filtered.phone_number
      })
      set_booking_details_list(show_all_members.data)
      setLoading(false)
    };

    fetchData();
  }, [updateResponse]);


  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <h2 className="mt-3 my-3">
              Digital Account{" "}
              <span style={{"float":"right"}}>
                <Button
                  class="CreateMember"
                  onClick={(e) => {
                    e.preventDefault();
                    redirectFun(1);
                  }}
                >
                  Create Account
                </Button>
                <Button
                  class="CreateMember"
                  onClick={(e) => {
                    e.preventDefault();
                    redirectFun(2);
                  }}
                >
                  Update Account Status
                </Button>
                </span>
            </h2>
            <hr class="my-3" />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div
                    class="table-responsive"
                    style={{ "max-height": "490px" }}
                  >
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Customer Contact</th>
                          <th scope="col">GDV_ID</th>
                          <th scope="col">GDV_Account_ID</th>
                          <th scope="col">Account type</th>
                          <th scope="col">Customer ID</th>
                          <th scope="col">vault_balance</th>
                          <th scope="col">Account_Status</th>
                          <th scope="col">Created_By</th>
                          <th scope="col">Created_DT and Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {booking_details_list?.map((obj, indx) => {
                          var formatted_date = Moment(obj.created_on).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          );
                          return (
                            <tr>
                              <td scope="col">{obj.phone_number}</td>
                              <td scope="col">{obj.gdv_id}</td>
                              <td scope="col">{obj.gdv_account_id}</td>
                              <td scope="col">{obj.account_type}</td>
                              <td scope="col">{obj.customer_id}</td>
                              <td scope="col">{obj.vault_balance}</td>
                              <td scope="col">{obj.account_status}</td>
                              <td scope="col">{obj.created_by}</td>
                              <td scope="col">{formatted_date}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );    
}
}

export default WalletMemberScreen;
