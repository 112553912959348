import React, { useState, useEffect } from "react";
import { Container, ToggleButton, Modal, Button, Form } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { packageBookings } from "../dummyDb";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import $ from "jquery";
import DatePicker from "react-datepicker";
import moment from 'moment';

function PackageOfflineBooking() {
  const [startDate, setStartDate] = useState(new Date());
  const [minDate, setminDate] = useState("");
  const [guestList, setguestList] = useState([{ fname: "", lname: ""}])
  const [pk_booking_id, set_pk_booking_id] = useState(null);
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalShow, setmodalShow] = useState(false);
  const [modal2Show, setmodal2Show] = useState(false);
  const [tabkey, settabkey] = useState("");
  const [allCustomers, setallCustomers] = useState("");
  //for creating booking enteries
  const [BookingReq, setBookingReq] = useState([{
      "pkg_id":"",
      "pkg_name":"",
      "pkg_travel_date":"",
      "pkg_validity":"",
      "pkg_nights":"",
      "pkg_days":"",
      "pkg_guests":"",
      "pkg_booking_date":"",
      "pkg_total_amount":"",
      "pkg_redeem_points":"",
      "reward_amt":0,
      "pkg_total_after_discount":0,
      "api_type":3,
      "book_type":2,
      "createdby":JSON.parse(localStorage.getItem("response")).id,
      "user_id":"",
      "product_type":"",
      "vault_account": false
    }])
  const [RoomReq, setRoomReq] = useState([{
        "package_booking_id":$("#package_booking_id2").val(),
        "no_of_adt":"",
        "no_of_chd":"",
        "pack_guest":[{ fname: "", lname: ""},{ fname: "", lname: ""}]
    }])

  const handleShow = (type) => {
    $(".formtabs").removeClass("hide")
    $(".btnClose").removeClass("hide")
    $(".btnOpen").addClass("hide")
    $("#form_tabs-tab-pk_booking").click()
    $("#form_tabs-tabpane-pk_booking").addClass("active")
    $("#form_tabs-tabpane-pk_booking").addClass("show")
    $("#pkg_redeem_points").removeAttr("disabled")
    // $(".roomAddBtn, .roomRemBtn").removeClass("hide")
    if(type == "add"){
      $( "#form_tabs-tab-pk_rooms").attr("disabled", true)
      $(".cannot_edit").attr("disabled", false)
    }
    if(type == "update"){
      $( "#form_tabs-tab-pk_rooms").attr("disabled", false)
    }
  }
  const handleClose = () => {
    setBookingReq([{
      "pkg_id":"",
      "pkg_name":"",
      "pkg_travel_date":"",
      "pkg_validity":"",
      "pkg_nights":"",
      "pkg_days":"",
      "pkg_guests":"",
      "pkg_booking_date":"",
      "pkg_total_amount":"",
      "pkg_redeem_points":"",
      "reward_amt":0,
      "pkg_total_after_discount":0,
      "api_type":3,
      "book_type":2,
      "createdby":JSON.parse(localStorage.getItem("response")).id,
      "user_id":"",
      "product_type":"",
      "vault_account": false
    }])
    $("#package_booking_id2, .package_booking_id2").val("")
    set_pk_booking_id("")
    setRoomReq([{
      "package_booking_id":$("#package_booking_id2").val(),
      "no_of_adt":"",
      "no_of_chd":"",
      "pack_guest":[{ fname: "", lname: ""},{ fname: "", lname: ""}]
    }])
    $(".addBook").removeClass("hide")
    $(".next").addClass("hide")
    $(".updateBook").addClass("hide")
    $(".formtabs").addClass("hide")
    $(".btnClose").addClass("hide")
    $(".btnOpen").removeClass("hide")
    $("#form_tabs-tabpane-pk_booking").removeClass("active")
    $("#form_tabs-tabpane-pk_booking").removeClass("show")
    $("#form_tabs-tabpane-pk_rooms").removeClass("active")
    $("#form_tabs-tabpane-pk_rooms").removeClass("show")
    $("#form_tabs-tabpane-pk_guests").removeClass("active")
    $("#form_tabs-tabpane-pk_guests").removeClass("show")
    $(".roomAddBtn, .roomRemBtn").removeClass("hide")
    $(".updateRoom").addClass("hide")
    $(".addRoom").removeClass("hide")
    $("#form_tabs-tab-pk_rooms").attr("disabled", true)
  }

  //for review modal
  const handleModalClose = () => setmodalShow(false);
  const handleModal2Close = () => setmodal2Show(false);
  const handleModalShow = async (type) => {
      await setmodalShow(true)
      if(type == "create"){
        
        $(".add_Book").removeClass("hide")
        $(".update_Book").addClass("hide")
      }
      if(type == "update"){
        
        $(".add_Book").addClass("hide")
        $(".update_Book").removeClass("hide")
      }
  }
  const handleModal2Show = async (type) => {
      await setmodal2Show(true)
      if(type == "create"){
        
        $(".add_Room").removeClass("hide")
        $(".update_Room").addClass("hide")
      }
      if(type == "update"){
        
        $(".add_Room").addClass("hide")
        $(".update_Room").removeClass("hide")
      }
  }
  //for review modal end

  const redirectFun =(id)=>{
    
    window.location.href = DASHBOARD_URL + "/package_booking_details?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      setLoading(true)
      const booking_details = await axios.get( API_URL + '/cancel-tripjack/DisplayPackageBooking/')
      const all_customers = await axios.get( API_URL + '/accounts/showAllCx/')
      booking_details.data.map((obj,i) => {
        var filtered = all_customers.data.find(x => x.id == obj.user_id);
          obj.phone_number = filtered.phone_number
      })
      setallCustomers(all_customers.data)
      set_booking_details_list(booking_details.data)
      setLoading(false)
    };
    fetchData();
    startDateFun()
  }, []);

  const handleNegativeVal = (e, i) => {
    if(e.target.value < 0){
      e.target.value = e.target.value * -1
    }    
  }

  // handle input for booking data
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...BookingReq];
    list[index][name] = value;
    setBookingReq(list);
  };

  // handle input for room data
  const handleInputChange2 = (e, index, type) => {
    const { name, value } = e.target;
    const list = [...RoomReq];
    if(type != "name"){
      list[index][name] = value;
    }
    else{
      RoomReq.map((room, ind) => {
        room.pack_guest.map((guest, i) => {
            guest.fname = $("#fname"+ind+i).val()
            guest.lname = $("#lname"+ind+i).val()
        })
      })
    }
    list.package_booking_id = $("#package_booking_id2").val()
    setRoomReq(list)
    
  };

  // handle click event of the Remove button
  const roomRemoveClick = (index) => {
    const list = [...RoomReq];
    list.splice(index, 1);
    setRoomReq(list);
  };

  // handle click event of the Add button
  const roomAddClick = () => {
    setRoomReq([...RoomReq, {
        package_booking_id:"",
        no_of_adt:"",
        no_of_chd:"",
        pack_guest:[{ fname: "", lname: ""},{ fname: "", lname: ""}]
    }]);
  };

  // for guests---------------------------------------------------
  const guestChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...guestList];
    list[index][name] = value;
    setguestList(list);

  };

  // handle click event of the Remove button
  const guestRemoveClick = (index) => {
    const list = [...guestList];
    list.splice(index, 1);
    setguestList(list);
  };

  // handle click event of the Add button
  const guestAddClick = () => {
    setguestList([...guestList, { fname: "", lname: "" }]);
  };

  const AddUpdateRoom = async (type) => {
    swal({
        text: `Kindly confirm the details entered & Continue`,
        buttons: [
                'Cancel',
                'Proceed'
              ],
    }).then(async function(isConfirm){
      if(isConfirm){
        
        var flag = false
        RoomReq.map((room,i) => {
          
          if(room.no_of_adt == "" || room.no_of_chd == ""){
            swal("All fields are required")
            flag = false
            return
          }
          else if(room.pack_guest[0].fname == "" || room.pack_guest[0].lname == ""){
            swal("All fields are required")
            flag = false
          }
          else{
            flag = true
          }
        })
        var roomObj = {"rooms":RoomReq}
        roomObj.rooms.map((x,i) => {
          x.package_booking_id = $("#package_booking_id2").val()
        })
        if(flag == true){
          const addroom = await axios.post( API_URL + '/cancel-tripjack/createUpdatePackageRooms/', roomObj)
          if(addroom.data == "Created Successfully" || addroom.data == "Updated Successfully"){
            swal(addroom.data)
            setTimeout(() => window.location.reload(), 1000)
          }
          else{
            swal("Something went wrong, Please try again later!")
          }
        }
      }
    })
  }

  const getData = async (id) => {
    const data1 = await axios.post( API_URL + '/cancel-tripjack/DisplayPackageBookingById/', {"id":id})
    data1.data.pack_room.map((x,i) => {
      x.package_booking_id = data1.data.package_booking_id
    })
    set_pk_booking_id(data1.data.package_booking_id)
    $("#package_booking_id2").val(data1.data.package_booking_id)
    $(".package_booking_id2").val(data1.data.package_booking_id)
    setBookingReq([data1.data])
    data1.data.pack_room.length > 0 ? setRoomReq(data1.data.pack_room) : setRoomReq([{
        package_booking_id:"",
        no_of_adt:"",
        no_of_chd:"",
        pack_guest:[
            { fname: "", lname: ""},
            { fname: "", lname: ""}
        ]
    }])
    RoomReq.package_booking_id = $("#package_booking_id2").val()
    $(".addBook").addClass("hide")
    $(".updateBook").removeClass("hide")
    $(".updateRoom").removeClass("hide")
    $(".addRoom").addClass("hide")
    $("#form_tabs-tab-pk_rooms").attr("disabled", false)
    $('.cannot_edit').attr("disabled", true)
    if(data1.data.pack_room.length > 0){
      $(".roomAddBtn, .roomRemBtn").addClass("hide")
    }
    else{
      $(".roomAddBtn, .roomRemBtn").removeClass("hide")
    }
    handleShow("update")
    $("#pkg_redeem_points").attr("disabled", true)
    $(window).scrollTop(0)
  }

  const BookingReview = (type) => {
    
    var flag = false
    BookingReq.map((obj, i) => {
      
      if(obj.api_type == "" || obj.book_type == "" || obj.createdby == "" || obj.pkg_booking_date == "" || obj.pkg_days == "" || obj.pkg_guests == "" || obj.pkg_id == "" || obj.pkg_name == "" || obj.pkg_nights == "" || obj.pkg_total_amount == "" || obj.pkg_validity == "" || obj.user_id == "" || obj.product_type == ""){
        
        swal("All fields are required")
        flag = false
        return
      }
      else{
        flag = true
      }
    })
    if(flag == true){
      handleModalShow(type)
    }
  }

  const RoomReview = (type) => {
    
    var flag = false
    RoomReq.map((room,i) => {
      
      if(room.no_of_adt == "" || room.no_of_chd == ""){
        swal("All fields are required")
        flag = false
        return
      }
      else if(room.pack_guest[0].fname == "" || room.pack_guest[0].lname == ""){
        swal("All fields are required")
        flag = false
        return
      }
      else{
        flag = true
      }
    })
    if(flag == true){
      handleModal2Show(type)
    }
  }

  //package booking entry and update function
  const AddUpdateBooking = (type) => {
    swal({
        text: `Kindly confirm the details entered & Continue`,
        buttons: [
                'Cancel',
                'Proceed'
              ],
    }).then(async function(isConfirm){
      if(isConfirm){
        var obj = ""
        if(type == "create"){
          obj = BookingReq
        }
        else if(type == "update"){
          var id = $("#id").val()
          obj = BookingReq
          // obj.id = id
        }
        BookingReq.map(async (obj, i) => {
          
            if(type == "create"){
              if(obj.pkg_redeem_points > 0){
                var getVault = await axios.post( API_URL + '/points_system/showcredits/', {"id":obj.user_id})
                if(getVault.data == "Vault Account Not Found" || getVault.data.account_status != "Active"){
                  return swal(`Cannot Redeem, Vault account not found`)
                }
                else{
                  obj.vault_account = true
                }
              }
              else{
                obj.pkg_redeem_points = 0;
                obj.vault_account = false
              }
            }
            else{
              obj.vault_account = false;
            }
            console.log("BookingReq")
            console.log(JSON.stringify(BookingReq))
            const add = await axios.post( API_URL + '/cancel-tripjack/createUpdatePackageBooking/', BookingReq[0])
            if(typeof(add.data) != "string"){

              if(add.data.msg == "Booking Updated Successfully"){
                debugger
                handleModalClose()
                $("#pkg_total_after_discount").val(add.data.after_disc_amt)
                $('.cannot_edit').attr("disabled", false)
                $(".addBook").addClass("hide")
                $(".updateBook").addClass("hide")
                $(".next").removeClass("hide")
                swal(add.data.msg)
              }
              else{
                debugger
                handleModalClose()
                $("#pkg_total_after_discount").val(add.data.after_disc_amt)
                await $("#package_booking_id2").val(add.data.package_booking_id)
                $(".package_booking_id2").val(add.data.package_booking_id)
                $(".addBook").addClass("hide")
                $(".updateBook").addClass("hide")
                $(".next").removeClass("hide")
                swal(add.data.msg)
                  const request_data = {
                      "user_id":BookingReq[0].user_id,
                      "booking_amount":BookingReq[0].pkg_total_amount,
                      "pkg_guests":BookingReq[0].pkg_guests,
                      "product_name":"HOLIDAY_PACKAGE",
                      "product_type":BookingReq[0].product_type,
                      "booking_id":add.data.package_booking_id
                  }
                  /*----------savevaulttransaction function----------*/
                  const saveVaultTransaction = async (type, points, booking_id) => {
                    var getVault = await axios.post( API_URL + '/points_system/showcredits/', {"id":obj.user_id})
                    const transaction_request = {
                        "gdvt_id":getVault.data.gdv_id,
                        "gdvt_account_id":getVault.data.id,
                        "transaction_status":"SUCCESS",
                        "product_code":"HOLIDAY_PACKAGE",
                        "booking_id":booking_id,
                        "points":points,
                        "credit":type=="credit" ? "true" : "false",
                        "debit":type=="debit" ? "true" : "false"
                    }
                    const saveTransactionApi = await axios.post( API_URL + '/points_system/SavevaultTransactions/', transaction_request)
                    if(saveTransactionApi.data == "Created successfully"){
                      console.log("Transaction saved")
                    }
                    else{
                      console.log("Transaction not saved")
                    }
                  }
                  /*----------savevaulttransaction function end----------*/
                  if(obj.vault_account == true){
                    if(obj.pkg_redeem_points > 0){
                      saveVaultTransaction("debit", obj.pkg_redeem_points, add.data.package_booking_id)
                    }
                  }
                  /*----------reward function----------*/
                  const getReward = async () => {
                    const rewardApi = await axios.post( API_URL + '/cancel-tripjack/PackageBookingRewardPoints/', request_data)
                    if(rewardApi.data == "Reward not set"){
                      //pass
                    }
                    else{
                      swal(`Added ${rewardApi.data.Rewardpoints} Reward Points`)
                      .then(() => {
                        saveVaultTransaction("credit", rewardApi.data.Rewardpoints, $("#package_booking_id2").val())
                      })
                      $("#pkg_reward_points").val(rewardApi.data.Rewardpoints)
                    }
                  }
                  /*----------reward function end----------*/
                  if(type == "create"){
                    getReward()
                  }
              }
              
            }
            else{
              swal(add.data)
            }
        })
      }
    })
    
  }

  const roomTab = async () => {
    $("#form_tabs-tab-pk_rooms").attr("disabled", false)
    $("#form_tabs-tab-pk_rooms").click()
    $(window).scrollTop(0)
    // $(".package_booking_id2").val(pk_booking_id)
  }

  const startDateFun = () => {
    var currentMonth = startDate.getMonth()+1;
    var currentDate = startDate.getDate();
    if (currentMonth < 10) 
    { 
      currentMonth = '0' + currentMonth; 
    }
    if (currentDate < 10) 
    { 
      currentDate = '0' + currentDate; 
    }
    var finalDate = startDate.getFullYear()+"-"+currentMonth+"-"+currentDate;
    setminDate(finalDate)
    console.log("newDate")
    console.log(finalDate)
  }

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
          <div class="row">
            <div class="col-xl-12">
              <div class="">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6>Package Offline Bookings</h6>
                    </div>
                    <div class="col text-right">
                      <a class="btn btn-sm btn-primary btnOpen" style={{"color":"white"}} variant="primary" onClick={(e) => handleShow("add")}
                      >
                        Add
                      </a>
                      <a class="btn btn-sm btn-primary btnClose hide" style={{"color":"white"}} variant="primary" onClick={handleClose}
                      >
                        Close
                      </a>
                      
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey={tabkey}
                    id="form_tabs"
                    className="mb-3 hide formtabs"
                  >
                  {/*package booking form*/}
                    <Tab eventKey="pk_booking" title="Add Booking" className="packageModal booking_tab">
                      <Form>
                        <Form.Group>
                        {BookingReq.map((obj, i) => {
                          return(
                            <div>
                              <div className="text-center" style={{"color":"red"}}>
                                <span id="error" className="hide"></span>
                                <input type="hidden" id="createdby" className="createdby" name="createdby" defaultValue={obj.createdby} />
                                <input type="hidden" id="id" className="hide id" name="id" defaultValue={obj.id ? obj.id : ""} />
                                <input type="hidden" className="hide pk_booking_id" name="pk_booking_id" />
                              </div>
                              <Form.Label>User Contact Number</Form.Label>
                              <Form.Control
                                as="select"
                                className="user_id required_field cannot_edit"
                                name="user_id"
                                id="user_id"
                                onChange={(e) => handleInputChange(e,i)}
                              >
                                <option value="">Select</option>
                                {
                                  allCustomers.map((x,i) => {
                                    return(
                                      <option value={x.id} selected = {obj.user_id == x.id ? "selected" : ""}>{x.phone_number}</option>
                                      )
                                  })
                                }
                              </Form.Control>
                              <Form.Label>Package Id</Form.Label>
                              <Form.Control
                                className="pkg_id required_field cannot_edit"
                                name="pkg_id"
                                id="pkg_id"
                                defaultValue={obj.pkg_id}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>Package Name</Form.Label>
                              <Form.Control
                                type="text"
                                className="pkg_name required_field cannot_edit"
                                name="pkg_name"
                                id="pkg_name"
                                defaultValue={obj.pkg_name}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>Package Type</Form.Label>
                              <Form.Control
                                as="select"
                                className="product_type required_field cannot_edit"
                                name="product_type"
                                id="product_type"
                                onChange={(e) => handleInputChange(e,i)}
                              >
                                <option>Select</option>
                                <option selected = {obj.product_type == "DOMESTIC" ? "selected" : ""}>DOMESTIC</option>
                                <option selected = {obj.product_type == "INTERNATIONAL" ? "selected" : ""}>INTERNATIONAL</option>
                              </Form.Control>
                              <Form.Label>Travel Date</Form.Label>
                              <Form.Control
                                type="date"
                                className="pkg_travel_date required_field"
                                name="pkg_travel_date"
                                id="pkg_travel_date"
                                defaultValue={obj.pkg_travel_date}
                                onChange={(e) => handleInputChange(e,i)}
                                min={minDate}
                              >
                              </Form.Control>
                              <Form.Label>Validity</Form.Label>
                              <Form.Control
                                type="date"
                                className="pkg_validity required_field"
                                name="pkg_validity"
                                id="pkg_validity"
                                defaultValue={obj.pkg_validity}
                                onChange={(e) => handleInputChange(e,i)}
                                min={minDate}
                              >
                              </Form.Control>
                              <Form.Label>No. of Nights</Form.Label>
                              <Form.Control
                                type="number"
                                className="pkg_nights required_field"
                                name="pkg_nights"
                                id="pkg_nights"
                                defaultValue={obj.pkg_nights}
                                onInput={(e) => handleNegativeVal(e)}
                                onChange={(e) => handleInputChange(e,i)}
                                min='0'
                              >
                              </Form.Control>
                              <Form.Label>No. of Days</Form.Label>
                              <Form.Control
                                type="number"
                                className="pkg_days required_field"
                                name="pkg_days"
                                id="pkg_days"
                                defaultValue={obj.pkg_days}
                                onInput={(e) => handleNegativeVal(e)}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>No. of Guests</Form.Label>
                              <Form.Control
                                type="number"
                                className="pkg_guests required_field cannot_edit"
                                name="pkg_guests"
                                id="pkg_guests"
                                defaultValue={obj.pkg_guests}
                                onInput={(e) => handleNegativeVal(e)}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>Booking Date</Form.Label>
                              <Form.Control
                                type="date"
                                className="pkg_booking_date required_field"
                                name="pkg_booking_date"
                                id="pkg_booking_date"
                                defaultValue={obj.pkg_booking_date}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>API Type</Form.Label>
                              <Form.Control
                                disabled
                                as="select"
                                className="api_type required_field"
                                name="api_type"
                                id="api_type"
                                defaultValue={obj.api_type}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                                <option value="">Select</option>
                                <option value="1">TRIPJACK</option>
                                <option value="2">TBO</option>
                                <option value="3" selected>GOHOPTRIP</option>
                              </Form.Control>
                              <Form.Label>Booking Type</Form.Label>
                              <Form.Control
                                disabled
                                as="select"
                                className="book_type required_field"
                                name="book_type"
                                id="book_type"
                                defaultValue={obj.book_type}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                                <option value="">Select</option>
                                <option value="1">Online</option>
                                <option value="2" selected>Offline</option>
                               </Form.Control>
                              <Form.Label>Total Amount</Form.Label>
                              <Form.Control
                                type="number"
                                className="pkg_total_amount required_field cannot_edit"
                                name="pkg_total_amount"
                                id="pkg_total_amount"
                                defaultValue={obj.pkg_total_amount}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>Redeem Points</Form.Label>
                              <Form.Control
                                type="number"
                                className="pkg_redeem_points required_field cannot_edit"
                                name="pkg_redeem_points"
                                id="pkg_redeem_points"
                                value={obj.pkg_redeem_points}
                                onChange={(e) => handleInputChange(e,i)}
                              >
                              </Form.Control>
                              <Form.Label>Reward Points</Form.Label>
                              <Form.Control
                                disabled
                                type="number"
                                className="pkg_reward_points required_field"
                                name="pkg_reward_points"
                                id="pkg_reward_points"
                                defaultValue={obj.pkg_reward_points}
                                onChange={(e) => handleInputChange(e,i)}
                                value="0"
                              >
                              </Form.Control>
                              <Form.Label>Amount After Deduction</Form.Label>
                              <Form.Control
                                disabled
                                type="number"
                                className="pkg_total_after_discount required_field"
                                name="pkg_total_after_discount"
                                id="pkg_total_after_discount"
                                defaultValue={obj.pkg_total_after_discount}
                                onChange={(e) => handleInputChange(e,i)}
                                value="0"
                              >
                              </Form.Control>
                            </div>
                            )
                        })}
                        </Form.Group>
                      </Form>
                      <Button className="addBook" variant="primary" onClick={(e) => BookingReview("create")}>
                        Add
                      </Button>
                      <Button className="updateBook hide" variant="primary" onClick={(e) => BookingReview("update")}>
                        Update
                      </Button>
                      <Button className="next hide" variant="primary" onClick={roomTab}>
                        Next
                      </Button>
                    </Tab>
                  {/*package booking form end*/}

                  {/*package room form*/}
                      <Tab eventKey="pk_rooms" className="packageModal room_tab" title="Add Rooms">
                        <Form>
                          <Form.Group>
                          {RoomReq.map((room, index) => {
                            return(
                              <div>

                                <div className="text-center" style={{"color":"red"}}>
                                  <span id="error2" className="hide"></span>
                                </div>
                                <h5>Room {index+1}</h5>
                                <Form.Label>Package Booking Id</Form.Label>
                                <input type="hidden" id="package_booking_id2"/>

                                <Form.Control
                                  disabled
                                  defaultValue={pk_booking_id}
                                  type="text"
                                  className="package_booking_id required_field2 package_booking_id2"
                                  name="package_booking_id"
                                  onChange={(e) => handleInputChange2(e,index)}
                                >
                                </Form.Control>

                                <Form.Label>No of Adult</Form.Label>
                                <Form.Control
                                  defaultValue={room.no_of_adt}
                                  type="text"
                                  className="required_field2"
                                  name="no_of_adt"
                                  id="no_of_adt"
                                  onInput={(e) => handleNegativeVal(e)}
                                  onChange={(e) => handleInputChange2(e,index)}
                                >
                                </Form.Control>

                                <Form.Label>No of Child</Form.Label>
                                <Form.Control
                                  defaultValue={room.no_of_chd}
                                  type="number"
                                  className="required_field2"
                                  name="no_of_chd"
                                  id="no_of_chd"
                                  onInput={(e) => handleNegativeVal(e)}
                                  onChange={(e) => handleInputChange2(e,index)}
                                >
                                </Form.Control>
                                {room.pack_guest.map((x, i) => {
                                    return (
                                      <Form.Group className="box">
                                        <Form.Label for="">Guest {i + 1}</Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="required_field2"
                                          placeholder="First Name"
                                          name="fname"
                                          defaultValue={room.pack_guest[i].fname}
                                          id={"fname"+index+i}
                                          onChange={(e) => handleInputChange2(e, index, "name")}
                                        />
                                        <p></p>
                                        <Form.Control
                                          type="text"
                                          className="required_field2"
                                          placeholder="Last Name"
                                          name="lname"
                                          defaultValue={room.pack_guest[i].lname}
                                          id={"lname"+index+i}
                                          onChange={(e) => handleInputChange2(e, index, "name")}
                                        />
                                      </Form.Group>
                                  );

                                })}
                                <div className="">
                                  {RoomReq.length !== 1 && (
                                    <button
                                      className="btn btn-danger btn-sm roomRemBtn"
                                      onClick={() => roomRemoveClick(index)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                  {RoomReq.length - 1 === index && (
                                    <button
                                      className="btn btn-primary btn-sm roomAddBtn"
                                      onClick={roomAddClick}
                                    >
                                      Add New Room
                                    </button>
                                  )}
                              </div>
                              </div>
                              )
                          })}
                          </Form.Group>
                        </Form>
                        <Button variant="primary" className="addRoom" onClick={(e) => RoomReview("create")}>
                          Save
                        </Button>
                        <Button variant="primary" className="updateRoom hide" onClick={(e) => RoomReview("update")}>
                          Update Room
                        </Button>
                      </Tab>
                  {/*package room form end*/}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush packagebookigtable">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">User Contact Number</th>
                          <th scope="col">Booking Id</th>
                          <th scope="col">Package Name</th>
                          <th scope="col">Package Id</th>
                          <th scope="col">Booking Date</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          booking_details_list?.map((obj, indx)=>{
                            return(
                          <tr style={{"background-color":obj.pack_room.length > 0 ? "" : "#f1aeae"}}>
                            <td scope="col">{obj.phone_number}</td>
                            <td scope="col">{obj.package_booking_id}</td>
                            <td scope="col">{obj.pkg_name}</td>
                            <td scope="col">{obj.pkg_id}</td>
                            <td scope="col">{obj.pkg_booking_date}</td>
                            <td>
                              <button class="btn btn-primary" style={{cursor:"pointer"}} onClick = {
                                    (e)=>{
                                  e.preventDefault()
                                    redirectFun(obj.id)
                                  }

                                  } variant="primary"> View Details 
                              </button>
                            </td>
                            <td>
                              <button class="btn btn-primary" style={{cursor:"pointer"}} variant="primary" onClick={() => getData(obj.id)}> Update 
                              </button>
                            </td>
                          </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*add booking review modal*/}
            <Modal show={modalShow} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Please Review Entered Data</Modal.Title>
              </Modal.Header>
              <Modal.Body className="review_body">
                {BookingReq.map((obj, i) => {
                  return(
                      <div className="row">
                        <p><span className="reviewTitle">User Contact Number : </span><span className="review_val">{$('#user_id').find(":selected").text()}</span></p>
                        <p><span className="reviewTitle">Package Id : </span><span className="review_val">{$('#pkg_id').val()}</span></p>
                        <p><span className="reviewTitle">Package Name : </span><span className="review_val">{$('#pkg_name').val()}</span></p>
                        <p><span className="reviewTitle">Package Type : </span><span className="review_val">{$('#product_type').find(":selected").text()}</span></p>
                        <p><span className="reviewTitle">Travel Date : </span><span className="review_val">{$('#pkg_travel_date').val()}</span></p>
                        <p><span className="reviewTitle">Validity : </span><span className="review_val">{$('#pkg_validity').val()}</span></p>
                        <p><span className="reviewTitle">No. of Nights : </span><span className="review_val">{$('#pkg_nights').val()}</span></p>
                        <p><span className="reviewTitle">No. of Days : </span><span className="review_val">{$('#pkg_days').val()}</span></p>
                        <p><span className="reviewTitle">No. of Guests : </span><span className="review_val">{$('#pkg_guests').val()}</span></p>
                        <p><span className="reviewTitle">Booking Date : </span><span className="review_val">{$('#pkg_booking_date').val()}</span></p>
                        <p><span className="reviewTitle">API Type : </span><span className="review_val">{$('#api_type').find(":selected").text()}</span></p>
                        <p><span className="reviewTitle">Booking Type : </span><span className="review_val">{$('#book_type').find(":selected").text()}</span></p>
                        <p><span className="reviewTitle">Total Amount : </span><span className="review_val">{$('#pkg_total_amount').val()}</span></p>
                        {
                        $('#pkg_redeem_points').val() > 0
                        ?
                        <p><span className="reviewTitle">Redeem Points : </span><span className="review_val">{$('#pkg_redeem_points').val()}</span></p>
                        :
                        ""
                        }
                      </div>
                    )
                })}
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn btn-sm" variant="secondary" onClick={handleModalClose}>
                  Edit
                </Button>
                <Button className="btn btn-sm add_Book" variant="primary" onClick={() => AddUpdateBooking("create")}>
                  Add Booking
                </Button>
                <Button className="btn btn-sm update_Book" variant="primary" onClick={(e) => AddUpdateBooking("update")}>
                  Update Booking
                </Button>
              </Modal.Footer>
            </Modal>
          {/*add booking review modal end*/}
        {/*add room review modal*/}
            <Modal show={modal2Show} onHide={handleModal2Close}>
              <Modal.Header closeButton>
                <Modal.Title>Please Review Entered Data</Modal.Title>
              </Modal.Header>
              <Modal.Body className="review_body">
                {BookingReq.map((obj, i) => {
                  return(
                      <div className="row">
                      <p><span className="reviewTitle">Package Booking Id : </span><span className="review_val">{$('#package_booking_id2').val()}</span></p>
                        {RoomReq.map((room, i) => {
                          return(
                            <div>
                              <h5>Room {i+1}</h5>
                              <p><span className="reviewTitle">No of Adult : </span><span className="review_val">{room.no_of_adt}</span></p>
                              <p><span className="reviewTitle">No of Child : </span><span className="review_val">{room.no_of_chd}</span></p>
                              {room.pack_guest.map((guest, j) => {
                                return(
                                  <div>
                                    <p>
                                    <span className="reviewTitle">Guest {j+1}</span> - 
                                    { guest.fname != "" ?
                                      <span className="review_val">
                                      {
                                        guest.fname != "" ? guest.fname : ""
                                      }
                                      </span> :
                                      ""
                                    }
                                    { guest.lname != "" ?
                                      <span className="review_val">
                                      {
                                        guest.lname != "" ? guest.lname : ""
                                      }
                                      </span> :
                                      ""
                                    }
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    )
                })}
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn btn-sm" variant="secondary" onClick={handleModal2Close}>
                  Edit
                </Button>
                <Button className="btn btn-sm add_Room" variant="primary" onClick={() => AddUpdateRoom("create")}>
                  Add Room
                </Button>
                <Button className="btn btn-sm update_Room" variant="primary" onClick={(e) => AddUpdateRoom("update")}>
                  Update Room
                </Button>
              </Modal.Footer>
            </Modal>
          {/*add room review modal end*/}
        </div>
      </>
    );
  }
  }

export default PackageOfflineBooking;
