import React from 'react'
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";

function Navbar() {
    return (
      <div class="scrollbar-inner">
        <div class="sidenav-header  align-items-center">
          <a class="navbar-brand brandCls" href="javascript:void(0)">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/Gohoptriplogo.png"}
              class="navbar-brand-img"
              alt="..."
            />
          </a>
        </div>


        <nav className="navbar">
          <div class="collapse navbar-collapse" id="sidenav-collapse-main">
            <ul class="navbar-nav">
            <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> Markup
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link class="nav-link" activeClassName="is-active" to="/">
                  <span class="nav-link-text">Create Markup</span>
                </Link>
                <Link class="nav-link"activeClassName="is-active" to="/companies-markup">
                  <span class="nav-link-text">Assign MarkUp</span>
                </Link>
                </div>
              </li>

              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> Service Charge
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link class="nav-link" activeClassName="is-active" to="/create-service">
                  <span class="nav-link-text">Create Service Charges</span>
                </Link>
                <Link
                  class="nav-link" activeClassName="is-active" to="/companies-service">
                  <span class="nav-link-text">Assign Service Charges</span>
                </Link>
                </div>
              </li>
              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> Direct Pay
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="nav-link" activeClassName="is-active" to="/pay">
                    <a class="dropdown-item" href="#">Create Payment Request</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/DirectPayScreen">
                    <a class="dropdown-item" href="#">Direct Pay View</a>
                  </Link>
                </div>
              </li>








              <li class="nav-item">
                <Link
                  class="nav-link"
                  activeClassName="is-active"
                  to="/groupBooking"
                >
                  <i class="ni ni-tv-2 text-primary"></i>
                  <span class="nav-link-text">Group Booking</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  activeClassName="is-active"
                  to="/PetBooking">
                  <i class="ni ni-tv-2 text-primary"></i>
                  <span class="nav-link-text">Pets Booking</span>
                </Link>
              </li>
              {/*<li class="nav-item">
                <Link
                  class="nav-link"
                  activeClassName="is-active"
                  to="/DisplayBookingdetails"
                >
                  <i class="ni ni-tv-2 text-primary"></i>
                  <span class="nav-link-text">Display Booking Details</span>
                </Link>
              </li>*/}
              {/*<li class="nav-item">
                <Link class="nav-link" activeClassName="is-active" to="/packageGroupBooking">
                  <i class="ni ni-tv-2 text-primary"></i>
                  <span class="nav-link-text">Package Group Booking</span>
                </Link>
              </li>*/}
              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i>Digital Points
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="nav-link" activeClassName="is-active" to="/digitalVault">
                    <a class="dropdown-item" href="#">Card Membership</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/walletMembers">
                    <a class="dropdown-item" href="#">Digital Accounts</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/walletHistory">
                    <a class="dropdown-item" href="#">Digital Account History</a>
                  </Link>
                  {/* <Link class="nav-link" activeClassName="is-active" to="/merchantWallet">
                    <a class="dropdown-item" href="#"> Merchant Wallet</a>
                  </Link> */}
                  <Link class="nav-link" activeClassName="is-active" to="/TransactionHistory">
                    <a class="dropdown-item" href="#">Digital Transactions</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/Rewards">
                    <a class="dropdown-item" href="#">Reward & Redeem Points</a>
                  </Link>
                </div>
              </li>
              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> Holiday Packages
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="nav-link" activeClassName="is-active" to="/package_bookings">
                    <a class="dropdown-item" href="#">Offline Bookings</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/packagemanagement">
                    <a class="dropdown-item" href="#">Package Master</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/hoteltype_master">
                    <a class="dropdown-item" href="#">Hotel Type Master</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/supplier_master">
                    <a class="dropdown-item" href="#">Supplier Master</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/itinerary_images">
                    <a class="dropdown-item" href="#">Itinerary Image Master</a>
                  </Link>
                </div>
              </li>
              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> B2B
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="nav-link" activeClassName="is-active" to="/b2b_entries">
                    <a class="dropdown-item" href="#">B2B Registration Enquiries</a>
                  </Link>
                </div>
              </li>
              <li class="nav-item card_members">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ni ni-tv-2 text-primary"></i> Travel Agent MIS
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link class="nav-link" activeClassName="is-active" to="/travel-agent-mis-flights">
                    <a class="dropdown-item" href="#">Flight MIS</a>
                  </Link>
                  <Link class="nav-link" activeClassName="is-active" to="/travel-agent-mis-hotels">
                    <a class="dropdown-item" href="#">Hotel MIS</a>
                  </Link>
                </div>
              </li>
            </ul>
            {/* <hr class="my-3" /> */}
          </div>
        </nav>
      </div>
    );
}

export default Navbar
