import React, { useState, useEffect, useLayoutEffect } from "react";
import Select from "react-select";
import { API_URL, COMPANY_TOGGLE, MARKUP_TOGGLE, PRODUCT_TOGGLE, PRODUCT_TYPES, SERVICE_TOGGLE, TRAVEL_OPT, TRAVEL_TYPE, TYPE } from "../const";
import axios from "axios";


class ToggleClass{
  
  constructor(responseData) {
    debugger
    this.responseData = responseData;
    this.options = [];
  }

  setUpCompany =()=>{
    this.responseData.map((res, indx) => {
      this.options.push({ value: res, label: res.company_reg_name });
    });

    return this.options;
  }

  setMarkup =()=>{
    debugger
    this.responseData.markup_list.map((res, indx) => {
      debugger
      this.options.push({ value: res, label: res.markup_name+" ("+res.product+")" });
    });

    return this.options;
  }

  setService =()=>{
    this.responseData.service_list.map((res, indx) => {
      console.log("sssssssssssssssss");
      console.log(res);
      this.options.push({ value: res, label: res.service_name });
    });

    return this.options;
  }

  setProduct =()=>{
    debugger;
    // console.log(this.responseData);
    this.responseData.map((res, indx) => {
      console.log("resssssssssssssssss")
      console.log(res);
      this.options.push({ value: res, label: res.product_name });
    });

    return this.options;
  }

  setProductType=()=>{
    debugger;
    console.log(this.responseData);
    this.responseData.map((res, indx) => {
      this.options.push({ value: res.product_name, label: res.product_name });
    });

    return this.options;
  }


  setTravellType =()=>{
    this.responseData.map((res, indx) => {
    console.log(res);

      this.options.push({ value: res, label: res.product_type });
    });

    return this.options;
  }
}

function CustomToggle({ toggle_type, product_type, selectedOptn }) {
  var list_data = [];

  const [listData, setListData] = useState("");
  const [loading, setLoading] = useState(false);

  var placeHolderVar = "";

  if(toggle_type == COMPANY_TOGGLE)
  placeHolderVar = "Company";

  else if(toggle_type == PRODUCT_TOGGLE)
  placeHolderVar = "Product";

  else if(toggle_type == TRAVEL_TYPE)
  placeHolderVar = "Type"

  else if(toggle_type == MARKUP_TOGGLE)
  placeHolderVar = "MarkUp";

  else{
    placeHolderVar = "None";
  }
  var options = [];

  useLayoutEffect(() => {
    debugger;
    const fetchDropDownData = async (BASIC_URL) => {
      debugger;
      if (TYPE == "TEST") {
        setLoading(true);
        var token = JSON.parse(localStorage.getItem('response')).access;
        try {
          const { data: response } = await axios.get(API_URL + BASIC_URL);

          //setting up options for dropdown class
          console.log(response);
          var toggleObj = new ToggleClass(response);

          if (toggle_type == COMPANY_TOGGLE) 
          {
            //setting up options for dropdown
            options = toggleObj.setUpCompany();
          } 
          else if (toggle_type == MARKUP_TOGGLE) 
          {
            //setting up options for dropdown
            options = toggleObj.setMarkup();  
           
          }
          else if (toggle_type == PRODUCT_TOGGLE) 
          {
            //setting up options for dropdown
            options = toggleObj.setProduct(); 
               
          }

          else if (toggle_type == SERVICE_TOGGLE) 
          {
            //setting up options for dropdown
            options = toggleObj.setService();         
          }

          else if (toggle_type == PRODUCT_TOGGLE) 
          {
            //setting up options for dropdown
            options = toggleObj.setProductType();         
          }
          else if (toggle_type == TRAVEL_TYPE) 
          {
            //setting up options for dropdown
            options = toggleObj.setTravellType(); 
                
          }
          else{
            options.append({ value: "None", label: "none" });
          }
          
          //setting up list data for dropdown
          setListData(options);
          setLoading(false);
        } catch (error) {
          console.error(error.message);
        }
      } else {
      }
    };
    debugger;
    if (toggle_type == COMPANY_TOGGLE) fetchDropDownData("/dashboard/get_company_list/");
    else if (toggle_type == MARKUP_TOGGLE) fetchDropDownData("/dashboard/create_mark_up/");

    else if (toggle_type == PRODUCT_TOGGLE) fetchDropDownData("/dashboard/get_product_list/");

    // else if(toggle_type == PRODUCT_TOGGLE){

    //   var toggleObj = new ToggleClass(PRODUCT_TYPES);
    //   options = toggleObj.setProduct();  
    //   console.log(options);
    //   setListData(options);
    // }

    else if(toggle_type == TRAVEL_TYPE) fetchDropDownData("/dashboard/get_travell_type_list/");
    
    else if(toggle_type == SERVICE_TOGGLE) fetchDropDownData("/dashboard/create_service_up/");
    
  }, []);



  const handleChange = (selectedOptions) => {
   
    selectedOptn({ selectedOptions });
  }






  if (loading) {
    return <h1>loading</h1>;
  } else {

    return <Select options={listData}  placeholder={placeHolderVar} onChange={handleChange}/>;
  }
}

export default CustomToggle;
