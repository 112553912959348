import React, { useState, useEffect } from "react";
import { Container, Row, ToggleButton, Badge } from "react-bootstrap";
import { GROUPBOOKING_LIST } from "../dummyDb";
import axios from "axios";
import { MDBDataTable, MDBInput } from 'mdbreact';
import { objectOf } from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Button from 'react-bootstrap/Button';
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import Swal from 'sweetalert2'
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { $CombinedState } from "redux";
import Moment from "moment";


var dataTable;
function WalletHistory(){
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);
  const [vault_history, set_vault_history] = useState(null);
  const [dataoftable, setdataoftable] = useState(null);

  
  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      setLoading(true)
      const get_vault_history = await axios.get( API_URL + '/points_system/walletHistory/')
      const allCustomers = await axios.get( API_URL + '/accounts/showAllCx/')
      get_vault_history.data.map((obj,i) => {
        var filtered = allCustomers.data.find(x => x.id == obj.customer_id);
          obj.phone_number = filtered.phone_number
      })
      set_vault_history(get_vault_history.data)
      console.log("get_vault_history.data")
      console.log(get_vault_history.data)
      setUpdateResponse(true)
      var pushrow = [];
      vault_history?.map((obj, indx)=>{
        var created_date = Moment(obj.created_on).format('MMMM Do YYYY, h:mm:ss a')
        var updated_date = Moment(obj.updated_at).format('MMMM Do YYYY, h:mm:ss a')
          pushrow.push({
            Customer_Contact: obj.phone_number,
            GDV_ID: obj.gdv_id,
            GDV_Account_ID: obj.gdv_account_id,
            account_type: obj.account_type,
            customer_id: obj.customer_id,
            vault_balance: obj.vault_balance,
            created_by: obj.created_by,
            created_date: created_date,
            updated_by: obj.updated_by,
            updated_date:updated_date,
          });
      });
      dataTable =  {
        columns: [
          {
            label: "Customer Contact",
            field: "Customer_Contact",
            width: 150,
          },
          {
            label: "GDV_ID",
            field: "GDV_ID",
            width: 150,
          },
          {
            label: "GDV_Account_ID",
            field: "GDV_Account_ID",
            width: 150,
          },
          {
            label: "Account type",
            field: "account_type",
            width: 100,
          },
          {
            label: "Customer Id",
            field: "customer_id",
            width: 200,
          },
          {
            label: "vault_balance",
            field: "vault_balance",
            width: 100,
          },
          {
            label: "created_by",
            field: "created_by",
            width: 100,
          },
          {
            label: "created_date",
            field: "created_date",
            width: 150,
          },
          {
            label: "updated_by",
            field: "updated_by",
            width: 150,
          },
          {
            label: "updated_date",
            field: "updated_date",
            width: 150,
          }
        ],
        rows: pushrow,
      }
      setdataoftable(dataTable)

      setLoading(false)
    };

    fetchData();
  }, [updateResponse]);

  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {

    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <h3>Digital Account History</h3>
            <br></br>
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive vault_table">
                  <MDBDataTable Striped bordered data={dataoftable} noBottomColumns={true} sortable={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );    
}
}

export default WalletHistory;