import {
    CUSTOMER_LIST_REQUEST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAILURE,
    TRAINER_LIST_REQUEST,
    TRAINER_LIST_SUCCESS,
    TRAINER_LIST_FAILURE,
    DATA_TYPE,
    GET_USER_LIST,
    BASE_URL,
    CREATE_TRAINER_REQUEST,
    CREATE_TRAINER_SUCCESS,
    CREATE_TRAINER_FAILURE,
    POST_REQ,


} from "../const";
import { CUSTOMER_LIST, TRAINER_LIST } from '../dummyDb'
import axios from "axios";


export const getCustDataAction = (requestData, urlData) => async (dispatch) => {

    try {
        dispatch({ type: CUSTOMER_LIST_REQUEST });
        var { data } = '';
        if (DATA_TYPE == 'TEST') {
            data  = CUSTOMER_LIST //list of all customers
        }
        else {
            const API = BASE_URL+urlData; //Local URL
            // const token = JSON.parse(localStorage.getItem('userInfo')).access
            const userId = requestData;
            const { data } = await axios.post(API);
        }

        // dispatch the data to reducer
        dispatch({
            type: CUSTOMER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CUSTOMER_LIST_FAILURE,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};


//get trainer list 
export const getTrainerListAction = (requestData, urlData) => async (dispatch) =>{
    try {
        dispatch({ type: TRAINER_LIST_REQUEST });
        var { data } = '';
        if (DATA_TYPE == 'TEST') {
            data  = TRAINER_LIST //list of all customers
        }
        else {
            const API = BASE_URL+urlData; //Local URL
            // const token = JSON.parse(localStorage.getItem('userInfo')).access
            const userId = requestData;
            const { data } = await axios.post(API);
        }

        // dispatch the data to reducer
        dispatch({
            type: TRAINER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: TRAINER_LIST_FAILURE,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}


//get trainer list 
export const createTrainerAction = (requestData, urlData) => async (dispatch) =>{
    try {
        dispatch({ type: CREATE_TRAINER_REQUEST });
        var { data } = '';
        if (DATA_TYPE == 'TEST') {
            data  = TRAINER_LIST //list of all customers
        }
        else {
            const API = BASE_URL+urlData; //Local URL
            // const token = JSON.parse(localStorage.getItem('userInfo')).access
            const userId = requestData;
            const { data } = await axios.post(API);
        }

        // dispatch the data to reducer
        dispatch({
            type: CREATE_TRAINER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_TRAINER_FAILURE,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}


class ApiCallClass{

    constructor(request_data=null, url_data = '/'){
        this.request_data = request_data;
        this.urlData = url_data = '/';
    }
}

// export const apiCallFun =(request_type=POST_REQ, request_data=null, url_data = '/')=>{

//     if(async == true){

//     }
//     else{

//     }
// }


