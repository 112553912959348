import React, { useState, useEffect } from "react";
import { Container, ToggleButton } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import { COMPANY_MAP_LIST, MARKUP_LIST } from "../dummyDb";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";

function EventEnquiry() {
  const [booking_details_list, set_booking_details_list] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateResponse, setUpdateResponse] = useState(false);

  const redirectFun =(id)=>{
    
    window.location.href = DASHBOARD_URL + "/eventBooking?" + id
  }

  useEffect(() => {
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }


    const fetchData = async () => {
      setLoading(true)
      const booking_details = await axios.get( API_URL + '/event_promotion/save_event_data/')
      console.log(booking_details)
      set_booking_details_list(booking_details.data)
      setLoading(false)

    };

    fetchData();
  }, [updateResponse]);

  




  if (loading) {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  } else {
    return (
      <>
        <nav
          class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
          id="sidenav-main"
        >
          <Navbar />
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <TopNavbar />
          </nav>

          <div className="m-5">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Event Name</th>
                          <th scope="col">Guest Name</th>
                          <th scope="col">Guest Contact</th>
                          <th scope="col">Guest Voucher</th>
                          <th scope="col">  </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          booking_details_list?.map((obj, indx)=>{
                            return(
                              <tr>
                            <td scope="col">{obj.event_name}</td>
                            <td scope="col">{obj.lead_guest_name}</td>
                            <td scope="col">{obj.lead_guest_contact}</td>
                            <td scope="col">{obj.discount_code}</td>
                            <td scope="col"> <button onClick = {
                                  (e)=>{
                                e.preventDefault()
                                  redirectFun(obj.id)
                                }

                                } variant="primary"> View Details </button> </td>
                          </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventEnquiry;
