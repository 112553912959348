import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import $ from "jquery";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import { PackageLists } from "../dummyDb";
// import PackageList from "../Components/PackageList";
export const UserContext = createContext();   //User context helps in sending data from 1 page to another
var id="";
function PackageScreen(){

  var data = [];
  var list = [];
  var html = ""
  const [flag, setflag] = useState(false);
  
  const [create, setcreate] = useState(false);
  const [update, setupdate] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [package_details_list, set_package_details_list] = useState(null);
  const [package_details_list2, set_package_details_list2] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [dayList, setdayList] = useState([]);
  const [checkedInclusionList, setcheckedInclusionList] = useState([]);     //headinginclusionstate
  const [highlightList, sethighlightList] = useState([]);
  const [inclusionList, setinclusionList] = useState([]);
  const [exclusionList, setexclusionList] = useState([]);
  const [thingList, setthingList] = useState([]);
  const [tipList, settipList] = useState([]);
  const [hoteldetailList, sethoteldetailList] = useState([]);
  const [gst_chargelist, setgst_chargelist] = useState([]);
  const [type, settype] = useState([]);
  const [Pkid, setPkid] = useState(null);
  const [FileObj, setFileObj] = useState({
    'banner_img':"",
    'home_img':""
  })
  var data = [];

  // handle input change for status
  const handleInputChange = (e, index) => {
    list.push({ "id": index, "pk_status": e.target.value });
    setInputList(list);
  };

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // ***Here is the code for converting "image source" (url) to "Base64".***

  let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
  const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
       }))

  // ***Here is code for converting "Base64" to javascript "File Object".***

    function dataURLtoFile(dataurl, filename) {
       var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
       while(n--){
       u8arr[n] = bstr.charCodeAt(n);
       }
     return new File([u8arr], filename, {type:mime});
    }

  useEffect(() => {
    
    if (localStorage.getItem("response") === null) {
      
      window.location.href = "/login";
    }
    const fetchData = async () => {
      
      // const package_details = await axios.get( API_URL + '/group_bookings/getFlightBookingDetails/')
      const package_details = PackageLists
      const package_details2 = await axios.get( API_URL + '/packages/Display_All_Holiday_Package_Details/')
      set_package_details_list(package_details2.data)
    };

    fetchData();
    //for editing package
    const getData = (pk_id) => {
      var newdata = []
      
      data = PackageLists[0];

      const fetchDataDisplay = async () => {
        newdata = await axios.post( API_URL + '/packages/Display_Holiday_Package_Details/', {"id":pk_id})
        newdata = newdata.data
        // console.log("newdata.data")
        // console.log(newdata)
        var file_obj = {}
          if(newdata){
            // *** Calling both function ***
            var banner_img = newdata.banner_img.split("?")
            var home_img = newdata.banner_img.split("?")
            toDataURL(banner_img[0])
            .then(dataUrl => {
                var fileNameIndex = banner_img[0].lastIndexOf("/") + 1;
                var filename = banner_img[0].substr(fileNameIndex);
                var fileData = dataURLtoFile(dataUrl, filename);
                file_obj.banner_img = fileData
             })
            toDataURL(home_img[0])
            .then(dataUrl => {
              var fileNameIndex = home_img[0].lastIndexOf("/") + 1;
              var filename = home_img[0].substr(fileNameIndex);
               var fileData = dataURLtoFile(dataUrl, filename);
               file_obj.home_img = fileData
               setFileObj(file_obj)
             })
            // -----
            setModalShow(false)
            
            setflag(true)
            setupdate(true)

            
            setPkid(pk_id)
            settype([{
              "type":newdata.type,
              "subcategory1":newdata.subcategory1,
              "subcategory2":newdata.subcategory2,
            }])
            // created object to fetch it from Database so we can view in React fronthend.
            var gst_charge_obj = {
              bank_remittance: newdata.bank_remittance,
              trav_insurance: newdata.trav_insurance,
              visa: newdata.visa,
              supplier_total: newdata.supplier_total,
              miscellaneous: newdata.miscellaneous,
              gross_total: newdata.gross_total,
              tour_leader_cost: newdata.tour_leader_cost,
              tour_leader_salary: newdata.tour_leader_salary,
              mountain_bike_charges: newdata.mountain_bike_charges,
              acquisition: newdata.acquisition,
              give_aways: newdata.giveaways,
              staff_incentive: newdata.staff_incentives,
              ght_travel_insurance: newdata.ght_travel_insurance,
              travel_cost: newdata.travel_cost,
              travel_type: newdata.travel_type,
              frn_commision: newdata.franchisee_commission,
              frn_service_tax: newdata.service_tax_frn,
              total_cost: newdata.total_cost,
              ght_markup_percent: newdata.ght_markup_percent,
              ght_markup_figure: newdata.ght_markup_figures,
              total_selling_price: newdata.total_selling_price,
              display_price:true,
              currency: newdata.currency,
            }
            setgst_chargelist([gst_charge_obj]);
            setdayList(newdata.pack_itenary);
            sethoteldetailList(newdata.pack_hotel);
            // created object for heading_inclusions for fetching data from database as true or false
            var hd_inc_list_obj = {
              meals: newdata.heading_inclusions.meals ? newdata.heading_inclusions.meals : false,
              sightseeing: newdata.heading_inclusions.sightseeing ? newdata.heading_inclusions.sightseeing : false,
              hotels: newdata.heading_inclusions.hotels ? newdata.heading_inclusions.hotels : false
            }
            setcheckedInclusionList([hd_inc_list_obj]);
            // code for splitting values of Tour Details by /n on click of edit button and then fetch it from Database so we can view in React.
            // /n wale string ko array mai convert kiye by splitting
            //Highlights
            var hl_string = newdata.highlights;
            var hl_array = hl_string.split("/n/")
            
              var hl_result = [];
              var hl_list = hl_array.length;
              for (var i = 0; i < hl_list; i++) {
                
                hl_result.push(
                  {
                    "list_item" : hl_array[i]
                  }
                );
              }

            //Inclusions
            var inc_string = newdata.tr_inclusion;
            var inc_array = inc_string.split("/n/")
            var inc_result = [];
              var inc_list = inc_array.length;
              for (var i = 0; i < inc_list; i++) {
                
                inc_result.push(
                  {
                    "list_item" : inc_array[i]
                  }
                );
              }

            //Exclusions
            var exc_string = newdata.tr_exclusion;
            var exc_array = exc_string.split("/n/")
            var exc_result = [];
              var exc_list = exc_array.length;
              for (var i = 0; i < exc_list; i++) {
                
                exc_result.push(
                  {
                    "list_item" : exc_array[i]
                  }
                );
              }

            //Things to do
            var ttd_string = newdata.things_to_do;
            var ttd_array = ttd_string.split("/n/")
            var ttd_result = [];
              var ttd_list = ttd_array.length;
              for (var i = 0; i < ttd_list; i++) {
                
                ttd_result.push(
                  {
                    "list_item" : ttd_array[i]
                  }
                );
              }

              //Things to do
            var tips_string = newdata.tips;
            var tips_array = tips_string.split("/n/")
            var tips_result = [];
              var tips_list = tips_array.length;
              for (var i = 0; i < tips_list; i++) {
              
                tips_result.push(
                  {
                    "list_item" : tips_array[i]
                  }
                );
              }
            sethighlightList(hl_result);
            setinclusionList(inc_result);
            setexclusionList(exc_result);
            setthingList(ttd_result);
            settipList(tips_result);
            setcheckedInclusionList([hd_inc_list_obj]);      //03-11-22 last change

            document.getElementById("pk_id").value = newdata.pk_id
            document.getElementById("pk_name").value = newdata.name
            document.getElementById("pk_heading").value = newdata.heading
            document.getElementById("pk_subheading").value = newdata.subheading
            document.getElementById("pk_type").value = newdata.type
            document.getElementById("pk_subcategory1").value = newdata.subcategory1 
            document.getElementById("pk_subcategory2").value = newdata.subcategory2
            // document.getElementById("pk_home_img").value = newdata.home_img
            var home_img = newdata.home_img.split("?")
            $("#pk_homeimg").attr('src',home_img[0])
            $("#pk_homeimg").removeClass('hide')
            var banner_img = newdata.banner_img.split("?")
            $("#pk_bannerimg").attr('src',banner_img[0])
            $("#pk_bannerimg").removeClass('hide')
            document.getElementById("pk_summary").value = newdata.summary
            document.getElementById("pk_nights").value = newdata.nights
            document.getElementById("pk_days").value = newdata.days
            document.getElementById("pk_stdate").value = newdata.startdate;
            document.getElementById("pk_enddate").value = newdata.enddate;
            document.getElementById("pk_validity_date").value = newdata.validity_date
            document.getElementById("pk_status").value = newdata.status
            document.getElementById("pk_pub_min_cost").value = newdata.gross_total 
            document.getElementById("currency").value = newdata.currency 
            document.getElementById("pk_visiting_country").value = newdata.visiting_country
            document.getElementById("pk_rating").value = newdata.rating
            document.getElementById("introduction").value = newdata.introduction
            document.getElementById("other_comments").value = newdata.other_comments 
            document.getElementById("pk_order").value = newdata.order 
            document.getElementById("pk_rating").value = newdata.rating
            newdata.isnew == true ? $('#is_new').prop('checked', true) : $('#is_new').prop('checked', false)
            var ranking_str = newdata.ght_star_ranking
            $('.'+ranking_str.replace(/\s/g, '')).prop('selected', true);
          }
      }
      fetchDataDisplay()
    }
    if(modalShow == true){
      getData(id)
    }
    
},[modalShow]);

  var columns = [
      {
        name: 'Id',
        selector: 'id',
      },
      {
        name: 'Package Name',
        selector: 'pk_name',
      },
      {
        name: 'Package Cost',
        selector: 'pk_pub_min_cost',
      },
      {
        name: 'Start Date',
        selector: 'pk_stdate',
      },
      {
        name: 'End Date',
        selector: 'pk_enddate',
      },
      
      {
        name: 'Status',
        selector: 'pk_status',
      },
      {
        name: '',
        selector: 'edit',
      },
  ];
return(

  <>
    <nav
      class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
      id="sidenav-main"
    >
      <Navbar />
    </nav>
    <div class="main-content" id="panel">
      <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <TopNavbar />
      </nav>
      <div className="ml-5 mt-5 mr-5">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="mb-0">Packages</h5>
                  </div>
                  <div class="col text-right">
                    <a class="btn btn-sm btn-primary" style={{"color":"white"}} variant="primary" onClick={async () => {
                      
                      window.location.reload()
                      await setModalShow(true)
                      setcreate(true)
                      setflag(true)
                      }}
                    >
                      Add New
                    </a>
                    
                  </div>
                </div>
              </div>
            </div>
            {/*<span>{console.log("FileObj")}</span>*/}
            {/*<span>{console.log(FileObj)}</span>*/}
            {/*<span>{console.log(FileObj.banner_img)}</span>*/}
            <UserContext.Provider value={
              { 
                createValue: create,
                updateValue: update,
                dayValue: dayList, 
                checkedInclusionValue: checkedInclusionList,    //03-11-22 last change
                highlightValue: highlightList,
                inclusionValue: inclusionList,
                exclusionValue: exclusionList,
                thingValue: thingList,
                tipValue: tipList,
                hoteldetailValue: hoteldetailList,
                gst_chargeValue: gst_chargelist,
                typeValue: type,
                idValue: id,
                banner_imgValue: FileObj.banner_img,
                home_imgValue: FileObj.home_img,
              }}>
              <PackageCreateModal
                show={modalShow}
                onHide={() => {
                  setModalShow(false)
                  window.location.reload()
                  }}
              />
            </UserContext.Provider>
            <div class="card grp_list">
                {
                  package_details_list != null ?
                  package_details_list?.map((obj, index) => { var datalist ={
                        "id":obj.id,
                        "pk_name":obj.name,
                        "pk_pub_min_cost":obj.pack_hotel.length > 0 ? obj.pack_hotel[0].Base_Cost : "-",
                        "pk_stdate":obj.startdate,
                        "pk_enddate":obj.enddate,
                        "edit":
                        <span id={obj.id}
                        style={{"cursor":"pointer"}}
                        onClick={e => {
                          
                          setModalShow(true)
                          id = obj.id
                          topFunction()
                          
                        }}
                        >
                          Edit
                        </span>,
                        // "pk_status":<select className="btn btn-sm bk_status" id="bk_status" onChange={e => handleInputChange(e, obj.id)}>
                        //           <option value="Active" selected={obj.pk_status == "Active" ? 'selected':''}>Active</option>
                        //           <option value="Inactive" selected={obj.pk_status == "Inactive" ? 'selected':''}>Inactive</option>
                        //       </select>,
                        "pk_status":obj.status,
                      }
                      
                      data.push(datalist)
                    })
                  :
                  data.push({
                        "id":"",
                        "pk_name":"",
                        "pk_pub_min_cost":"",
                        "pk_stdate":"",
                        "pk_enddate":"",
                        "edit":"",
                        // "pk_status":<select className="btn btn-sm bk_status" id="bk_status" onChange={e => handleInputChange(e, obj.id)}>
                        //           <option value="Active" selected={obj.pk_status == "Active" ? 'selected':''}>Active</option>
                        //           <option value="Inactive" selected={obj.pk_status == "Inactive" ? 'selected':''}>Inactive</option>
                        //       </select>,
                        "pk_status":"",
                      })
                }
            <DataTableExtensions
                      title="Bookings"
                      columns={columns}
                      data={data}
                      print={false}
                      export={false}
                      filter={false}
                      filterPlaceholder="search"
                      filterHidden={true}
                      filterDigit={1}
                    >
              <DataTable
                nHeader
                highlightOnHover
                pagination
              />
            </DataTableExtensions>
            </div>
            {
              inputList.map((x, i) => {
                  return(
                    <div>
                   {inputList.length - 1 === i && <button 
                    className="btn btn-danger" 
                    id="fixedbutton" 
                    style={{
                      "position":"fixed",
                      "bottom":"0px",
                      "right":"0px",
                      "cursor":"pointer"
                       }}
                      // onClick={handleAddClick}
                         >
                      Save Changes
                      </button>}
                    </div>
                    )
                })
            }
          </div>
        </div>
      </div>
    </div>

  </>

  )

}


export default PackageScreen