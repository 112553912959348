// For hotel

import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import PackageCreateModal from "../Components/PackageCreateModal";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { JsonToExcel } from "react-json-to-excel";
import CsvDownloadButton from 'react-json-to-csv'
import moment from 'moment'
import $ from "jquery"

var id="";

function HotelTravelAgentMis(){
    var data = [];
    var details = [];
    const [ta_mis_list, set_ta_mis_list] = useState([])
    const [edit_flag, set_edit_flag] = useState(false)
    const [clear_flag, set_clear_flag] = useState(false)
    const [id, setid] = useState("")
    const [loading, setloading] = useState(true)
    const [filterFromDate, setfilterFromDate] = useState("")
    const [filterToDate, setfilterToDate] = useState("")
    const [filterByStatus, setfilterByStatus] = useState("")
    const [filterByCompany, setfilterByCompany] = useState("")
    const [statusName, setstatusName] = useState([])
    const [companyName, setcompanyName] = useState([])

    useEffect(() => {
      if (localStorage.getItem("response") === null) {
        window.location.href = "/login";
      }
      const fetchData = async () => {
        $(".downloadBtn").text("")
        $(".downloadBtn").removeClass("download-button")
        const misdata = await axios.get( API_URL + '/accounts/send-hotel-booking-data/')
        var misdata_sort = misdata.data        
        misdata_sort.sort(function (a,b) {return b.booking.id - a.booking.id})
        console.log("sorted data")
        console.log(misdata_sort)
        set_ta_mis_list(misdata.data)
        setloading(false)
        setTimeout(() => setstatusName(status_name), 1000)
        setTimeout(() => setcompanyName(company_name), 1000)
      };
      fetchData();
    }, [])

    useEffect(() => {
      var html1 = `<option value="">All</option>`
      var html2 = `<option value="">All</option>`
      status_name.map((x,i) => {
        html1 += `<option value=${x}>${x}</option>`
      })
      company_name.map((x,i) => {
        debugger
        // html2 += `<option value=${a}>${x}</option>`
        html2 += `<option value=${JSON.stringify(x)}>${x}</option>`
      })
      $("#filterByStatus").html(html1)
      $("#filterByCompany").html(html2)
    },[statusName, companyName])

    var details_col = [
       
        { 
          name: 'No. of Nights',
          selector: 'no_of_nights',
        },
        { 
          name: 'No. of Rooms',
          selector: 'no_of_rooms',
        },
        { 
          name: 'Base Fare',
          selector: 'base_fare',
        },
        { 
          name: 'TAF',
          selector: 'taf',
        },
        { 
          name: 'Other Tax',
          selector: 'other_tax',
        },
        { 
          name: 'Service Charge',
          selector: 'service_charge',
        },
        { 
          name: 'GST',
          selector: 'gst',
        },
        { 
          name: 'TDS',
          selector: 'tds_deducted',
        },
        { 
          name: 'Addon Agency Commission',
          selector: 'Addon_Agent_Charges',
        },
        { 
          name: 'Amount',
          selector: 'amount',
        },
        { 
          name: 'Cancel Charge',
          selector: 'cancel_charge',
        },
        // here
      ]

  const ExpandedComponent = (data) => {
      var masterdetails = data.data
      return (
        <>
          {
            [masterdetails].map((obj, index) => {
              console.log(obj)
              // here
              var detailslist = {
                "no_of_nights":obj.no_of_nights,
                "no_of_rooms":obj.no_of_rooms,
                "base_fare":obj.base_fare,
                "taf":obj.taf,
                "other_tax":obj.other_tax,
                "service_charge":obj.service_charge,
                "gst":obj.gst, 
                "tds_deducted":obj.tds_deducted,
                "Addon_Agent_Charges":obj.Addon_Agent_Charges,
                "amount":obj.amount,
                "cancel_charge":obj.cancel_charge,
              }
              details = [detailslist]
            }
          )}
          <div className="master_details">
          <DataTableExtensions
              title="HotelTravelAgentMis Details"
              columns={details_col}
              data={details}
              filter={false}
              filterPlaceholder="filter"
              filterHidden={true}
              export={false}
              print={false}
            >
            <DataTable
              nHeader
              striped
              dense
              responsive
            />
          </DataTableExtensions>
          </div>
        </>
      )
  };

  var columns = [
      { 
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      { 
        name: 'Booking Id',
        selector: 'gohoptrip_booking_id',
      },
      { 
        name: 'Status',
        selector: 'hotel_booking_status',
      },
      { 
        name: 'Company Name',
        selector: 'company_name',
      },
      { 
        name: 'Invoice Date',
        selector: 'invoice_date',
      },
      { 
        name: 'Invoice Number',
        selector: 'invoice_number',
      },
      { 
        name: 'Lead Guest Name',
        selector: 'leadpax',
      },
      { 
        name: 'Hotel Name',
        selector: 'hotel_name',
      },
      { 
        name: 'Check In',
        selector: 'check_in_date',
      },
      { 
        name: 'Check Out',
        selector: 'check_out_date',
      },
  ];

  var status_name = []
  var company_name = []
  var flt_status_company = (item) => {
    if (item.hotel_booking_status.toLowerCase().includes(filterByStatus.toLowerCase()) && item.company_name.toLowerCase().includes(filterByCompany.toLowerCase())) {
      return item;
    }
  }
  
  var flt_status = (item) => {
    if (item.hotel_booking_status.toLowerCase().includes(filterByStatus.toLowerCase())) {
      return item;
    }
  }
  var flt_company = (item) => {
    if (item.company_name == filterByCompany){
      return item;
    }
  }
  var flt_date = (item) => {
    var dep_date = moment(item.check_in_date, 'YYYY-MM-DD').toDate()
    if(dep_date >= filterFromDate && dep_date <= filterToDate){
      if(filterByCompany !== "" && filterByStatus !== ""){
        return flt_status_company(item)
      }
      else if(filterByStatus !== ""){
        return flt_status(item)
      }
      else if(filterByCompany !== ""){
        return flt_company(item)
      }
      else{
        return item;
      }
    }
  }

  var reset = () => {
    setfilterByCompany("")
    setfilterToDate("")
    setfilterFromDate("")
    setfilterByStatus("")
    $("#filterFromDate").val("")
    $("#filterToDate").val("")
    $("#filterByStatus").val("")
    $("#filterByCompany").val("")
  }

  return(
      <>
      <nav
        class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
      >
        <Navbar />
      </nav>
      <div class="main-content ta_table" id="panel">
        <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
          <TopNavbar />
        </nav>
        <div className="container ml-5 mt-5 mr-5">
          <div className="card">
            <div class="card-header border-0">
              <h3 class="mb-0">Hotel Travel Agent Bookings</h3>
            </div>
            <Row className="container ml-2 mt-2 mr-2" style={{"padding":0}}>
              <Col xs={12} md={3}>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <b><span className="mr-2 label label-default">From -</span></b>
                      </Col>
                      <Col>
                        <input
                          type="date"
                          id="filterFromDate"
                          className="date form-control"
                          placeholder=""
                          onChange={(e) => {
                            setfilterFromDate(moment(e.target.value, 'YYYY-MM-DD').toDate())                
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <b><span className="mr-2 label label-default">To -</span></b>
                      </Col>
                      <Col>
                        <input
                          type="date"
                          id="filterToDate"
                          className="date form-control"
                          placeholder=""
                          onChange={(e) => {
                            setfilterToDate(moment(e.target.value, 'YYYY-MM-DD').toDate())                
                          }}
                          />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={2}>
                <b><span className="mr-2 label label-default">Filter by status-</span></b>
                <br />
                <select
                type="text"
                id="filterByStatus"
                className="form-select"
                placeholder="Filter by status"
                onChange={(e) => {
                  setfilterByStatus(e.target.value)
                }}
                >
                </select>
              </Col>
              <Col xs={12} md={2}>
                <b><span className="mr-2 label label-default">Filter by company-</span></b>
                <br />
                <select
                type="text"
                id="filterByCompany"
                className="form-select"
                placeholder="Filter by company"
                onChange={(e) => {
                  setfilterByCompany(e.target.value)
                }}
                >
                </select>
              </Col>
              <Col xs={12} md={3}>
                <button
                type="button"
                className="btn btn-primary"
                onClick={() => reset()}
                style={{"float":"right"}}
                >
                Reset
                </button>
              </Col>
            </Row>
          {ta_mis_list.map((obj, index) => {
              var datalist = {
              "id":obj.booking.id,
              "gohoptrip_booking_id":obj.booking.gohoptrip_booking_id,
              "hotel_booking_status":obj.booking.hotel_booking_status,
              "company_name":obj.booking.company_name,
              "invoice_date":obj.invoice ? obj.invoice.invoice_date : "-",
              "invoice_number":obj.invoice ? obj.invoice.invoice_number : "-",
              "leadpax":obj.booking.leadpax,
              "hotel_name":obj.booking.hotel_name,
              "check_in_date":obj.booking.check_in_date,
              "check_out_date":obj.booking.check_out_date,
              "no_of_nights":obj.booking.no_of_nights,
              "no_of_rooms":obj.booking.no_of_rooms,
              "base_fare":obj.invoice ? obj.invoice.base_fare : "-",
              "taf":obj.invoice ? obj.invoice.taf : "-",
              "other_tax":obj.invoice ? obj.invoice.other_tax : "-",
              "service_charge":obj.invoice ? obj.invoice.service_charge : "-",
              "gst":obj.invoice ? Math.round(parseFloat(obj.invoice.cgst) + parseFloat(obj.invoice.sgst) + parseFloat(obj.invoice.igst)) : "-",
              "tds_deducted":obj.invoice ? obj.invoice.tds_deducted : "-",
              "Addon_Agent_Charges":obj.invoice ? obj.invoice.Addon_Agent_Charges ? obj.invoice.Addon_Agent_Charges : "-" : "-",
              "amount":obj.invoice ? obj.invoice.amount : "-",
              "cancel_charge":obj.booking.amendments 
                              ? 
                                obj.booking.amendments[0].cancel_charge != null
                                ?
                                obj.booking.amendments[0].cancel_charge
                                :
                                'Non Refundable'
                              : 
                              "-",
            }
            if(!status_name.includes(obj.booking.hotel_booking_status)){
              status_name.push(obj.booking.hotel_booking_status)
            };
            if(!company_name.includes(obj.booking.company_name)){
              company_name.push(obj.booking.company_name)
            };
            data.push(datalist)
          })}
            <div className="exportBtns container ml-2 mt-2 mr-2">
              <JsonToExcel
                title=""
                data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })}
                fileName="HotelTravelAgentMis"
                btnClassName="downloadBtn xls"
              />
              <div>
              <CsvDownloadButton
                data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })} 
                filename="HotelTravelAgentMis"
                className="downloadBtn csv"
              >
              Download as Csv
              </CsvDownloadButton>
              </div>
            </div>
            <DataTableExtensions
                title="HotelTravelAgentMis"
                columns={columns}
                data={data.filter((item) => {
                      if (filterFromDate === "" && filterByStatus === "" && filterByCompany === "") {
                        return item;
                      }
                      else if(filterFromDate !== "" && filterToDate !== ""){
                        return flt_date(item)
                      }
                      else if(filterByCompany !== "" && filterByStatus !== ""){
                        return flt_status_company(item)
                      }
                      else if(filterByCompany !== ""){
                        return flt_company(item)
                      }
                      else if(filterByStatus !== ""){
                        return flt_status(item)
                      }
                      else{
                        return item;
                      }
                    })}
                print={false}
                export={false}
                copy={false}
                filter={false}
                filterPlaceholder="filter"
                filterHidden={false}
                filterDigit={5}
                fileName="HotelTravelAgentMis"
              >
              <DataTable
                nHeader
                highlightOnHover
                pagination
                defaultSortField="id"
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                responsive
                progressPending={loading}
                theme="light"
                fixedHeader
                // fixedHeaderScrollHeight="500px"
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
     
      </>
    )
}


export default HotelTravelAgentMis