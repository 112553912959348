import React, { useState, useEffect, createContext} from "react";
import { Container, ToggleButton, Card, Modal, Button, Form, Col, Row, ButtonGroup} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from "axios";
import {
  API_URL,
  DASHBOARD_URL,
  MARKUP_TOGGLE,
  COMPANY_TOGGLE,
  TYPE,
  PRODUCT_TOGGLE,
  TRAVEL_TYPE
} from "../const";
import CustomToggle from "../Components/CustomToggle";
import Select from "react-select";
import { ApiCall, putApiCall } from "../apicalls";
import swal from "sweetalert";
import Navbar from "../Components/Navbar";
import TopNavbar from "../Components/TopNavbar";
import { MDBDataTable, MDBInput } from 'mdbreact';
import $ from 'jquery';
export const UserContext = createContext();
var id="";
function RewardScreen(){

  const[modalshow, setmodalshow] = useState(false);
  const[modalshow2, setmodalshow2] = useState(false);
  const[products, setproducts] = useState([]);
  const[producttypes, setproducttypes] = useState([]);
  const[RedeemData, setRedeemData] = useState([]);
  const[dataoftable, setdataoftable] = useState([]);
  const[dataoftable2, setdataoftable2] = useState([]);
  //for creating reward entry
  const [reward_req, setreward_req] = useState([{
      "product_name":"",
      "product_type":"",
      "reward_percentage":"",
      "max_reward_amt":"",
      "last_updated_by":JSON.parse(localStorage.getItem("response")).id,
    }])
  //for creating redeem entry
  const [redeem_req, setredeem_req] = useState([{
      "product_name":"",
      "product_type":"",
      "percentage":"",
      "max_redeem_amt":"",
      "last_updated_by":JSON.parse(localStorage.getItem("response")).id,
    }])

//--------------for reward input---------------------

  const handleShow = async (type, id) => {
    await setmodalshow(true);
    if(type == "update"){
      const data = await getReward(id);
      await setreward_req([{
        "id":data.id,
        "product_name":data.product_name_id,
        "product_type":data.product_type_id,
        "reward_percentage":data.reward_percentage,
        "max_reward_amt":data.max_reward_amt,
        "last_updated_by":1
      }])
      $("#product_name").val(data.product_name_id)
      $("#product_type").val(data.product_type_id)
      $("#product_name").attr("disabled", "disabled")
      $("#product_type").attr("disabled", "disabled")
      $("#reward_percentage").val(data.reward_percentage)
      $("#max_reward_amt").val(data.max_reward_amt)
      $("#create").addClass("hide")
      $("#update").removeClass("hide")

    }
    else if(type == "create"){
        $("#create").removeClass("hide")
        $("#update").addClass("hide")
        await delete reward_req[0].id
      }
  }
  const handleClose  = () => setmodalshow(false);

//--------------for redeem input---------------------

  const handleShow2 = async (type, id) => {
      await setmodalshow2(true);
      if(type == "update"){
        const data = await getRedeem(id);
        await setredeem_req([{
          "id":data.id,
          "product_name":data.product_name_id,
          "product_type":data.product_type_id,
          "percentage":data.percentage,
          "max_redeem_amt":data.max_redeem_amt,
          "last_updated_by":1,
        }])
        $("#product_name2").val(data.product_name_id)
        $("#product_type2").val(data.product_type_id)
        $("#product_name2").attr("disabled", "disabled")
        $("#product_type2").attr("disabled", "disabled")
        $("#percentage2").val(data.percentage)
        $("#max_redeem_amt2").val(data.max_redeem_amt)
        $("#create2").addClass("hide")
        $("#update2").removeClass("hide")

      }
      else if(type == "create"){
        $("#create2").removeClass("hide")
        $("#update2").addClass("hide")
        await delete redeem_req[0].id
      }
  };
  const handleClose2  = () => setmodalshow2(false);

  const getRedeem = async (id) => {
    const data = await axios.post(API_URL + '/points_system/getRedeem/', {"id":id});
    const redeemdata = data.data;
    return redeemdata

  }

  const getReward = async (id) => {
    const data = await axios.post(API_URL + '/points_system/getReward/', {"id":id});
    const rewarddata = data.data;
    return rewarddata

  }
  const getproducts = async () => {
    const data = await axios.get(API_URL + '/dashboard/get_product_list/');
    const productdata = data.data;
    setproducts(productdata)
  }

  const getproducttypes = async () => {
    const data = await axios.get(API_URL + '/dashboard/get_product_type/');
    const producttypesdata = data.data;
    setproducttypes(producttypesdata)
  }

  const getProdNames = (id) => {
    var filtered = products.find(x => x.id == id);
    var name = filtered.product_name
    return name;
  }

  const getProdTypeNames = (id) => {
    debugger
    var filtered = producttypes.find(x => x.id == id);
    var type = filtered.product_type
    return type;
  }

  const setReward = (request, type) => {
    debugger
    if(request[0].product_name == "" || request[0].product_type == "" || request[0].reward_percentage == "" || request[0].last_updated_by == ""){
      debugger
      return swal("Please fill required fields")
    }
    else{
      debugger
      swal({
          text: `Do you want to ${type}?`,
          buttons: [
                  'No',
                  'Yes'
                ],
      }).then(async function(isConfirm) {
        if (isConfirm){
          const data = await axios.post(API_URL + '/points_system/setReward/', request[0])
          swal(data.data)
          if(data.data == "Updated Successfully" || data.data == "Created Successfully"){
            setmodalshow(false)
            getAllReward()
          }
          else{
            // setmodalshow(true)
          }
        }
      });
    }
  }

  function checkProperties(obj) {
      for (var key in obj) {
          if (obj[key] !== null && obj[key] != ""){
            return false;
          }
      }
      return true;
  }

  const setRedeem = (request, type) => {
    if(request[0].product_name == "" || request[0].product_type == "" || request[0].percentage == "" || request[0].last_updated_by == ""){
      return swal("Please fill required fields")
    }
    else{
      swal({
          text: `Do you want to ${type}?`,
          buttons: [
                    'No',
                    'Yes'
                  ],
      })
      .then(async function(isConfirm) {
        if (isConfirm){
          const data = await axios.post(API_URL + '/points_system/setRedeem/', request[0])
          swal(data.data)
          if(data.data == "Updated Successfully" || data.data == "Created Successfully"){
            setmodalshow2(false)
            getAllRedeem()
          }
          else{
            // setmodalshow2(true)
          }
        }
      });
    }
  }

  const getAllRedeem = async () => {
    const data = await axios.get(API_URL + '/points_system/getAllRedeem/');
    const redeem_data = data.data;
    setRedeemData(redeem_data)
    var pushrow = [];
    redeem_data?.map((obj, indx) => {
        pushrow.push({
          id: obj.id,
          product_name: getProdNames(obj.product_name),
          product_type: getProdTypeNames(obj.product_type),
          percentage: obj.percentage,
          max_redeem_amt: obj.max_redeem_amt,
          last_updated_by: obj.last_updated_by,
          last_updated_dt: obj.last_updated_dt,
          update: <button id="update_btn" class="btn btn-sm btn-primary" disabled={getProdNames(obj.product_name) == "HOLIDAY_PACKAGE" ? true : false}  onClick={e => {handleShow2("update", obj.id)}}>Update</button>,
        });
    });

    var dataTable =  {
      columns: [
        {
          label: "ID",
          field: "id",
          width: 150,
        },
        {
          label: "Product Name",
          field: "product_name",
          width: 150,
        },
        {
          label: "Product Type",
          field: "product_type",
          width: 150,
        },
        {
          label: "Percentage",
          field: "percentage",
          width: 150,
        },
        {
          label: "Max Redeem Amount",
          field: "max_redeem_amt",
          width: 150,
        },
        {
          label: "Last Updated By",
          field: "last_updated_by",
          width: 150,
        },
        {
          label: "Last Updated At",
          field: "last_updated_dt",
          width: 150,
        },
        {
          label: "update",
          field: "update",
          width: 150,
        },
      ],
      rows: pushrow,
    }
    setdataoftable(dataTable)
  }

  const getAllReward = async () => {
  debugger
    const data = await axios.get(API_URL + '/points_system/getAllReward/');
    const reward_data = data.data;
    setRedeemData(reward_data)
    var pushrow = [];
    reward_data?.map((obj, indx) => {
        pushrow.push({
          id: obj.id,
          product_name: getProdNames(obj.product_name),
          product_type: getProdTypeNames(obj.product_type),
          reward_percentage: obj.reward_percentage,
          max_reward_amt: obj.max_reward_amt,
          last_updated_by: obj.last_updated_by,
          last_updated_dt: obj.last_updated_dt,
          update: <button id="update_btn" class="btn btn-sm btn-primary" onClick={e => {handleShow("update", obj.id)}}>Update</button>,
        });
    });

    var dataTable =  {
      columns: [
        {
          label: "ID",
          field: "id",
          width: 150,
        },
        {
          label: "Product Name",
          field: "product_name",
          width: 150,
        },
        {
          label: "Product Type",
          field: "product_type",
          width: 150,
        },
        {
          label: "Percentage",
          field: "reward_percentage",
          width: 150,
        },
        {
          label: "Max Reward Amount",
          field: "max_reward_amt",
          width: 150,
        },
        {
          label: "Last Updated By",
          field: "last_updated_by",
          width: 150,
        },
        {
          label: "Last Updated At",
          field: "last_updated_dt",
          width: 150,
        },
        {
          label: "update",
          field: "update",
          width: 150,
        },
      ],
      rows: pushrow,
    }
    setdataoftable2(dataTable)
  }

  // use
  useEffect(() => {
    getAllRedeem();
    getAllReward();
  }, [products, producttypes])
  useEffect(() => {
    console.log("useEffect")
    if (localStorage.getItem("response") === null) {
      window.location.href = "/login";
    }
    const fetchData = async () => {
    };

    fetchData();
    getproducts();
    getproducttypes();
},[]);

// handle input for reward
const handleInputChange1 = (e, index) => {
  const { name, value } = e.target;
  const list = [...reward_req];
  list[index][name] = value;
  setreward_req(list);
};

// handle input for redeem
const handleInputChange2 = (e, index) => {
  const { name, value } = e.target;
  const list = [...redeem_req];
  list[index][name] = value;
  setredeem_req(list);
};

return(

  <>
    <nav
      class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white"
      id="sidenav-main"
    >
      <Navbar />
    </nav>
    <div class="main-content" id="panel">
      <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <TopNavbar />
      </nav>
      <div className="ml-5 mt-5 mr-5">
      
        <div class="row">
          <div class="col-xl-12">
            <div className="" style={{"padding": '0 0 20px 0px'}}><h5 class="mb-0">Reward Points</h5></div>
            <div class="">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h6>Set Reward Percentage</h6>
                  </div>
                  <div class="col text-right">
                    <a class="btn btn-sm btn-primary" style={{"color":"white"}} variant="primary" onClick={e => {handleShow("create")}}
                    >
                      Set
                    </a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*display reward data*/}
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="table-responsive vault_table">
                <MDBDataTable Striped small bordered data={dataoftable2} noBottomColumns={true} sortable={false} />
              </div>
            </div>
          </div>
        </div>
        {/*display reward data end*/}

        {/*reward points modal*/}
          <Modal show={modalshow} onHide={handleClose} className="RewardModal">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Set Reward Percentage
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  {
                    reward_req.map((x,i) => {
                      return (

                        <div>
                          <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              as="select"
                              className="product_name"
                              name="product_name"
                              id="product_name"
                              onChange={(e) => handleInputChange1(e, i)}
                            >
                              {
                                <option>Select Product</option>
                              }
                              {
                                products.map((o, i) => {return <option value={o.id} key={o.id} id={o.id}>{o.product_name}</option>})
                              }
                            </Form.Control>
                            <br/>
                            <Form.Label>Product Type</Form.Label>
                            <Form.Control
                              as="select"
                              className="product_type"
                              name="product_type"
                              id="product_type"
                              onChange={(e) => handleInputChange1(e, i)}
                            >
                              {
                                <option>Select Product Type</option>
                              }
                              {
                                producttypes.map((o, i) => {return <option value={o.id} key={o.id} id={o.id}>{o.product_type}</option>})
                              }
                            </Form.Control>
                            <br/>
                            <Form.Label>Percentage</Form.Label>
                            <Form.Control
                              type="number"
                              className="reward_percentage"
                              name="reward_percentage"
                              id="reward_percentage"
                              placeholder="%"
                              onChange={(e) => handleInputChange1(e, i)}
                            >
                            </Form.Control>
                            <br/>
                            <Form.Label>Maximum Reward Amount</Form.Label>
                            <Form.Control
                              type="number"
                              className="max_reward_amt"
                              name="max_reward_amt"
                              id="max_reward_amt"
                              placeholder=""
                              defaultValue="0"
                              onChange={(e) => handleInputChange1(e, i)}
                            >
                            </Form.Control>
                        </div>

                      )
                    })
                  }
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="" id="create" onClick={(e) => {setReward(reward_req, "Create New Entry")}}>Create</Button>
              <Button className="hide" id="update" onClick={(e) => {setReward(reward_req, "Update")}}>Update</Button>
              {/*<Button onClick={props.onHide}>Close</Button>*/}
            </Modal.Footer>
          </Modal>
        {/*reward points modal end*/}

        <div class="row">
          <div class="col-xl-12">
            <div className="" style={{"padding": '0 0 20px 0px'}}><h5 class="mb-0">Redeem Points</h5></div>
            <div class="">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h6>Set Redeem Percentage</h6>
                  </div>
                  <div class="col text-right">
                    <a class="btn btn-sm btn-primary" style={{"color":"white"}} variant="primary" onClick={e => {handleShow2("create")}}
                    >
                      Set
                    </a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*display redeem data*/}
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="table-responsive vault_table">
                <MDBDataTable Striped small bordered data={dataoftable} noBottomColumns={true} sortable={false} />
              </div>
            </div>
          </div>
        </div>
        {/*display redeem data end*/}

        {/*redeem points modal*/}
          <Modal show={modalshow2} onHide={handleClose2} className="RewardModal">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Set Redeem Percentage
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  {
                    reward_req.map((x,i) => {
                      return (

                        <div>
                          <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              as="select"
                              className="product_name"
                              name="product_name"
                              id="product_name2"
                              onChange={(e) => handleInputChange2(e, i)}
                            >
                              {
                                <option>Select Product</option>
                              }
                              {
                                products.map((o, i) => {return <option value={o.id} key={o.id} id={o.id}>{o.product_name}</option>})
                              }
                            </Form.Control>
                            <br/>
                            <Form.Label>Product Type</Form.Label>
                            <Form.Control
                              as="select"
                              className="product_type"
                              name="product_type"
                              id="product_type2"
                              onChange={(e) => handleInputChange2(e, i)}
                            >
                              {
                                <option>Select Product Type</option>
                              }
                              {
                                producttypes.map((o, i) => {return <option value={o.id} key={o.id} id={o.id}>{o.product_type}</option>})
                              }
                            </Form.Control>
                            <br/>
                            <Form.Label>Percentage</Form.Label>
                            <Form.Control
                              type="number"
                              className="percentage"
                              name="percentage"
                              id="percentage2"
                              placeholder="%"
                              onChange={(e) => handleInputChange2(e, i)}
                            >
                            </Form.Control>
                            <br/>
                            <Form.Label>Maxiumum Redeem Amount</Form.Label>
                            <Form.Control
                              type="number"
                              className="max_redeem_amt"
                              name="max_redeem_amt"
                              id="max_redeem_amt2"
                              placeholder=""
                              defaultValue="0"
                              onChange={(e) => handleInputChange2(e, i)}
                            >
                            </Form.Control>
                        </div>

                      )
                    })
                  }
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="hide" id="create2" onClick={(e) => {setRedeem(redeem_req, "Create New Entry")}}>Create</Button>
              <Button className="hide" id="update2" onClick={e => {setRedeem(redeem_req, "Update")}}>Update</Button>
              {/*<Button onClick={props.onHide}>Close</Button>*/}
            </Modal.Footer>
          </Modal>
        {/*redeem points modal end*/}
     </div>
    </div>

  </>

  )

}


export default RewardScreen